import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../http/user/user.service';
import { CirclesService } from '../http/circles/circles.service';

/**
 * CirclesResolver
 */
@Injectable()
export class CirclesResolver implements Resolve<any> {

  constructor(
    public circleService: CirclesService,
    public userService: UserService
  ) { }

  /***
   * Get user's link and unlink circles
   */
  public resolve(): Promise<any> {
    return this.userService.getUserMail()
    .then((mail) => forkJoin(
      this.circleService.getCircleUser(mail).pipe(map((x) => x.data)),
      this.circleService.getAllCircles(mail).pipe(map((x) => x.data))
    ).toPromise());
  }
}
