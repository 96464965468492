import { Component, OnInit, Output, EventEmitter, HostListener, ViewChild, Input } from '@angular/core';
import { dateList, dayList } from 'src/assets/data/dates';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Regex } from 'src/assets/regex/regex';
import { enterAnimation } from '../../../animations/enterAnimation.animation';
import { UserService } from 'src/app/modules/http/user/user.service';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { PasswordPopupComponent } from './password-popup/password-popup.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { User } from 'src/app/providers/models/user.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

/**
 * Component that handles profil edition related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-edit-profil',
  templateUrl: './edit-profil.component.html',
  styleUrls: ['./edit-profil.component.scss'],
  animations: [enterAnimation]
})
export class EditProfilComponent implements OnInit {

  /*** Page status emitter */
  @Output()
  editProfil: EventEmitter<boolean> = new EventEmitter();
  /*** Delete profil popup status emitter */
  @Output()
  deletePopup: EventEmitter<boolean> = new EventEmitter();
  /*** Page status input */
  @Input() isEditProfil: boolean;
  /*** Month list */
  public selectDate = dateList;
  /*** Day list */
  public selectDay = dayList;
  /*** Year list */
  public selectYears = [];
  /*** Fill input field number */
  public formFillvalue = 0;
  /*** Birthday input status */
  public isBirthday = false;
  /*** Birthmonth input status */
  public isBirthMonth = false;
  /*** Birthyear input status */
  public isBirthYear = false;
  /*** Training type input status */
  public isTrainingType = false;
  /*** Training year input status */
  public isTrainingYear = false;
  /*** Sticky bar status */
  public sticky = false;
  /*** Mail regex */
  public mailRegex: string;
  /*** Postal regex */
  public postalRegex: string;
  /*** Certification type */
  public certification: string;
  /*** User's form mail */
  public email: string;
  /** User's current mail */
  public currentMail: string;
  /*** User's gender */
  public gender: string;
  /*** User's Firstname */
  public firstName: string;
  /*** User's Lastname */
  public lastName: string;
  /*** User's postal code */
  public postalCode: number | string;
  /*** User's birthday */
  public dateBirth: Date;
  /*** User's certification date */
  public dateCertification: string | number;
  /*** Form's defaut value */
  public defautValue: User;
  /*** User day of birth */
  public birthday: number;
  /*** User month of birth */
  public birthmonth: number;
  /*** User year of birth */
  public birthyear: number;
  /*** User certification status */
  public hasCertification: boolean;
  /** Mail update status */
  public mailError = false;

  /*** Get profil information */
  @ViewChild('profil', null) profil: NgForm;

  /**
   * Get viewport width
   */
  @HostListener('window:scroll')
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 400)
      this.sticky = true;
    else
      this.sticky = false;

  }

  constructor(
    public router: Router,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  /**
   * Create date list, get regex and user's informations to fill form
   */
  ngOnInit(): void {
    for (let i = 0; i < 100; ++i)
      this.selectYears.push(2020 - i);
    this.postalRegex = Regex.filter((x) => x.type === 'codePostal').map((x) => x.regex).toString();
    this.mailRegex = Regex.filter((x) => x.type === 'mail').map((x) => x.regex).toString();
    this.userService.getUserMail()
    .then((mail) => {
      this.currentMail = this.email = mail;
      this.userService.getUserData(this.email)
      .subscribe((infos) => {
        this.certification = infos.data.certification;
        this.gender = infos.data.gender;
        this.firstName = infos.data.firstName;
        this.lastName = infos.data.lastName;
        if (infos.data.postalCode === '0')
          this.postalCode = null;
        else
          this.postalCode = infos.data.postalCode;
        if (infos.data.dateBirth) {
          this.dateBirth = new Date(infos.data.dateBirth);
          this.birthday = this.dateBirth.getDate();
          this.birthmonth = (this.dateBirth.getMonth() + 1);
          this.birthyear = this.dateBirth.getFullYear();
        }
        this.dateCertification = infos.data.dateCertification;
        this.hasCertification = infos.data.hasCertification;
        this.defautValue = this.getFormvalue();
        this.checkProgress();
      });
    })
    .catch(console.error);
  }

  /**
   * Go back to profil page
   */
  public backToProfil(): void {
    this.editProfil.emit(false);
    this.router.navigateByUrl(`/profil`)
    .catch(console.error);
  }

  /**
   * Get user gender
   * @param param User's gender
   */
  public genderType(param: string): void {
    this.gender = param;
  }

  /**
   * Get user training
   * @param param User's training
   */
  public isTrained(param: boolean): void {
    this.hasCertification = param;
  }

  /**
   * Get form data on submit
   */
  public onSubmit(): any {
    const formValue = this.getFormvalue();
    this.userService.setUserData(this.currentMail, formValue).subscribe(
      (update) => {
        if (update.code === '03')
          return this.mailError = true;
        if (update.data)
          window.localStorage.setItem('token', update.data);
        this.editProfil.emit(false);
        this.router.navigateByUrl(`/profil`)
        .catch(console.error);
      },
      (err) => {
        if (err.code === '03')
          this.mailError = true;
      }
    );
  }

  /**
   * Get form progression
   * @param param Form data
   */
  public checkProgress(param?: NgForm): void {
    const paramValue = this.getFormvalue();
    this.formFillvalue = Math.ceil(Object.keys(paramValue).filter((x) => paramValue[x]).length / Object.keys(paramValue).length * 100);
  }

  /**
   * Delete user account
   */
  public deleteAccount(): void {
    window.scrollTo(0, 0);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(DeletePopupComponent, dialogConfig);
  }

  /**
   * Open/close password popup
   */
  public passwordPopup(): void {
    window.scrollTo(0, 0);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(PasswordPopupComponent, dialogConfig);
  }

  /**
   * Get form data
   */
  public getFormvalue(): User {
    let birthdate = '';
    if (!this.birthyear || !this.birthmonth || !this.birthday)
      birthdate = null;
    else
      birthdate = (`${this.birthyear}-${this.birthmonth}-${this.birthday}`);
    const formValue = {
      firstName: this.firstName,
      lastName: this.lastName ? this.lastName : null,
      postalCode: this.postalCode ? this.postalCode : null,
      email: this.email,
      gender: this.gender,
      dateBirth: birthdate,
      certification: this.certification,
      dateCertification: parseInt(this.dateCertification as string, 10),
      hasCertification: this.hasCertification
    };
    return formValue;
  }
}

