import { Component, OnInit, Input } from '@angular/core';
import { PathsService } from 'src/app/modules/http/paths/paths.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { UserService } from 'src/app/modules/http/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { CertificatePopupComponent } from './certificate-popup/certificate-popup.component';
import { User } from 'src/app/providers/models/user.model';
import { CustomPath } from 'src/app/providers/models/profilestats.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { Circle } from 'src/app/providers/models/circle.model';

/**
 * Component that handles profil path related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-my-path',
  templateUrl: './my-path.component.html',
  styleUrls: ['./my-path.component.scss'],
  animations: [enterAnimation]
})
export class MyPathComponent implements OnInit {

  constructor(
    public pathsService: PathsService,
    public dialog: MatDialog,
    public userService: UserService,
    private stats: StatsService,
    public circleService: CirclesService
  ) { }
  /*** User device status */
  @Input() isMobile: boolean;
  /*** Viewport width */
  @Input() innerWidth: number;
  /*** User's paths array */
  @Input() userPaths: Array<CustomPath>;
  /*** User's paths array */
  @Input() allPaths: Array<CustomPath>;
  /*** User's paths array */
  @Input() circlePaths: Array<CustomPath>;
  /*** User's information */
  @Input() userInfo: User;
  /*** User's circle id */
  public idsCircle: Array<number> = [];
  /*** User's circles */
  public userCircles: Array<Circle>;
  /*** User's paths array */
  public pathsList: Array<CustomPath> = [];
  /*** Carousel status */
  public isSlick = false;

  /*** Carousel param */
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: ('.next'),
    prevArrow: ('.prev'),
    infinite: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          variableWidth: true
        }
      }
    ]
  };

  /**
   * Get paths information to set carousel
   */
  ngOnInit(): void {
    for (const allPaths of this.allPaths) {
      allPaths.finishedScenes = 0;
      allPaths.locked = 1;
    }
    for (const circlePaths of this.circlePaths) {
      circlePaths.finishedScenes = 0;
      circlePaths.locked = 1;
    }
    for (const userPath of this.userPaths) {
      userPath.locked = 1;
      if (userPath.finishedScenes === userPath.totalScenes)
        userPath.locked = 0;
    }
    this.pathsList = this.allPaths?.concat(this.circlePaths).concat(this.userPaths);
    this.pathsList.sort((a, b) => a.idPath - b.idPath);
    if (this.pathsList.length > 2)
      this.isSlick = true;
    else this.isSlick = false;
  }

  /**
   * Open/close certificate popup
   */
  public openPopup(): void {
    window.scrollTo(0, 0);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      paths: this.pathsList,
      userInfo: this.userInfo
    };
    const dialogRef = this.dialog.open(CertificatePopupComponent, dialogConfig);
    this.userService.getUserMail()
    .then((mail) => {
      this.circleService.getCircleUser(mail)
      .subscribe((data) => {
        this.userCircles = data.data;
        for (const element of this.userCircles)
          this.idsCircle.push(element.idCircle);
        this.stats.logEvent('[EH] Profil_CTA-Attestations_Clicked', { idCircle: this.idsCircle })
        .catch(console.error);
      });
    })
    .catch((error) => console.error(error));
  }

}
