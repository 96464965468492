import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { UserService } from 'src/app/modules/http/user/user.service';

/**
 * Component that handles password popup related methods
 *  @author jeremy65
 */
@Component({
  selector: 'app-password-popup',
  templateUrl: './password-popup.component.html',
  styleUrls: ['./password-popup.component.scss']
})
export class PasswordPopupComponent {

  /*** User's actual password */
  public actualPass: string;
  /*** New password */
  public password: string;
  /*** New password 2nd input */
  public password2: string;
  /*** User's actual password input status */
  public isPassword = true;
  /*** Nes user's password input status */
  public isPassword2 = true;
  /*** User's password validity */
  public isActualPass = true;
  /*** Password check status */
  public badPass = true;
  /*** New password input status */
  public samePassword = true;
  /*** Form status */
  public submit = false;
  /*** Popup element */
  public dialogRef: MatDialog;

  constructor(
    public loginService: LoginService,
    public userService: UserService,
    public matDialog: MatDialog
  ) { }

  /**
   ** Check password and send form data
   */
  public onSubmit(): void {
    this.submit = true;
    if (!this.password || !this.password2 || !this.actualPass) {
      if (!this.password)
        this.isPassword = false;
      if (this.password)
        this.isPassword = true;
      if (!this.password2)
        this.isPassword2 = false;
      if (!this.actualPass)
        this.isActualPass = false;
    } else {
      if (this.password !== this.password2)
        this.samePassword = false;
      else {
        this.matDialog.closeAll();
        this.isPassword2 = true;
        this.samePassword = true;
        this.isPassword = true;
        this.userService.getUserMail()
        .then((email) => {
          this.loginService.changePassword(email, this.actualPass, this.password).subscribe(
            (data) => console.log(data),
            (err) => console.error
          );
        })
        .catch(console.error);
      }
    }
  }

}
