import { trigger, animate, transition, style } from '@angular/animations';

export const leaveAnimation = [trigger(
  'leaveAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('100ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('100ms', style({ opacity: 0 }))
    ])
  ]
)
];
