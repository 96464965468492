import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DatabaseService } from 'src/app/providers/database/database.service';
import { AuthService } from 'angularx-social-login';
/**
 * AuthenticationService
 * @description Service to Auth User
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /** User login status */
  public authStatus: boolean;
  /** JWT service */
  public jwt = new JwtHelperService();
  constructor(
    public router: Router,
    private storage: DatabaseService,
    private oauth: AuthService
  ) { }

  /**
   *   Check connection with token
   */
  public getToken(): Promise<string> {
    return this.storage.get('token')
    .then((token) => {
      if (!token)
        return '';
      return Promise.resolve(token);
    })
    .catch(() => Promise.resolve(''));
  }

  /**
   *  Use get token method to return authentication boolean
   */
  public checkAuth(): Promise<boolean> {
    return this.getToken()
    .then((token) => {
      if (!token)
        return Promise.resolve(false);
      if (token.length === 0)
        return Promise.resolve(false);
      if (this.jwt.isTokenExpired(token))
        return Promise.resolve(false);
      return Promise.resolve(true);
    });
  }

  /**
   * Logout user
   */
  public async logout(): Promise<void> {
    await this.oauth.signOut();
    await this.router.navigateByUrl('');
  }
}

