import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Circle } from 'src/app/providers/models/circle.model';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { fadeAnimation } from 'src/app/providers/animations/fadeAnimation.animation';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { trigger, transition, style, animate } from '@angular/animations';

/**
 * Components that handle signup section relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-signup-section',
  templateUrl: './signup-section.component.html',
  styleUrls: ['./signup-section.component.scss'],
  animations: [enterAnimation, fadeAnimation,
               trigger('fade', [
                 transition(':enter', [
                   style({ opacity: 0 }),
                   animate(200, style({opacity: 1}))
                 ])
               ])
  ]
})
export class SignupSectionComponent implements OnInit {
  /** Custom content status */
  public showDynamicContent = true;
  /** Circle section status */
  public circleSection = false;
  /** Mobile button status */
  public isMobileBtn = true;
  /** Mobile password popup status */
  public isPopup = false;
  /** Login form view status */
  public displayLogin = false;
  /** Signup form view status */
  public displaySignup = false;
  /** Circle signup form status */
  public displayCircleForm = false;
  /** REgister form status */
  public displayRegister = true;
  /** Form content */
  public formContent: string;
  /** User mail */
  public mail: string;
  /** Mobile view status */
  public displayMobile = false;
  /** Circle information */
  @Input() circle: Circle;
  /** User device status */
  @Input() isMobile: boolean;
  /** Connection by circle status */
  @Input() isCircleConnected: boolean;

  constructor(
    public router: Router,
    public circleService: CirclesService,
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    public loginService: LoginService,
    public authenticationService: AuthenticationService,
    private stats: StatsService,
    private statusService: StatusService
  ) { }

  /**
   * Get circle information and set signup view
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (data) => {
        if (data) {
          this.authenticationService.checkAuth()
          .then((status) => {
            if (status) {
              this.circleSection = true;
              this.displayCircleForm = true;
              this.displayLogin = false;
              this.displaySignup = false;
              this.displayRegister = false;
              if (window.innerWidth <= 765) {
                this.isMobileBtn = false;
                this.displayMobile = true;
              }
            } else {
              this.circleSection = true;
              this.displayCircleForm = false;
              this.displayLogin = false;
              this.displayRegister = true;
              this.displaySignup = false;
            }
          })
          .catch(console.error);
        } else {
          this.circleSection = false;
          this.displayCircleForm = false;
          this.displayLogin = false;
          this.displayRegister = true;
          this.displaySignup = false;
        }
      });
    this.statusService.formStatus.subscribe(
      (data) => {
        if (data === 'login') {
          this.displayLogin = true;
          this.displayRegister = false;
          this.displaySignup = false;
        } else if (data === 'register') {
          this.displayLogin = false;
          this.displayRegister = true;
          this.displaySignup = false;
        }
      }
    );
  }

  /**
   *  Scroll to $element
   * @param $element Id to scroll to
   */
  scrollToSection($element: any): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  /**
   *  If validator is OK, go to signup form
   * @param $event Form is valid, Email, Password
   */
  public goSignup($event: Array<any>): void {
    if ($event[0] === 0) {
      this.displaySignup = true;
      this.circleSection = true;
      this.displayCircleForm = false;
      this.displayRegister = false;
      this.displayLogin = false;
      this.formContent = $event[1];
    } if ($event[0] === 1) {
      this.displaySignup = false;
      this.circleSection = true;
      this.displayCircleForm = true;
      this.displayRegister = false;
      this.displayLogin = false;
    }
  }

  /**
   *  Go back to login form
   * @param $event Is circle form
   */
  public goLogin($event: number): void {
    if ($event === 1) {
      this.circleSection = false;
      this.displayCircleForm = false;
      this.displayLogin = true;
      this.displaySignup = false;
      this.displayRegister = false;
    }
    else if ($event === 2) {
      this.circleSection = true;
      this.displayCircleForm = true;
      this.displayLogin = false;
      this.displaySignup = false;
    }
    else if ($event === 3) {
      this.circleSection = false;
      this.displayCircleForm = false;
      this.displayLogin = false;
      this.displaySignup = false;
      this.displayRegister = true;
    }
  }

  /**
   *  Show mobile form
   * @param $event Is mobile
   */
  public hideLogin($event: boolean): void {
    if (!$event) {
      this.displayMobile = $event;
      this.isMobileBtn = true;
      this.displayLogin = false;
      this.displayRegister = false;
    } else {
      this.displaySignup = false;
      this.displayRegister = true;
    }
  }

  /**
   *  Show mobile form
   * @param $event Is mobile
   */
  public hideCircleForm($event: boolean): void {
    this.displayMobile = $event;
    this.isMobileBtn = true;
  }

  /**
   *  Custom form on mobile
   */
  public showMobile(): void {
    if (this.displayCircleForm) {
      this.displayMobile = true;
      this.displayLogin = false;
      this.isMobileBtn = false;
      this.displayRegister = false;
    } else {
      this.displayMobile = true;
      this.displayLogin = false;
      this.displayRegister = true;
      this.isMobileBtn = false;
    }
  }

  /**
   *  Go to top
   */
  public scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  /**
   * Open/close password popup
   */
  public passwordPopup(param: boolean): void {
    this.isPopup = param;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
