import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { Tags } from 'src/app/providers/models/tags.models';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Component that handles bonus-tag on mobile related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-bonus-tags-mobile',
  templateUrl: './bonus-tags-mobile.component.html',
  styleUrls: ['./bonus-tags-mobile.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0'
      })),
      state('final', style({
        overflow: 'hidden',
        opacity: '1'
      })),
      transition('initial=>final', animate('400ms')),
      transition('final=>initial', animate('400ms'))
    ])
  ]
})
export class BonusTagsMobileComponent implements OnInit {

  /*** Searched tag status */
  @Input() isTag: boolean;
  /*** Sticky searchbar status */
  @Input() isSticky: boolean;
  /*** Tag list view statius emitter */
  @Output()
  closeTag: EventEmitter<any> = new EventEmitter();
  /*** Searched tags emitter */
  @Output()
  tagName: EventEmitter<string> = new EventEmitter();
  /*** User selected tags */
  public selectedTags = [];
  /*** User selected tags */
  public tagList: Array<Tags>;
  /** Selected tag from homepage */
  public tagHome: string;

  constructor(
    public statusService: StatusService,
    public route: ActivatedRoute,
    private stats: StatsService
  ) { }

  /**
   * Get tags list and searched tag if the user comes from the home page
   */
  ngOnInit(): void {
    this.route.data.subscribe(
      (res) => {
        this.tagList = res.bonusTag.data
        .sort((a, b) => a.title.localeCompare(b.title));
        this.tagHome = history.state.tag;
        if (this.tagHome) {
          this.selectTag(this.tagHome);
          this.tagSearch(this.tagHome);
        }
      }
    );
  }

  /**
   * Change css on selection
   * @param param tag selected
   */
  public selectTag(param: string): void {
    const index = this.selectedTags.indexOf(param);
    if (index === -1)
      this.selectedTags.push(param);
    else
      this.selectedTags.splice(index, 1);

  }

  /**
   * Emit tag name on click
   * @param tag Tag name
   */
  public tagSearch(tag: string): void {
    this.tagName.emit(tag);
  }
  /**
   * Close cloud tag
   */
  public close(): void {
    this.closeTag.emit();
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}

