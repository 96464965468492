import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PathScene } from 'src/app/providers/models/pathScene.model';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';

/**
 * PathsService
 * @description Service to handle Path
 */
@Injectable({
  providedIn: 'root'
})
export class PathsService {

  /** Property to check whether to display Path */
  public pathsShowed = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpService
  ) { }

  /**
   *  Get paths and scenes
   * @param email User's mail
   */
  public getPathsScene(email: string): Observable<ServerResponse<Array<PathScene>>> {
    return this.http.get<ServerResponse<Array<PathScene>>>(`/content/user/${email}/path/amd`);
  }

  /**
   * On mobile, hide/show carousel
   * @param ev Is path showed
   */
  public showPaths(ev: boolean): void {
    this.pathsShowed.next(ev);
  }

}
