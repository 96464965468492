import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { StatusService } from '../../../modules/singleton/status.service';
import { HistoryService } from '../../../modules/history/history.service';

/**
 * Component for the cookie policy page
 */
@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
  /** Store window size */
  public innerWidth;

  /** Store previous url for redirection */
  public previousUrl: string = null;

  /** Initial dropdown value */
  public selected = 'article-02';

  /** Array of dropdown options */
  public articleList = [
    { id: 'article-02', text: 'Qu\'est-ce qu\'un Cookie ?' },
    { id: 'article-03', text: 'Identification des Cookies' },
    { id: 'article-04', text: 'Vos préférences en matière de Cookies' }
  ];

  /**
   * Component dependencies
   * @param router Angular Routing
   * @param statusService Custom Status Service for header handling
   * @param historyService handles navigation history stocked on the application
   */
  constructor(
    public router: Router,
    public statusService: StatusService,
    public historyService: HistoryService
  ) { }

  /**
   * On init handle initial window size to know if we are on mobile or not also handle live window resize
   */
  ngOnInit(): void {
    this.historyService.getUrl()
    .then((url) => {
      this.previousUrl = url;
      this.historyService.setUrl();
    })
    .catch(console.error);

    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.statusService.showHeaderNotConnected(false);
      this.statusService.showHeader(false);
      this.statusService.showNavMobile(false);
    } else {
      this.statusService.showHeaderNotConnected(true);
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
  }

  /**
   * handle window resize
   * @param event resize of window
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.statusService.showHeaderNotConnected(false);
      this.statusService.showHeader(false);
      this.statusService.showNavMobile(false);
    } else {
      this.statusService.showHeaderNotConnected(true);
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
  }

  /**
   * scroll
   * @description scrolls into defined anchor on the page.
   * @event scroll Into View.
   * @param id of element to scroll into
   */
  public scroll(elementId: string): void {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Navigate to previous page
   */
  public async backClicked(): Promise<void> {
    this.statusService.showHeaderFooter(true);

    if (this.innerWidth < 768) this.statusService.showNavMobile(true);

    if (this.previousUrl === null) await this.router.navigateByUrl('/home');

    await this.router.navigateByUrl(this.previousUrl);
  }
}
