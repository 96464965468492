import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { LoginService } from './http/login/login.service';
import { ErrorInterceptor } from './interceptor/error.interceptor';

/**
 * Modules required to operate the modules components
 */
@NgModule({
  declarations: []
  ,
  imports: [
    CommonModule,
    HttpClientModule

  ],
  providers: [
    AuthGuard,
    LoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],

  exports: []
})

export class ModulesModule { }
