import { Injectable } from '@angular/core';
import { Scene } from 'src/app/providers/models/scene.model';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { Observable } from 'rxjs';
import { Badge } from 'src/app/providers/models/badge.model';
import { SceneTransition } from 'src/app/providers/models/sceneTransition.model';

/**
 * SceneService
 */
@Injectable({
  providedIn: 'root'
})
export class SceneService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * Gets a scene from its url
   * @param email user email
   * @param url scene url
   * @param device user device
   */
  public getScene(email: string, url: string, device: string): Observable<ServerResponse<Scene>> {
    return this.http.post<ServerResponse<Scene>>(`/content/user/${email}/scene/${url}`, { device });
  }

  /**
   * Updates a scene for a user
   * @param email User email
   * @param url Scene url
   * @param params POST params (score, tag)
   */
  public updateScene(email: string, url: string, params: any): Observable<ServerResponse<void>> {
    return this.http.put<ServerResponse<void>>(`/content/user/${email}/scene/${url}`, params);
  }

  /**
   * Handles unlocks (next scene, badge) for a user after finishing a scene
   * @param email User email
   * @param url Scene url
   * @param params POST params (tag)
   */
  public handleUnlocks(email: string, url: string, params: any): Observable<ServerResponse<SceneTransition>> {
    return this.http.post<ServerResponse<SceneTransition>>(`/content/user/${email}/handleUnlock/${url}`, params);
  }

}

interface UnlockResponse {
  /** Next Scene Unlocked */
  nextScene: Scene;
  /** Badge Unlocked */
  badge: Badge;
}
