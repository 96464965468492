import { trigger, transition, style, animate } from '@angular/animations';

export const zoomIn = [
  trigger('zoomIn', [
    transition(':enter', [
      style({ transform: 'scale(0)'}),
      animate('400ms ease-in-out', style({ transform: 'scale(1.2)' })),
      animate('300ms ease-in-out', style({ transform: 'scale(1.1)' })),
      animate('200ms ease-in-out', style({ transform: 'scale(1)' }))
    ])
  ])
];
