import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { LightPath } from 'src/app/providers/models/lightPath.model';
import { ThemeStats } from 'src/app/providers/models/themeStats.model';
/** Elelements that handle badge banner component */
@Component({
  selector: 'app-badge-banner',
  templateUrl: './badge-banner.component.html',
  styleUrls: ['./badge-banner.component.scss'],
  animations: [enterAnimation]
})
export class BadgeBannerComponent implements OnInit {

  constructor() { }
  /** Current path information */
  @Input() currentPath: LightPath;
  /** Theme banner information */
  @Input() themeStats: Array<ThemeStats>;
  /** Total scenes on path */
  public totalPathScenes: number = 0;
  /** Finished scenes on path */
  public finishedPathScenes: number = 0;

  /** Get total path scenes */
  ngOnInit(): void {
    this.themeStats.forEach((theme) => {
      this.totalPathScenes += +theme.totalScenes;
      this.finishedPathScenes += +theme.finishedScenes;
    });
  }

}
