import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ThemesService } from 'src/app/modules/http/themes/themes.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { InterventionService } from 'src/app/modules/http/intervention/intervention.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Component that handles intervention declaration related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-declare-intervention',
  templateUrl: './declare-intervention.component.html',
  styleUrls: ['./declare-intervention.component.scss'],
  animations: [enterAnimation]
})
export class DeclareInterventionComponent implements OnInit {

  /*** Declaration page view status emitter */
  @Output()
  newDeclaration: EventEmitter<boolean> = new EventEmitter();
  /*** Get page status */
  @Input() isDeclare: boolean;
  /*** Avalaible theme array */
  public themesList = [];
  /*** Avalaible declaration array */
  public declaration = [];
  /*** User declaration */
  public message: string;
  /*** Publication status */
  public contacted = false;
  /** Intervention status */
  public isSend = false;
  /** User's mail */
  public userMail: string;

  constructor(
    public themeService: ThemesService,
    public router: Router,
    private interService: InterventionService,
    private userService: UserService,
    private stats: StatsService
  ) { }

  /**
   * Get theme array
   */
  ngOnInit(): void {
    this.themeService.getThemes().subscribe(
      (info) => {
        this.themesList = info;
      }
    );
    this.userService.getUserMail()
    .then((mail) =>
      this.userMail = mail)
    .catch(() => console.error());
  }

  /**
   * Submit form data
   * @param form Form data
   */
  onSubmit(form: NgForm): void {
    this.logEvent('[EH] Intervention_CTA-send_Clicked', {contact: form.value.contacted});
    this.interService.declareIntervention(this.userMail, form.value).subscribe(
      (data) => {this.isSend = true; },
      (err) => {console.error(); }
    );
  }

  /**
   * Go back to profil page
   */
  public backToProfil(): void {
    this.newDeclaration.emit(false);
    this.router.navigateByUrl(`/profil`)
    .catch(console.error);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
