/* eslint-disable @typescript-eslint/tslint/config */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PathComponent } from './pages/path/path.component';
import { AuthGuard } from './modules/guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { BonusComponent } from './pages/bonus/bonus.component';
import { ProfilComponent } from './pages/profil/profil.component';
import { SceneComponent } from './pages/scene/scene.component';
import { NewPasswordComponent } from './pages/landing-page/new-password/new-password.component';
import { CreditsComponent } from './pages/terms-of-service/credits/credits.component';
import { CirclesResolver } from './modules/resolvers/circles.resolver';
import { BonusResolver } from './modules/resolvers/bonus.resolver';
import { BonusTagResolver } from './modules/resolvers/bonusTag.resolver';
import { PathSceneResolver } from './modules/resolvers/pathsScene.resolver';
import { PrivacyPolicyComponent } from './pages/terms-of-service/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms-of-service/terms-of-use/terms-of-use.component';
import { LegalNoticeComponent } from './pages/terms-of-service/legal-notice/legal-notice.component';
import { CookiePolicyComponent } from './pages/terms-of-service/cookie-policy/cookie-policy.component';
import { GetUserResolver } from './modules/resolvers/get-user-data.resolver';
import { HomeResolver } from './modules/resolvers/home.resolver';
import { HomeTagsResolver } from './modules/resolvers/homeTags.resolver';
import { UnsubscribeComponent } from './pages/landing-page/unsubscribe/unsubscribe.component';
import { CircleRedirectionComponent } from './providers/components/circle-redirection/circle-redirection.component';
import { AboutComponent } from './pages/about/about.component';
import { LockedViewComponent } from './pages/scene/locked-view/locked-view.component';
import { ContestRulesComponent } from './pages/terms-of-service/contest-rules/contest-rules.component';
import { ClarkComponent } from './pages/clark/clark.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'landing.php', component: LandingPageComponent },
  { path: 'index.php', component: LandingPageComponent },
  { path: 'landing', component: LandingPageComponent },
  {
    path: 'path', canActivate: [AuthGuard], component: PathComponent,
    resolve: { path: PathSceneResolver }
  },
  { path: 'about', canActivate: [AuthGuard], component: AboutComponent },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
    resolve: { userInfo: GetUserResolver, home: HomeResolver, tags: HomeTagsResolver }
  },
  {
    path: 'bonus', canActivate: [AuthGuard], component: BonusComponent,
    resolve: { bonus: BonusResolver, bonusTag: BonusTagResolver }
  },
  { path: 'clark', canActivate: [AuthGuard], component: ClarkComponent },
  { path: 'password', component: NewPasswordComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  {
    path: 'profil', runGuardsAndResolvers: 'always', canActivate: [AuthGuard], component: ProfilComponent,
    resolve: { circles: CirclesResolver, paths: PathSceneResolver, userInfo: GetUserResolver },
    children: [
      { path: 'intervention', canActivate: [AuthGuard], component: ProfilComponent },
      { path: 'circle', canActivate: [AuthGuard], component: ProfilComponent, resolve: { circles: CirclesResolver } },
      {
        path: 'edition',
        canActivate: [AuthGuard],
        component: ProfilComponent,
        resolve: { userInfo: GetUserResolver }
      }
    ]
  },
  { path: 'scene/block', canActivate: [AuthGuard], component: LockedViewComponent },
  { path: 'scene/:url', canActivate: [AuthGuard], component: SceneComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'confidentialite', component: PrivacyPolicyComponent },
  { path: 'cookies', component: CookiePolicyComponent },
  { path: 'cgu', component: TermsOfUseComponent },
  { path: 'mentions', component: LegalNoticeComponent },
  { path: 'orano', component: CircleRedirectionComponent, data: { circle: '/?circle=dcc351aa93e104a8c0c952268abcd58a0bb20ca1aabd88a62b6ea419744d46fa' } },
  { path: 'academiedaixmarseille', component: CircleRedirectionComponent, data: { circle: '/?circle=73b7277aef26f586b3c3588c0d10c5b6d9a849ed6d49c1d8e1706b5e068eebda' } },
  { path: 'academiedamiens', component: CircleRedirectionComponent, data: { circle: '/?circle=b11845d3231c8e6b47a81dff071b1c6ee0415e071f987a52022874522d674e69' } },
  { path: 'academiedebesancon', component: CircleRedirectionComponent, data: { circle: '/?circle=40f4e7ce7bf3aedb5bf95538245314d442b05d0c2c4b5953c3170ed0d093b12a' } },
  { path: 'academiedebordeaux', component: CircleRedirectionComponent, data: { circle: '/?circle=59fd1397866b9ae576a2d9324caafb9f448eb554248cc3655235e928ed2aa1ba' } },
  { path: 'academiedecaen', component: CircleRedirectionComponent, data: { circle: '/?circle=bf913b71df455ac773d0e112c0c4bf505c5ebb3a5bc6923fcfcbd452072c4690' } },
  { path: 'academiedeclermontferrand', component: CircleRedirectionComponent, data: { circle: '/?circle=cf18862780e31f477349f4ae80b42a2a23762e8534fbccde991420b16ac42dc4' } },
  { path: 'academiedecorse', component: CircleRedirectionComponent, data: { circle: '/?circle=dbb59b7729d26626e95f3976321bf5d9c15e3b224d040436cd9f8c864a20f73a' } },
  { path: 'academiedecreteil', component: CircleRedirectionComponent, data: { circle: '/?circle=ae582b0a80a110ad9258de4460c79adc5db60926291ba84a017c8c36bfa429b1' } },
  { path: 'academiedegrenoble', component: CircleRedirectionComponent, data: { circle: '/?circle=d95b798d7b4b3a130753ce1b22890344c51967119018a35d0abd8fedfaa5156d' } },
  { path: 'academiedelaguadeloupe', component: CircleRedirectionComponent, data: { circle: '/?circle=a49f45a9809cc2a1c3ebf01d1a4ba394ff5b5f8908a1371173f66fcfe62e6024' } },
  { path: 'academiedeguyane', component: CircleRedirectionComponent, data: { circle: '/?circle=8b43e24eacbbdbdfa827d8184d08e53dc3c2c03a2538396c5bc0f2e58dedaac7' } },
  { path: 'academiedelareunion', component: CircleRedirectionComponent, data: { circle: '/?circle=64995d3c8acb4943ec0bcd9f8db0cd0320b08f4ed3dee125582122931b8684b9' } },
  { path: 'academiedelille', component: CircleRedirectionComponent, data: { circle: '/?circle=c59eb6e58f3d13f55b16aa9647eb3df2031fe274c9fd27cc766677167d7972fb' } },
  { path: 'academiedelimoges', component: CircleRedirectionComponent, data: { circle: '/?circle=b9db721449ba2d207c74409ac34b56a243ca670bf5ea5a4783c2791096305422' } },
  { path: 'academiedelyon', component: CircleRedirectionComponent, data: { circle: '/?circle=26fdfe5d38560ae67417e5f3f32f78110533d1e6841c74aa0370eaa1c25adca0' } },
  { path: 'academiedelamartinique', component: CircleRedirectionComponent, data: { circle: '/?circle=40d97b30434fe8784fbfa7ab11e9abdb1780fa49e025f55d213496189dcd67ab' } },
  { path: 'academiedemayotte', component: CircleRedirectionComponent, data: { circle: '/?circle=18fa86f7c4fe94c96b76e8def26bd8b356e6abb2afcb7f120ee570a6e763942d' } },
  { path: 'academiedemontpellier', component: CircleRedirectionComponent, data: { circle: '/?circle=d12a76e4104c1fad0bd8f0d4639c1b9adf1b34f677ad327dd6494928b01e81c8' } },
  { path: 'academiedenancymetz', component: CircleRedirectionComponent, data: { circle: '/?circle=5622fe920bf340e5a33dd2fd35127edc34dd44fa04547d401c59d6db28a2a7fa' } },
  { path: 'academiedenantes', component: CircleRedirectionComponent, data: { circle: '/?circle=9f558fc12f9bed2d49d4dfad73e35f810dadaa4e1c432b7be0f7931eb86c4914' } },
  { path: 'academiedenice', component: CircleRedirectionComponent, data: { circle: '/?circle=8044955057cb120e0dbb8581f14f064dd4a0474225b63ab3fae251dd06014944' } },
  { path: 'academiedorleanstours', component: CircleRedirectionComponent, data: { circle: '/?circle=cc658a80bdafbbe0766bd944b1cc36d717df788ddad4a1f62155249398d7f046' } },
  { path: 'academiedeparis', component: CircleRedirectionComponent, data: { circle: '/?circle=6fdeb99e6a629275a5c7377974096d0763d914dd6060b150e447993ce172619e' } },
  { path: 'academiedepoitiers', component: CircleRedirectionComponent, data: { circle: '/?circle=39529c0b3943cc5d15a875b3b133ccfe6443721b2c1b9fc42c6a58a2e1ad792e' } },
  { path: 'academiedereims', component: CircleRedirectionComponent, data: { circle: '/?circle=c85e516c08156d613f8f4aeee069c3334fa76100038392aa0e1e2b40359e2f96' } },
  { path: 'academiederennes', component: CircleRedirectionComponent, data: { circle: '/?circle=a66ad4af76364f965d24c75ae83d3aac2c453bb3f161f6f2a546e5d40c29959d' } },
  { path: 'academiederouen', component: CircleRedirectionComponent, data: { circle: '/?circle=27a18ef3ce5e11d2366ad41286b2a87a21cbf0fc5fccca14566f4824ad5a8eb0' } },
  { path: 'academiedestrasbourg', component: CircleRedirectionComponent, data: { circle: '/?circle=ace834015f9c4eafcd1caebfb774a5763d5d4b8fed5210584f4f44c1d51d52ef' } },
  { path: 'academiedetoulouse', component: CircleRedirectionComponent, data: { circle: '/?circle=d34486cbcb3e0531166ba6be0c90655c3a2242c1dff0d1606f230d5334756447' } },
  { path: 'academiedeversailles', component: CircleRedirectionComponent, data: { circle: '/?circle=476b4b87bc02c059c9eab145e6abf213a42283375bdd0e605ae5e679453b72aa' } },
  { path: 'academiededijon', component: CircleRedirectionComponent, data: { circle: '/?circle=312bacc4806266d869dc38a4e5f0a33dd3b512215761c264490481edbbcac391' } },
  { path: 'officedepot', component: CircleRedirectionComponent, data: { circle: '/?circle=429ac9e90ed4ce96d6530c0fb360b8ecb52b04df39d7d4a44fb1beec341e935e' } },
  { path: 'mairiedegarges', component: CircleRedirectionComponent, data: { circle: '/circle=02dc78585270b76596b90bd89fe34a851417f69b69b9994d5618de53bcb1964e' } },
  { path: 'h&m', component: CircleRedirectionComponent, data: { circle: '/?circle=3e1f50dbe3fba79e4fe13ba0f1bb11c5da48db1e0f1afb6c667f74b733a89a3c' } },
  { path: 'parisquisauve', component: CircleRedirectionComponent, data: { circle: '/?circle=fb07cbfeb13e87fb3f136373e105284fe82f5ffe3d4ae434339e9388fc2fe188' } },
  { path: 'secteurlasseube', component: CircleRedirectionComponent, data: { circle: '/?circle=56c3c75de3f7ac1e96b8c1194186f525b6032ca6fe58f537268369f621139078' } },
  { path: 'thomasdumorey', component: CircleRedirectionComponent, data: { circle: '/?circle=1e0f857d2a6e959f8a8b1042891d2a02c7639e47a9706ea5d8da2927bbd8b757' } },
  { path: 'tousagentstousheros', component: CircleRedirectionComponent, data: { circle: '/?circle=e2e3f89aefe62e791c0d8bc84ed258d981e76869322b3926650f1f24bca0b95f' } },
  { path: 'covi', component: CircleRedirectionComponent, data: { circle: '/?circle=2338ee042f9d126c0c60e6a332dec16b58efc563f992d6d0adb79471081b1e8e' } },
  { path: 'carresenart', component: CircleRedirectionComponent, data: { circle: '/?circle=6ca8c717cf523385ee59446196d3ae77ae2a01275bf939b21310fa82c611b8cd' } },
  { path: 'confluence', component: CircleRedirectionComponent, data: { circle: '/?circle=c198f0095bdb5a72a6451d9a7b446a3318864ff1e96a48f2426f5a2fd8119ba3' } },
  { path: 'euralille', component: CircleRedirectionComponent, data: { circle: '/?circle=54c513975b9ff3b4fac49406b30ec104df8e1d2832899ca99d0387d6de72b768' } },
  { path: 'parly2', component: CircleRedirectionComponent, data: { circle: '/?circle=00d7f78c2ab65688cf31405e2cd5d90ce56bffb03c780d7923ddeb024768df13' } },
  { path: 'les4temps', component: CircleRedirectionComponent, data: { circle: '/?circle=377fb09dc985f276509fccead12aa52bec1a8af462c8d24df0323eff01edc841' } },
  { path: 'toisondor', component: CircleRedirectionComponent, data: { circle: '/?circle=15f9971148621f35f6b3649d5ee93fe3f9ba317c67b053262279199dda9c3da5' } },
  { path: 'velizy2', component: CircleRedirectionComponent, data: { circle: '/?circle=a6b5c016b48df0b82fcf64410abef10651db6496298dcc1467e11eac8b40b1c9' } },
  { path: 'rosny2', component: CircleRedirectionComponent, data: { circle: '/?circle=714608fc9a357a429b283ca5dfa4eebae98ed3973fc14a703acc257441349b39' } },
  { path: 'polygoneriviera', component: CircleRedirectionComponent, data: { circle: '/?circle=c94acc8ad5927abfe441554af6bb8c3d0d4d716ff9119ac24037afbd8d579063' } },
  { path: 'v2', component: CircleRedirectionComponent, data: { circle: '/?circle=868311064e058a3b30d65756bf1731cec4e946823061f07089ba0859d1cf3999' } },
  { path: 'aeroville', component: CircleRedirectionComponent, data: { circle: '/?circle=3085516f081a8321d801de75752a8379ce738b5b2374780162995fc90490f0ac' } },
  { path: 'reglementconcoursdesheroes', component: ContestRulesComponent },
  { path: 'savproformation', component: CircleRedirectionComponent, data: { circle: '/?circle=19e21f7567ea0a2513c44ed6892ea74392496b0ce01275ecf2ca9d876568e8ce' } },
  { path: 'doctolib', component: CircleRedirectionComponent, data: { circle: '/?circle=985220ea948c6738a8cdb42a027ef81970db4686a5d09b85673675f88355b6ba' } },
  { path: 'leadersante', component: CircleRedirectionComponent, data: { circle: '/?circle=c73b5a39944cc2d71dcc300b0865e4fefb0199c5b2841d69a259e62f82bd3fdd' } },
  { path: 'villedeparis', component: CircleRedirectionComponent, data: { circle: '/?circle=e2e3f89aefe62e791c0d8bc84ed258d981e76869322b3926650f1f24bca0b95f' } },
  { path: 'villedeparis', component: CircleRedirectionComponent, data: { circle: '/?circle=e2e3f89aefe62e791c0d8bc84ed258d981e76869322b3926650f1f24bca0b95f' } },
  { path: 'harmonie-mutuelle', component: CircleRedirectionComponent, data: { circle: '/?circle=da826e77a76f7f5d17e20c33178525361b5e1b9d4463e4db6f1fa4150fe21ae9' } },
  { path: 'forumdeshalles', component: CircleRedirectionComponent, data: { circle: '/?circle=705162c9e02144c9dfb9a95cc8ded2df9725ecc6d705d5b66028154fc512cc01' } },
  { path: 'alma', component: CircleRedirectionComponent, data: { circle: '/?circle=112850868fd082f1e47c9920e8f037b92047fbc9ad91eaf38afd5c583a40f89e' } },
  { path: 'soouest', component: CircleRedirectionComponent, data: { circle: '/?circle=8767f5f7f6390fef260be0a473477b090b7c8100c25463fcc1bf357829e8ae5c' } },
  { path: 'lapartdieu', component: CircleRedirectionComponent, data: { circle: '/?circle=85ad2869916d7075016174964c9822320a27b8936711378440960483db1b7f7c' } },
  { path: 'feytiat', component: CircleRedirectionComponent, data: { circle: '/?circle=826b94f52d3296b0b1d36dfcb0b6ecca2928e6e3167dfea675f2afde68d68187' } },
  { path: 'greffetc', component: CircleRedirectionComponent, data: { circle: '?circle=837d9b398c293e4bd33388258d367394712ee0ea132f96a8da6aa796960a5b03' } },
  { path: 'feytiat', component: CircleRedirectionComponent, data: { circle: '/?circle=826b94f52d3296b0b1d36dfcb0b6ecca2928e6e3167dfea675f2afde68d68187' } }
];

/**
 * Modules required to operate the routing component
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [GetUserResolver]
})
export class AppRoutingModule { }
