import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, Inject } from '@angular/core';
import { HistoryService } from 'src/app/modules/history/history.service';
import { Router } from '@angular/router';
import { SceneTransition } from 'src/app/providers/models/sceneTransition.model';
import { LightPath } from 'src/app/providers/models/lightPath.model';
import { Scene } from 'src/app/providers/models/scene.model';
import { DOCUMENT } from '@angular/common';


/** Component handling unlocked scene view */
@Component({
  selector: 'app-unlocked-view',
  templateUrl: './unlocked-view.component.html',
  styleUrls: ['./unlocked-view.component.scss']
})
export class UnlockedViewComponent implements OnInit, AfterViewInit {
  /** User score */
  @Input() score: number;
  /** Next scene data */
  @Input() transitionData: SceneTransition;
  /** New banner emitter */
  @Output()
  themeBanner: EventEmitter<[LightPath, Scene?, boolean?]> = new EventEmitter();
  /** Page theme status emitter */
  @Output()
  themePage: EventEmitter<boolean> = new EventEmitter();
  /** Replay scene status emitter */
  @Output()
  replay: EventEmitter<boolean> = new EventEmitter();
  /** Heart array */
  public hearts = [];
  /** Top star animation status */
  public starTop = false;
  /** Bottom left star animation status */
  public starBottomLeft = false;
  /**Bottom right star animation status */
  public starBottomRight = false;
  /** Unlocked badge page status */
  public unlockedBadge = false;
  /** Unlocked theme page status */
  public unlockedTheme = false;
  /** Scene type status */
  public isBonus = false;
  constructor(
    public historyService: HistoryService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  /** Get score and fullfill hearts array for score animation, check scene type */
  ngOnInit(): void {
    let totalScore = this.score;
    while (this.hearts.length < 5) {
      if (totalScore >= 1) {
        this.hearts.push('https://imgs.everydayheroes.fr/EH2/scene/heart_full.gif');
        totalScore = totalScore - 1;
      } else if (totalScore >= 0.5) {
        this.hearts.push('https://imgs.everydayheroes.fr/EH2/scene/heart_half.gif');
        totalScore = totalScore - 0.5;
      } else if (totalScore < 0.5)
        this.hearts.push('https://imgs.everydayheroes.fr/EH2/scene/heart_empty.png');
    }
    if (!this.transitionData)
      this.isBonus = true;
  }

  /** Show stars animation */
  ngAfterViewInit(): void {
    this.starBottomRight = true;
    setTimeout(() => {
      this.starTop = true;
    }, 1000);
    setTimeout(() => {
      this.starBottomLeft = true;
    }, 1500);
  }

  /**
   * Launch scene to play
   * @param param Scene to play
   */
  public playScene(param: string): void {
    if (this.transitionData) {
      if (param === 'next') {
        if (this.transitionData.isUnlockedBadge) {
          this.unlockedBadge = true;
          this.themeBanner.emit([this.transitionData.path]);
        } else {
          if (this.transitionData.isUnlockedBadge && !this.transitionData.nextScene) {
            this.unlockedTheme = true;
            this.themePage.emit(true);
          } else if (!this.transitionData.isUnlockedBadge && this.transitionData.nextScene) {
            this.unlockedTheme = true;
            this.themeBanner.emit([this.transitionData.path, this.transitionData.nextScene, true]);
          } else {
            this.themeBanner.emit([this.transitionData.path, this.transitionData.nextScene]);
            this.unlockedTheme = true;
          }
        }
      } else if (param === 'replay')
        this.replay.emit(true);
    } else if (param === 'replay')
      this.replay.emit(true);
    else if (param === 'bonus') {
      this.router.navigateByUrl('/bonus')
      .catch(console.error);
    }
  }

  /**
   * Show unlocked theme page
   * @param $event: Theme page status
   */
  public showTheme($event: boolean): void {
    if (this.transitionData.nextScene)
      this.themeBanner.emit([this.transitionData.path, this.transitionData.nextScene]);
    this.unlockedBadge = false;
    this.unlockedTheme = true;
    this.themePage.emit(true);
  }
}
