import { Injectable } from '@angular/core';
import { Badge } from 'src/app/providers/models/badge.model';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { Observable } from 'rxjs';

/**
 * BadgesService
 * @description Service to handle Badges
 */
@Injectable({
  providedIn: 'root'
})
export class BadgesService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * Get user's badge
   * @param email User's mail
   */
  public getUserBadges(email: string): Observable<ServerResponse<Array<Badge>>> {
    return this.http.get<ServerResponse<Array<Badge>>>(`/platform/user/${email}/badges`);
  }

}
