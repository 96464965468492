import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { LoginResponse } from 'src/app/providers/models/LoginResponse';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';

/**
 * LoginService
 * @description Service to login a user
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  /** Check if user is logged in */
  public isLogin: BehaviorSubject<boolean>;
  /** Property to check is user came from a Circle Landing Page */
  public isCircleConnected = new BehaviorSubject<boolean>(false);
  /** User Auth Status */
  public currentStatus: Observable<boolean>;

  constructor(
    private http: HttpService,
    private authGuard: AuthenticationService
  ) {
    this.isLogin = new BehaviorSubject(false);
    this.authGuard.checkAuth()
    .then((status) => {
      this.isLogin.next(status);
    })
    .catch(console.error);
    this.currentStatus = this.isLogin.asObservable();
  }

  /**
   * Check if user is connected from a circle landing page
   * @param bool Circle connected status
   */
  public circleConnected(bool: boolean): void {
    this.isCircleConnected.next(bool);
  }

  /**
   *  Behaviour subject method to update user connection status
   * @param bool Check autentication status
   */
  public isConnected(bool: boolean): void {
    this.isLogin.next(bool);
  }

  /**
   *  Check user login
   * @param email User's email
   * @param password User's password
   */
  public login(email: string, password: string): Observable<ServerResponse<LoginResponse>> {
    return this.http.post<ServerResponse<LoginResponse>>(`/platform/user/${email}/session`, { password });
  }

  /**
   *  Register new user
   * @param email User's email
   * @param password User's password
   */
  public register(email: string, password: string, firstName: string, circleName: string): Observable<ServerResponse<LoginResponse>> {
    return this.http.post<ServerResponse<LoginResponse>>(`/platform/user/${email}`, { password, firstName, circleName });
  }


  /**
   * Change user's password
   * @param password User's new password
   * @param email User's mail
   * @param oldPassword User's old password
   */
  public changePassword(email: string, oldPassword: string, password: string): Observable<ServerResponse<void>> {
    return this.http.put<ServerResponse<void>>(`/platform/user/${email}/password`, { password, oldPassword });
  }

  /**
   * Reset user's password from mail link
   * @param password User's new password
   */
  public resetPassword(password: string): Observable<ServerResponse<void>> {
    return this.http.put<ServerResponse<void>>(`/platform/user/null/password`, { password });
  }

  /**
   * Send new password
   * @param email User's mail
   */
  public forgotPassword(email: string): Observable<ServerResponse<void>> {
    return this.http.get<ServerResponse<void>>(`/platform/user/${email}/password`);
  }

}
