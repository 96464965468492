import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProvidersModule } from './providers/providers.modules';
import { PagesModule } from './pages/pages.modules';
import { ModulesModule } from './modules/modules.modules';
import { LoginService } from './modules/http/login/login.service';
import { CirclesService } from './modules/http/circles/circles.service';
import { StatusService } from './modules/singleton/status.service';
import { SceneService } from './modules/http/scene/scene.service';
import { CirclesResolver } from './modules/resolvers/circles.resolver';
import { BonusResolver } from './modules/resolvers/bonus.resolver';
import { BonusTagResolver } from './modules/resolvers/bonusTag.resolver';
import { PathSceneResolver } from './modules/resolvers/pathsScene.resolver';
import { HomeResolver } from './modules/resolvers/home.resolver';
import { HomeTagsResolver } from './modules/resolvers/homeTags.resolver';
import { UnsubscribeComponent } from './pages/landing-page/unsubscribe/unsubscribe.component';
import { CircleRedirectionComponent } from './providers/components/circle-redirection/circle-redirection.component';
import { LockedViewComponent } from './pages/scene/locked-view/locked-view.component';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';

/** return jwt from the local storage*/
export function tokenGetter(): any {
  return window.localStorage.getItem('token');
}

/**
 * Modules required for the proper functioning of the application
 */
@NgModule({
  declarations: [
    AppComponent,
    UnsubscribeComponent,
    CircleRedirectionComponent,
    LockedViewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ProvidersModule,
    PagesModule,
    FormsModule,
    ModulesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: ['localhost:4200', 'staging.everydayheroes.fr', 'everydayheroes.fr'],
        authScheme: 'JWT '
      }
    })
  ],
  providers: [
    LoginService,
    CirclesService,
    StatusService,
    SceneService,
    CirclesResolver,
    BonusResolver,
    PathSceneResolver,
    HomeResolver,
    BonusTagResolver,
    HomeTagsResolver,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
