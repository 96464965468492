
import { trigger, animate, transition, style } from '@angular/animations';

export const blurAnimation = [
  trigger('blurAnimation', [
    transition(':enter', [
      style({ opacity: 0, position: 'absolute', right: '0', top: '0' }),
      animate(400, style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1, position: 'absolute', top: '0', right: '0' }),
      animate(400, style({ opacity: 0 }))
    ])
  ])
];
