import { Directive, HostListener } from '@angular/core';
/** Hide header directive on mobile */
@Directive({
  selector: '[appHeaderHide]'
})
export class HeaderHideDirective {
  /** Set offset */
  public lastOffset = 0;
  /** Set Last touch X */
  public touchLastX = 0;
  /** Set last touch Y */
  public touchLastY = 0;

  /**
   * Hide/show header on scroll down/up
   */
  @HostListener('window:scroll')
  onWindowScroll(): void {
    if (window.innerWidth < 765 && window.pageYOffset > 25) {
      if (window.pageYOffset > this.lastOffset) {
        const element = document.getElementById('connected');
        if (element) {
          element.classList.add('hide-header');
          this.lastOffset = window.pageYOffset; }
      } else {
        const element = document.getElementById('connected');
        if (element) {
          element.classList.remove('hide-header');
          this.lastOffset = window.pageYOffset; }
      }
    }
  }

  constructor() { }

}
