import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { transition, trigger, state, style, animate } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { map } from 'rxjs/operators';
import { Tags } from 'src/app/providers/models/tags.models';
import { ActivatedRoute } from '@angular/router';
import { StatsService } from 'src/app/modules/stats/stats.service';
/**
 * Component that handles bonus-tag related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-bonus-tags',
  templateUrl: './bonus-tags.component.html',
  styleUrls: ['./bonus-tags.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        overflow: 'hidden'
      })),
      state('final', style({
        overflow: 'hidden'
      })),
      transition('initial=>final', animate('400ms')),
      transition('final=>initial', animate('400ms'))
    ]),
    enterAnimation
  ]
})
export class BonusTagsComponent implements OnInit, OnChanges {
  /** Viewport width */
  @Input() innerWidth: number;
  /** Searched string */
  @Input() search = '';
  /** Searched tags view status */
  @Input() isTag = false;
  /** Searched tags view sticky status */
  @Input() isSticky = false;
  /** Searched tags emitter */
  @Output()
  tagName: EventEmitter<string> = new EventEmitter();
  /*** User selected tags */
  public selectedTags = [];
  /*** User selected tags */
  public tagList: Array<Tags>;
  /** Selected tag view list */
  public selectedClick = [];
  /** Selected tag from homepage */
  public tagHome: string;

  constructor(
    public domSanitizer: DomSanitizer,
    public statusService: StatusService,
    public route: ActivatedRoute,
    private stats: StatsService
  ) { }

  /**
   * Get tags list and select them on view
   */
  ngOnInit(): void {
    this.route.data.subscribe(
      (res) => {
        this.tagList = (res.bonusTag.data as Array<Tags>)
        .sort((a, b) => a.title.localeCompare(b.title));
        this.tagHome = history.state.tag;
        if (this.tagHome) {
          this.selectTag(this.tagHome);
          this.enter(this.tagHome);
          this.tagSearch(this.tagHome);
        }
      }
    );
  }
  /**
   * Update searched tags
   * @param changes Tag searched changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.search) {
      if (changes.search.currentValue)
        this.selectedTags = [];
    }
  }

  /**
   * Change css on selection
   * @param param tag selected
   */
  public selectTag(param: string): void {
    const index = this.selectedTags.indexOf(param);
    const indexClick = this.selectedClick.indexOf(param);
    if (this.innerWidth > 1191) {
      if (indexClick === -1)
        this.selectedClick.push(param);
      else {
        this.selectedClick.splice(indexClick, 1);
        this.selectedTags.splice(index, 1);
      }
    } else {
      if (index === -1)
        this.selectedTags.push(param);
      else
        this.selectedTags.splice(index, 1);
    }
  }

  /**
   * Change img on entering hover
   * @param param Tag title
   */
  public enter(param: string): void {
    if (this.innerWidth > 1191) {
      const index = this.selectedTags.indexOf(param);
      if (index === -1)
        this.selectedTags.push(param);
    }
  }

  /**
   * Change img on leaving hover
   * @param param Tag title
   */
  public quit(param: string): void {
    if (this.innerWidth > 1191) {
      const index = this.selectedTags.indexOf(param);
      const indexClick = this.selectedClick.indexOf(param);
      if (indexClick === -1 && index !== -1)
        this.selectedTags.splice(index, 1);

    }

  }

  /**
   * Emit tag name on click
   * @param tag Tag name
   */
  public tagSearch(tag: string): void {
    this.tagName.emit(tag);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
