import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/providers/http/http.service';
import { IAedStatus } from 'src/app/providers/models/aedStatus.model';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';

/**
 * ClarkService
 * @description Service to handle Clark data
 */
@Injectable({
  providedIn: 'root'
})
export class ClarkService {

  constructor(
    private http: HttpService
  ) { }


  /**
   *  Get Aed information
   * @param email User mail
   */
  public getAedData(email: string): Observable<ServerResponse<IAedStatus>> {
    return this.http.get<ServerResponse<IAedStatus>>(`/content/user/${email}/aed`);
  }

  /**
   *  Post Aed information to create user link
   * @param email User mail
   * @param serial Aed serial number
   */
  public postAedData(email: string, serial: string): Observable<ServerResponse<IAedStatus>> {
    return this.http.post<ServerResponse<IAedStatus>>(`/content/user/${email}/aed`, {serial});
  }

  /**
 * Post user message to CSM
 * @param message User msg
 */
  public postCSMMail(email: string, message: string, serial: string): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`/content/user/${email}/mail`, {serial, message});
  }
}
