import { trigger, animate, transition, style } from '@angular/animations';

export const slideInOut = [
  trigger('slideInOut', [
    transition(':enter', [
      style({ transform: 'translateY(50%)', opacity: 0 }),
      animate('400ms ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))
    ]),
    transition(':leave', [
      animate('300ms ease-in', style({ transform: 'translateY(50%)', opacity: 0 }))
    ])
  ])
];
