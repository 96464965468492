import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { Subject, Observable } from 'rxjs';
import { BonusScene } from 'src/app/providers/models/bonusScene.model';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { HttpClient } from '@angular/common/http';
import { Tags } from 'src/app/providers/models/tags.models';

/**
 * BonusService
 * @description Service to handle Bonus Scenes
 */
@Injectable({
  providedIn: 'root'
})
export class BonusService {

  constructor(
    private httpServ: HttpClient,
    private http: HttpService
  ) { }

  /** Tag Subject */
  public tag = new Subject<string>();

  /**
   * Get bonus list
   * @param email User's mail
   */
  public getBonus(email: string): Observable<ServerResponse<Array<BonusScene>>> {
    return this.http.get<ServerResponse<Array<BonusScene>>>(`/content/user/${email}/bonus`);
  }

  /**
   * Get tag list and order them alphabetically
   */
  public getTags(email: string): any {
    return this.http.get<ServerResponse<Array<Tags>>>(`/content/user/${email}/bonus/tags`);
  }
}
