import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { Observable } from 'rxjs';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';

/** Declare intervention service */
@Injectable({
  providedIn: 'root'
})
export class InterventionService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * Post an intervention
   * @param email User's mail
   * @param params User's message and contact boolean
   */
  public declareIntervention(email: string, params: object): Observable<ServerResponse<void>> {
    return this.http.post<ServerResponse<void>>(`/platform/user/${email}/intervention`, params);
  }

}
