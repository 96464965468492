import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { PathsService } from 'src/app/modules/http/paths/paths.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Path } from 'src/app/providers/models/paths.model';
import { Scene } from 'src/app/providers/models/scene.model';
import { PathScene } from 'src/app/providers/models/pathScene.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';

/**
 * Component that handles paths related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss'],
  animations: [
    enterAnimation,
    trigger(
      'fadeText', [
        transition(':enter', [
          style({ opacity: 0, position: 'absolute', width: '650px' }),
          animate('500ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1, position: 'absolute', width: '650px' }),
          animate('500ms', style({ opacity: 0 }))
        ])
      ]
    )
  ]
})
export class PathComponent implements OnInit, AfterViewInit {

  /** Carousel config */
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: ('.next'),
    prevArrow: ('.prev'),
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  /** Carousel component */
  @ViewChild('slickModal', null)
  slickModal: SlickCarouselComponent;
  /** Currrent slide index in view */
  public currentSlide: number;
  /** Paths list */
  public pathsList: Array<Path>;
  /** Actual path in view */
  public pathShowed: Path;
  /** Previous path in view */
  public prevPath: Path;
  /** Changing slide view status to activate animation */
  public change = true;
  /** Finished scene number */
  public validScene = 0;
  /** Scene total number */
  public totalScene = 0;
  /*** Viewport width */
  public innerWidth: number;
  /*** User device*/
  public isMobile = false;
  /** Next scene to play */
  public nextScene: PathScene;
  /** Path showed index */
  public pathIndex: number;
  constructor(
    public router: Router,
    public serviceLogin: LoginService,
    public statusService: StatusService,
    public pathsService: PathsService,
    public route: ActivatedRoute,
    private stats: StatsService,
    private historyService: HistoryService
  ) { }

  /**
   * Get user device, path data from resolver and calculate each path score
   */
  ngOnInit(): void {
    this.historyService.getUrl()
    .then((url) => {
      this.logEvent('[EH] Path_Page_Landed', {referrer: url});
    })
    .catch(console.error);
    this.historyService.setUrl();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 765)
      this.isMobile = true;
    else
      this.isMobile = false;
    this.statusService.showHeaderFooter(true);
    this.statusService.showNavMobile(true);
    this.route.data.subscribe(
      (data) => {
        this.pathsList = data.path.data;
      }
    );
    this.pathsList.push(this.pathsList.shift());
    for (let i = 0; i < this.pathsList.length; i++) {
      this.pathsList[i].index = i;
      this.pathsList[i].score = 0;
      this.pathsList[i].total = 0;
      this.pathsList[i].categories.forEach((categorie) => {
        categorie.scenes.map((x) => {
          this.pathsList[i].total += 1;
          if (x.score >= 4)
            this.pathsList[i].score += 1;
        });
      });
      if (this.pathsList[i].score / this.pathsList[i].total === 1)
        this.pathIndex = this.pathsList.findIndex((x) => x.idPath === (this.pathsList[i].idPath + 1));
    }
    if (this.pathIndex && this.pathIndex !== -1)
      this.pathShowed = this.pathsList[this.pathIndex];
    else
      this.pathShowed = this.pathsList[2];
    this.nextScene = this.getNextScene();
  }

  /**Scroll to next scene */
  ngAfterViewInit(): void {
    if (!this.isMobile && (this.pathShowed.score / this.pathShowed.total !== 1))
      setTimeout(() => this.scrollToSection(this.nextScene.idScene.toString()), 1000);
  }

  /**
   * Get next scene to play
   */
  public getNextScene(): PathScene {
    let lastScene: PathScene;
    for (const cat of this.pathShowed.categories) {
      for (const scene of cat.scenes) {
        if (!scene.locked)
          lastScene = scene;
        else
          break;
      };
    }
    return lastScene;
  }

  /**
   * Change the highlighted path, when cloned element add slick method
   * @param id Path id
   */
  public changePath(id: any): void {
    if (typeof id === 'number') {
      this.change = false;
      this.pathShowed = this.pathsList.find((x) => x.index === id);
      if (id === 0)
        this.prevPath = this.pathsList.find((x) => x.index === this.pathsList.length - 1);
      else if (id === this.pathsList.length - 1)
        this.prevPath = this.pathsList.find((x) => x.index === 0);
      else
        this.prevPath = this.pathsList.find((x) => x.index === id - 1);
      setTimeout(() => this.change = true, 100);
    } else {
      if (id.clientX < 1335) {
        this.change = false;
        this.pathShowed = this.pathsList.find((x) => x.index === 0);
        this.slickModal.slickGoTo(1);
        setTimeout(() => this.change = true, 100);
      } else {
        this.change = false;
        this.pathShowed = this.pathsList.find((x) => x.index === 1);
        this.prevPath = this.pathsList.find((x) => x.index === 0);
        this.slickModal.slickGoTo(2);
        setTimeout(() => this.change = true, 100);
      }
    }
    this.logEvent('[EH] Path_OtherPath_Clicked', {idPath: this.pathShowed.idPath});
    this.nextScene = this.getNextScene();
    if (!this.pathShowed.categories[0].scenes[0].locked && (this.pathShowed.score / this.pathShowed.total !== 1))
      setTimeout(() => this.scrollToSection(this.nextScene.idScene.toString()), 1000);
  }

  /**
   *  Scroll to $element
   * @param $element Id to scroll to
   */
  public scrollToSection($element: string): void {
    const yOffset = -80;
    const element = document.getElementById($element);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  /**
   * Scroll to child element
   * @param $element Child component id
   */
  public scrollToBottom($element: any): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  /**
   * Get current slide to show in top page
   * @param $event Carousel data
   */
  public afterChange($event: any): void {
    this.currentSlide = $event.currentSlide;
    const elements = $event.event.target.lastChild.firstChild.getElementsByClassName('slick-cloned');
    for (const element of elements)
      element.addEventListener('click', this.changePath.bind(this));
  }

  /**
   * Go to next scene to play
   */
  public goToScene(): void {
    this.router.navigateByUrl(`scene/${this.nextScene.url}`)
    .catch(console.error);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
