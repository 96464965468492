import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { DatabaseService } from 'src/app/providers/database/database.service';

/**
 * Components that handle new password popup relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  animations: [enterAnimation]
})
export class NewPasswordComponent implements OnInit {

  /** User password first field*/
  public password1: string;
  /** User password second field*/
  public password2: string;
  /** New password form submission status */
  public isSubmit = false;
  /** New password form error status */
  public error = false;
  /** User password first field error status*/
  public errPass1 = false;
  /** User password second field error status*/
  public errPass2 = false;
  /** Password identical fields status */
  public samePass = true;
  /** User reset password token */
  public resetToken: string;
  /** Password format status */
  public passFormat = true;
  /** Pasword regex */
  public regex = RegExp(/^(?=.*\d).{6,}$/);
  /** User mail status */
  public emailCheck = false;
  /** JWT service */
  public jwt = new JwtHelperService();
  /** Unkonown error status */
  public internError = false;
  constructor(
    public statusService: StatusService,
    public loginService: LoginService,
    public userService: UserService,
    public router: Router,
    private storage: DatabaseService
  ) { }

  /** Hide footer */
  ngOnInit(): void {
    this.statusService.showFooter(false);
  }

  /**
   * Check form fields and submit
   */
  submit(): void {
    this.internError = false;
    this.isSubmit = true;
    this.passFormat = this.regex.test(this.password2);
    if (!this.password1)
      this.errPass1 = true;
    else
      this.errPass1 = false;
    if (!this.password2)
      this.errPass2 = true;
    else
      this.errPass2 = false;
    if (this.password1 !== this.password2)
      this.samePass = false;
    else
      this.samePass = true;
    if (this.samePass && !this.errPass1 && !this.errPass2 && this.passFormat) {
      this.loginService.resetPassword(this.password2)
      .subscribe(
        (data) => {
          console.log(data);
          this.emailCheck = true;
          this.storage.remove('token')
          .then(() => {
            setTimeout(() => this.router.navigateByUrl(''), 2000);
          })
          .catch(console.error);
        },
        (err) => {
          console.log(err);
          this.internError = true;
        });
    }
  }
}
