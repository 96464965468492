import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { DatabaseService } from '../../database/database.service';
import { slideDown } from '../../animations/slideDown.animation';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { AuthService } from 'angularx-social-login';

/**
 * Components that handle plus dropdown relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-plus-section',
  templateUrl: './plus-section.component.html',
  styleUrls: ['./plus-section.component.scss'],
  animations: [enterAnimation, slideDown]
})
export class PlusSectionComponent {
  /** Control variable to avoid instantly closing the section */
  @Input() isPlusRecentlyShown: boolean;
  /** Dropdown view status */
  @Input() isPlusShow: boolean;
  /** Dropdown hover status */
  @Input() isPlusHover: boolean;
  /** Dropdwon view status emitter */
  @Output() closePlus = new EventEmitter<boolean>();
  /** Dropdown navigation data emitter */
  @Output() changePage = new EventEmitter<string>();
  /** * Viewport width */
  public innerWidth: number;
  /** Social share popup status */
  public isPopup = false;
  /**
 *  Check screen size
 * @param event Screen size
 */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    public router: Router,
    public serviceLogin: LoginService,
    private storage: DatabaseService,
    private stats: StatsService,
    private history: HistoryService,
    private oauth: AuthService
  ) { }

  /**
   *  Close plus section on click outside
   */
  public closeOnClick(): void {
    if (this.isPlusShow && !this.isPlusRecentlyShown)
      this.closePlus.emit(false);
  }

  /**
   *  Change page on click
   * @param param Page to navigate
   */
  public navTab(param: string): void {
    this.history.getUrl()
    .then((url) => {
      if (param === 'profil/circle')
        this.logEvent('[EH] Circle_Page_Landed', { referrer: url });
      else if (param === 'profil/intervention')
        this.logEvent('[EH] Intervention_Page_Landed', { referrer: url });
    })
    .catch(console.error);
    this.changePage.emit(param);
    this.closePlus.emit(false);
  }

  /**
   * Close session, reset score
   */
  public disconnect(): void {
    this.oauth.signOut().catch(console.error);
    this.closePlus.emit(false);
    this.serviceLogin.isConnected(false);
    this.storage.clear()
    .then(() => this.router.navigateByUrl(''))
    .catch(console.error);
  }

  /**
   * Log event when user clicks on discover lifeaz button
   */
  public discoverLifeaz(): void {
    this.logEvent('[EH] Home_navbarCTA_Lifeaz-home_Clicked', {});
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   * Open a new tab with an URL
   * @param url Url to navigate to
   */
  public goToLink(url: string): void {
    window.open(url, "_blank");
  }

  /**
   * Open friend popup
   */
  public openPopup(): void {
    this.isPopup = !this.isPopup;
  }
}
