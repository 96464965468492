import { Injectable } from '@angular/core';

/**
 * UtilsService
 * @description Utility methods that can be used everywhere in the platform
 * @author Pierre
 */
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
  ) { }

  /**
   * Gets a specific parameter from a url
   * @param qs URL string
   * @param param URL parameter to look for
   */
  public getUrlParam(qs: string, param: string): string {
    const reg = new RegExp( '[?&]' + param + '=([^&#]*)', 'i' );
    const value = reg.exec(qs);
    return (value) ? value[1] : null;
  }

  /**
   * Get image original dimension
   * @param url Image url
   */
  public getMeta(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = () => {
        reject('Error loading image');
      };
      img.src = url;
    });
  }

  /**
   * Returns a cookie value from its name
   * @param name Cookie name
   * @returns Cookie value
   */
  public readCookie(name: string): string {
    const nameEQ = encodeURIComponent(name) + "=";
    const ca = document.cookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ')
        c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  }
}
