import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { User } from 'src/app/providers/models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JWT } from 'src/app/providers/models/jwt.model';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { Circle } from 'src/app/providers/models/circle.model';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/providers/http/http.service';
import { LoginResponse } from 'src/app/providers/models/LoginResponse';
import { SceneFinished } from 'src/app/providers/models/sceneFinished.model';
import { CertificateInfos } from 'src/app/providers/models/certificate.model';

/**
 * UserService
 * @description Service to handle User Info
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /** User's Email */
  public userMail: string;
  /** User Invite */
  public userInvite = [false, ''];
  /** User Auth Token */
  public jwt = new JwtHelperService();
  /** User Token */
  public userToken: string;

  constructor(
    private http: HttpService,
    public authenticationService: AuthenticationService
  ) { }

  /**
   * Get user's circle
   */
  public getUserCircle(): Observable<ServerResponse<Array<Circle>>> {
    return this.http.get<ServerResponse<Array<Circle>>>(`/platform/circle/${this.userMail}`);
  }

  /**
   * Register user from invite link
   * @param email User's mail
   * @param password User's password
   * @param firstName User's password
   * @param friendLink URL friendLink
   */
  public registerInvite(email: string, password: string, firstName: string, friendLink: any): Observable<ServerResponse<LoginResponse>> {
    return this.http.post<ServerResponse<LoginResponse>>(`/platform/user/${email}/registerInvite`, { password, firstName, friendLink });
  }

  /**
   * Get user data to prepare form
   * @param email User's mail
   */
  public getUserData(email: string): Observable<ServerResponse<User>> {
    return this.http.get<ServerResponse<User>>(`/platform/user/${email}`);
  }

  /**
   * Update user info data
   * @param email User's mail
   * @param data Form data
   */
  public setUserData(email: string, data: User): Observable<ServerResponse<any>> {
    return this.http.put<ServerResponse<any>>(`/platform/user/${email}`, { data });
  }

  /**
   * Get user's mail from token
   */
  public getUserMail(): Promise<string> {
    return this.authenticationService.getToken()
    .then((token) => {
      const info = this.jwt.decodeToken(token) as JWT;
      return info !== null ? info.email : null;
    });
  }

  /**
   * Delete user mail
   * @param email User's mail
   */
  public deleteUser(email: string): Observable<ServerResponse<void>> {
    return this.http.delete<ServerResponse<void>>(`/platform/user/${email}`);
  }

  /**
   * Get user finished scene number
   * @param email User's mail
   */
  public getUserScene(email: string): Observable<ServerResponse<SceneFinished>> {
    return this.http.get<ServerResponse<SceneFinished>>(`/platform/user/${email}/scenes`);
  }

  /**
   * Unsubscribe user
   * @param hashMail User's hash mail
   */
  public unsubscribeUser(hashMail: string): Observable<ServerResponse<void>> {
    return this.http.get<ServerResponse<void>>(`/platform/user/${hashMail}/unsubscribe`);
  }


  /**
   *  Get paths
   * @param email User's mail
   */
  public getStats(email: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`/content/user/${email}/profile/stats`);
  }

  /**
   * Get path's success certificate
   * @param infos user's, circles's and path's infos
   * @param email User's mail
   */
  public getCertificate(infos: CertificateInfos, email: string): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`/content/user/${email}/certificate`, infos);
  }

  /**
   * Login with social media
   * @param email User's email
   * @param firstName User's firstname
   * @param id User's social media id
   * @param method method used (google or facebook)
   */
  public loginSocial(method: string, idToken: string, email?: string, firstName?: string): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`/platform/user/social`, {method, idToken, email, firstName});
  }
}
