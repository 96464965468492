import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { HistoryService } from '../history/history.service';

/**
 * AuthGuard
 * @description Guest to check if user is authentified
 */
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    public authService: AuthenticationService,
    public historyService: HistoryService
  ) { }

  /**
   *  Allow route access if logged in
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authService.getToken()
    .then((token) => {
      if (token)
        return Promise.resolve(true);
      this.historyService.setUrl(state.url);
      this.router.navigate([''])
      .catch(console.error);
      return Promise.resolve(false);
    })
    .catch(() => Promise.resolve(false));
  }
}
