import {
  trigger,
  animate,
  transition,
  style,
  query, group
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* <=> *', [
    query(':leave', [
      style({ opacity: 1, display: 'none' })],
          { optional: true }),
    group([
      query(':enter', [
        style({ opacity: 0, display: 'block' }),
        animate('800ms ease-in-out',
                style({
                  opacity: 1
                })
        )
      ], { optional: true })
    ])
  ])
]);
