import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClarkService } from 'src/app/modules/http/clark/clark.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
/** Contact from component */
@Component({
  selector: 'app-contact-csm',
  templateUrl: './contact-csm.component.html',
  styleUrls: ['./contact-csm.component.scss'],
  animations: [enterAnimation]

})
export class ContactCSMComponent {
  /** User's message */
  public message: string;
  /** Close CSM dialog */
  @Output()
  closeDialog: EventEmitter<boolean> = new EventEmitter();
  /** Btn status */
  public isSend = false;
  /** Error message to show */
  public errorMsg = '';
  /** Error status */
  public error = false;
  /** Aed serial number */
  @Input() public serial: string;
  constructor(
    private userService: UserService,
    private stats: StatsService,
    public aedService: ClarkService
  ) { }
  /**
   * Close dialog modal
   */
  onNoClick(): void {
    this.closeDialog.emit(false);
  }

  /** Send Message to CSM */
  sendMsg(): void {
    this.logEvent('[EH] ClarkStatus_CTA-ContactUs_Clicked');
    if (!this.message || this.message.length <= 0) {
      this.error = true;
      this.errorMsg = 'Merci de rédiger un message.';
    } else {
      this.userService.getUserMail().then((mail) => {
        this.aedService.postCSMMail(mail, this.message, this.serial).subscribe(
          (data) => {
            if (data.code === '00') {
              this.isSend = true;
              setTimeout(() => this.closeDialog.emit(false), 1000);
            }
          },
          (err) => {
            this.error = true;
            this.errorMsg = 'Une erreur est survenue, merci de réessayer ultérieurement.';
          }
        );
      })
      .catch(() => console.error);
    }
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

}
