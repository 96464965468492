import { Component, OnChanges, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { CustomFormService } from 'src/app/modules/http/custom-form/custom-form.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { Circle, CircleForm } from 'src/app/providers/models/circle.model';
import { HistoryService } from 'src/app/modules/history/history.service';
import { DatabaseService } from 'src/app/providers/database/database.service';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Components that handle circle form relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-form-circle',
  templateUrl: './form-circle.component.html',
  styleUrls: ['../form.component.scss']
})
export class FormCircleComponent implements OnChanges {

  /** Circle information */
  @Input() circle: Circle;
  /** User device */
  @Input() displayMobile: boolean;
  /** Form status */
  @Input() displayCircleForm: boolean;
  /** Circle form status emitter */
  @Output()
  isLoginCircleHide: EventEmitter<boolean> = new EventEmitter();
  /** Circle form submission status */
  public submitted = false;
  /** Circle form error status */
  public logError: boolean;
  /** Form content */
  public circleForm: NgForm;

  constructor(
    public formService: CustomFormService,
    public router: Router,
    public serviceLogin: LoginService,
    public serviceCircle: CirclesService,
    public userService: UserService,
    public loginService: LoginService,
    public circleService: CirclesService,
    private historyService: HistoryService,
    private storage: DatabaseService,
    private stats: StatsService
  ) { }

  /** Set error on circle form  */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.circle) {
      if (this.circle.form.length === 0) {
        this.userService.getUserMail()
        .then((email: string) => {
          if (email)
            this.addCircleUser(email); })
        .catch(() => console.error);
      }
      if (changes["circle"])
        this.circle.error = false;
    }
  }

  /**
   *  Get back to login form
   */
  backToLogin(): void {
    this.isLoginCircleHide.emit(false);
  }

  /**
   *  Check custom validator for each field
   * @param circleForm Form content
   */
  onSubmit(circleForm?: NgForm): void {
    this.userService.getUserMail()
    .then((email: string) => {
      if (!this.circle.forceInfo && !circleForm.value)
        this.addCircleUser(email);
      else {
        this.circleService.addCircle(email, this.circle.name, circleForm.value)
        .subscribe(() => {
          this.serviceLogin.isConnected(true);
          this.historyService.getUrl()
          .then((url) => {
            if (url) {
              if (url.match(/scene/i))
                this.router.navigateByUrl(url).catch(console.error);
              else
                this.router.navigateByUrl('home').catch(console.error);
            } else
              this.router.navigateByUrl('home').catch(console.error);
          }).catch(console.error);
        }, () => {
          this.circle.error = true;
        });
      }
    })
    .catch(console.error);
  }

  /**
   *  Go to EH without form validation
   */
  redirectEH(circleForm?: NgForm): void {
    if (!this.circle.forceInfo) {
      this.userService.getUserMail()
      .then((email) => {
        this.circleService.addCircle(email, this.circle.name, circleForm.value)
        .subscribe(() => {
          this.serviceLogin.isConnected(true);
          this.router.navigateByUrl('home')
          .catch(console.error);
        }, () => {
          this.circle.error = true;
        });
      })
      .catch(console.error);
    } else {
      this.serviceLogin.isConnected(true);
      this.router.navigateByUrl('home')
      .catch(console.error);
    }
  }

  /**
   * Add circle to User
   */
  public addCircleUser(email: string): void {
    this.circleService.addCircle(email, this.circle.name, {})
    .subscribe(() => {
      this.serviceLogin.isConnected(true);
      this.historyService.getUrl()
      .then((redirectUrl) => {
        if (redirectUrl) {
          this.storage.remove("previousUrl")
          .catch(console.error);
          this.router.navigate([redirectUrl])
          .catch(console.error);
        } else {
          this.router.navigateByUrl('home')
          .catch(console.error);
        }
      })
      .catch(console.error);
    }, () => {
      this.circle.error = true;
    });
  }
  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
