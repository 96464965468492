import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { smoothCollapse } from 'src/app/providers/animations/smoothCollapse.animation';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/modules/http/user/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { Circle } from 'src/app/providers/models/circle.model';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Component that handles add circles related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-add-circle',
  templateUrl: './add-circle.component.html',
  styleUrls: ['./add-circle.component.scss'],
  animations: [enterAnimation, smoothCollapse]
})
export class AddCircleComponent implements OnInit {

  /*** New circle status emitter */
  @Output()
  newCircle: EventEmitter<boolean> = new EventEmitter();
  /*** User's device */
  @Input() isMobile: boolean;
  /*** Component view status */
  @Input() isAddCircle: boolean;
  /*** User's circles array */
  public userCircles: Array<Circle>;
  /*** All circles array */
  public allCircles: Array<Circle>;
  /*** Searched circles array */
  public filteredCircles: Array<Circle>;
  /*** Circles view status */
  public isShowAllCircles = false;
  /*** Search circles mobile status */
  public showMobile = false;
  /*** Circle form status */
  public submit = false;
  /*** User's mail */
  public userMail: string;
  /*** JWT service */
  public jwt = new JwtHelperService();
  /*** Searched string */
  public search: string;

  constructor(
    public router: Router,
    public circleService: CirclesService,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private stats: StatsService
  ) { }

  /**
   * Get user's mail and resolvers data, set popup status
   */
  ngOnInit(): void {
    this.authenticationService.getToken()
    .then((token) => {
      this.userMail = this.jwt.decodeToken(token).email;
      this.userCircles = this.route.snapshot.data.circles[0];
      this.route.data.subscribe((data) => {
        this.userCircles = data.circles[0];
        for (const opt of this.userCircles)
          opt.popup = false;
        this.allCircles = data.circles[1];
        this.filteredCircles = data.circles[1].filter((circle: Circle) => circle.featured);
        for (const opt of this.filteredCircles) {
          opt.show = false;
          opt.added = false;
          opt.error = false;
        }
      });
    })
    .catch(console.error);
  }

  /**
   * Go back to profil page
   */
  public backToProfil(): void {
    this.newCircle.emit(false);
    this.router.navigateByUrl(`/profil`)
    .catch(console.error);
  }


  /**
   * Show custom form when adding a circle
   * @param iCircle Show form number
   * @param i loop index
   */
  public  showCustom(idCircle: number, i: number): void {
    if (!this.isMobile && this.filteredCircles[i].form.length > 0)
      this.filteredCircles[i].show = !this.filteredCircles[i].show;
    else if (this.isMobile && this.filteredCircles[i].form.length > 0) {
      this.showMobile = !this.showMobile;
      this.filteredCircles[i].show = !this.filteredCircles[i].show;
    } else {
      this.circleService.addCircle(this.userMail, this.filteredCircles[i].name, {}).subscribe(
        (data) => {
          this.circleAdded(idCircle);
        },
        console.error
      );
    }
  }

  /**
   * Show all available circles
   */
  public showAllCircles(): void {
    this.isShowAllCircles = true;
  }

  /**
   *  Add circle
   * @param circleForm Form content
   * @param circleName Circle name
   * @param idCircle Circle id
   * @param i index for circle in the ngFor
   * @param forceInfo Whether or not the custom form is mandatory for this circle
   */
  public onSubmit(circleForm: NgForm, circleName: string, idCircle: number, i: number, forceInfo: boolean): void {
    this.submit = true;
    if (Object.values(circleForm.value).every((x) => x)) {
      this.circleService.addCircle(this.userMail, circleName, circleForm.value).subscribe(
        (data) => {
          this.circleAdded(idCircle);
          this.showCustom(idCircle, i);
        },
        (err) => {
          this.filteredCircles[i].error = true;
        }
      );
    } else {
      if (!forceInfo) {
        this.circleService.addCircle(this.userMail, circleName, {}).subscribe(
          (data) => {
            this.circleAdded(idCircle);
            this.showCustom(idCircle, i);
          },
          (err) => {
            this.filteredCircles[i].error = true;
          }
        );
      } else
        this.filteredCircles[i].error = true;
    }
  }

  /**
   * Update circles list
   * @param type User's circle or all circles
   */
  private updateCirclesList(): void {
    this.circleService.getCircleUser(this.userMail).subscribe(
      (userCircles) => this.userCircles = userCircles.data
    );
    this.circleService.getAllCircles(this.userMail).subscribe(
      (allCircles) => {
        this.allCircles = allCircles.data;
        this.filteredCircles = allCircles.data.filter((circle: Circle) => circle.featured);
      }
    );
  }

  /**
   *  Add circle if forceInfo is false
   * @param circleForm Form content
   * @param circleName Circle name
   * @param idCircle Circle id
   * @param i index for circle in the ngFor
   * @param forceInfo Circle forceInfo
   */
  public addCircle(circleName: string, idCircle: number, i: number, forceInfo: boolean): void {
    if (!forceInfo) {
      this.circleService.addCircle(this.userMail, circleName, {}).subscribe(
        (data) => {
          this.circleAdded(idCircle);
          this.showCustom(idCircle, i);
        },
        (err) => {
          this.filteredCircles[i].error = true;
        }
      );
    } else
      this.showCustom(idCircle, i);
  }

  /**
   * Change added icon
   * @param idCircle Circle id
   */
  public circleAdded(idCircle: number): void {
    for (const param of this.filteredCircles) {
      if (param.idCircle === idCircle)
        param.added = true;
    }
    setTimeout(() => this.updateCirclesList(), 500);
  }
  /**
   * Go back to circle page
   */
  public backToCircle(): void {
    this.router.navigateByUrl(`/profil/circle`)
    .catch(console.error);
  }

  /**
   * Circle search method
   * @param param Key value
   */
  public searchCircle(param: string): void {
    if (param.length > 2) {
      this.filteredCircles = Object.assign([], this.allCircles).filter(
        (item) => item.name.toLowerCase().indexOf(param.toLowerCase()) > -1);
    } else
      this.filteredCircles = this.allCircles.filter((circle: Circle) => circle.featured);
  }

  /**
   * Open/close popup
   * @param i Index in loop
   */
  public openPopup(i: number): void {
    this.userCircles[i].popup = !this.userCircles[i].popup;
  }

  /**
   * Delete circle's user
   * @param circle Circle data
   * @param i Index in loop
   */
  public deleteCircle(circle: any, i: number): void {
    this.circleService.deleteUserCircle(this.userMail, circle.name).subscribe(
      (data) => {
        this.userCircles.splice(i, 1);
        setTimeout(() => this.updateCirclesList(), 200);
      },
      console.error
    );
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
