import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { switchMap } from 'rxjs/operators';

/**
 * TokenInterceptor
 * @description Add User token to Http Request
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthenticationService
  ) { }

  /**
   * Add token from header
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.getToken())
    .pipe(
      switchMap((token) => {
        const headers = request.headers
        .set('Authorization', token);
        const requestClone = request.clone({
          headers
        });
        return next.handle(requestClone);
      })
    );
  }

}
