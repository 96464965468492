import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

/**
 * DatabaseService
 * @description Service to Access Local Database
 * @notes All Object are saved to an array
 * @author ImOverlord
 */
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  /** Client Storage System */
  private storage: Storage = window.localStorage;

  constructor(
  ) { }

  /**
   * get
   * @description Method to get all data in a key
   * @param key where to retreive data
   */
  public get(key: string): Promise<string | null> {
    return new Promise((resolve) => {
      const value = this.storage.getItem(key);
      resolve(value);
    });
  }

  /**
   * set
   * @description Set value to a specific key
   * @param key where to put value
   * @param value to put in key
   */
  public set(key: string, value: object | string): Promise<any> {
    return new Promise((resolve) => {
      let parsedValue: string;
      if (typeof value !== "string")
        parsedValue = JSON.stringify(value);
      else
        parsedValue = value;
      this.storage.setItem(key, parsedValue);
      resolve();
    });
  }

  /**
   * remove
   * @description Remove value on key
   * @param key where to remove
   */
  public remove(key: string): Promise<any> {
    return new Promise((resolve) => {
      window.localStorage.removeItem(key);
      resolve();
    });
  }

  /**
   * clear
   * @description Clear whole database
   */
  public clear(): Promise<any> {
    return new Promise((resolve) => {
      window.localStorage.clear();
      resolve();
    });
  }

  /**
   * insert
   * @description Save value to database
   * @param key where to insert
   * @param data to insert
   */
  public insert<T extends object = any>(key: string, data: T | Array<T>): Promise<any> {
    return new Promise((resolve, reject) => {
      const results = this.storage.getItem(key);
      let parsedResult: Array<object>;
      if (results === null)
        parsedResult = [];
      else
        parsedResult = JSON.parse(results);
      if (Array.isArray(data))
        parsedResult.push(...data);
      else
        parsedResult.push(data);
      this.storage.setItem(key, results);
      resolve();
    });
  }

  /**
   * select
   * @description Select Elements in a list
   * @param key where to find
   * @param filter
   */
  public select<T extends object = any>(key: string, filter: object = {}): Observable<T> {
    return new Observable((subscriber) => {
      const results = this.storage.getItem(key);
      const parsedResult = JSON.parse(results);
      this.matcher<T>(parsedResult, filter, subscriber);
    });
  }

  /**
   * matcher
   * @description Return all elements that match the filter in an array
   * @param list of elements
   * @param filter to check for match
   * @param subscriber For RxJS subscribe
   */
  private matcher<T extends object = any>(list: Array<T>, filter: Record<string, any>, subscriber: Subscriber<T>): void {
    if (list === null)
      return subscriber.complete();
    for (const item of list) {
      let match = false;
      if (Object.keys(filter).length === 0)
        match = true;
      for (const key of Object.keys(filter)) {
        match = false;
        if (item.hasOwnProperty(key) && item[key] === filter[key])
          match = true;
        else
          break;
      }
      if (match)
        subscriber.next(item);
    }
    subscriber.complete();
  }
}
