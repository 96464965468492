import { Component, OnInit, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingComponent } from './loading/loading.component';
import { SceneViewComponent } from './scene-view/scene-view.component';
import { SceneUiComponent } from './scene-ui/scene-ui.component';
import { SceneService } from 'src/app/modules/http/scene/scene.service';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Step } from 'src/app/providers/models/step.model';
import { Scene } from 'src/app/providers/models/scene.model';
import { LightStep } from 'src/app/providers/models/lightStep.model';
import { LightPath } from 'src/app/providers/models/lightPath.model';
import { SceneTransition } from 'src/app/providers/models/sceneTransition.model';
import { UtilsService } from 'src/app/providers/utils/utils.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { BehaviorSubject } from 'rxjs';

/**
 * Components that handles scene display and gameplay
 */
@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.scss', './scene-ui/scene-popup.scss'],
  animations: [enterAnimation],
  encapsulation: ViewEncapsulation.None
})
export class SceneComponent implements OnInit {

  constructor(
    private sceneService: SceneService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private statusService: StatusService,
    private historyService: HistoryService,
    private utils: UtilsService,
    private stats: StatsService,
    private userService: UserService
  ) {
    this.loading = new LoadingComponent(this.statusService);
    this.router.routeReuseStrategy.shouldReuseRoute = function (): boolean {
      return false;
    };
  }

  /** Child component that handles scene loading */
  @ViewChild(LoadingComponent, { static: false })
  public loading: LoadingComponent;

  /** Child component that handles scene view */
  @ViewChild(SceneViewComponent, { static: false })
  public sceneView: SceneViewComponent;

  /** Child component that handles scene ui */
  @ViewChild(SceneUiComponent, { static: false })
  public sceneUi: SceneUiComponent;

  /** jwt service */
  public jwt = new JwtHelperService();
  /** Scene data */
  public scene: Scene;
  /** Scene score */
  public score = 0;
  /** Scene steps formatted with the essential data */
  public lightSteps: Array<LightStep> = [];
  /** Current step id */
  public currentStep = 0;
  /** Whether or not the scene summary should be displayed */
  public displaySummary = false;
  /** Whether or not the scene summary should be displayed */
  public displayScore = false;
  /** Whether or not the loading screen should be displayed */
  public displayLoading = true;
  /** Viewport width */
  public innerWidth: number;
  /** Whether or not the user in on a mobile device (tablet or phone) */
  public isMobile = false;
  /** Whether or not the current step is the last one */
  public isLastStep = false;
  /** Amount of user mistakes in the scene */
  public mistakes = 0;
  /** Amount of already loaded images in the scene */
  public loadedImgs = 0;
  /** Amount of images in the scene */
  public totalImgs = 0;
  /** Loading progress (%) */
  public loadingPercentage = 0;
  /** User email */
  public email = '';
  /** Previous navigation url */
  public previousUrl: string = null;
  /** Current path tag */
  public pathTag = '';
  /** Unlockedg badge page status */
  public badgeShowed = false;
  /** Current path information */
  public currentPath: LightPath;
  /** Unlocked theme page status */
  public unlockedTheme = false;
  /** Banner title status */
  public scoreTitle = false;
  /** User unlocked scene */
  public unlockedScene: [Scene, boolean?];
  /** Scene view status */
  public sceneSummary = false;
  /** Path id tag */
  public pathIdTag: number;
  /** Intro status */
  public isIntro = false;
  /** Scene replay status */
  public replay = false;
  /** Path end status */
  public pathEnd = false;
  /** Check scene status */
  public isPath = new BehaviorSubject<boolean>(false);
  /** Summary formated in a way that allows the display of a carousel in the summary */
  public summaryMobile = {
    beforeSteps: '',
    steps: [],
    afterSteps: ''
  };
  /** Data needed to display transition screens after the scene is done */
  public transitionData;
  /** Device type */
  public sceneParam: string;
  /** Slide config for slick carousel */
  slideConfig = {
    slidesToScroll: 1,
    nextArrow: ('.next'),
    prevArrow: ('.prev'),
    infinite: false,
    swipeToSlide: true
  };

  /**
   * Handles display variables on window resize
   * @param event Resize event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1200 || ((this.innerWidth / window.innerHeight) < (25 / 16)))
      this.isMobile = true;
    else
      this.isMobile = false;

  }

  /**
   * Gets data and initializes necessary variables for the component
   */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1200 || ((this.innerWidth / window.innerHeight) < (25 / 16))) {
      this.isMobile = true;
      this.sceneParam = 'mobile';
    } else {
      this.isMobile = false;
      this.sceneParam = 'desktop';
    }
    this.historyService.getUrl()
    .then((url) => {
      this.previousUrl = url;
      this.historyService.setUrl();
      return this.authenticationService.getToken();
    })
    .then((token) => {
      const url = decodeURIComponent(this.router.url);
      const sceneUrl = url.replace('/scene/', '');
      this.email = this.jwt.decodeToken(token).email;
      this.statusService.showHeaderFooter(false);
      this.statusService.showNavMobile(false);
      this.sceneService.getScene(this.email, sceneUrl, this.sceneParam).subscribe(
        (data) => {
          if (data.code === '10') {
            this.router.navigate(['scene/block'], {queryParams: {"code": data.code }})
            .catch(console.error);
          } else if (data.code === '11') {
            this.router.navigate(['scene/block'], {queryParams: {"code": data.code, "circleName": data.data.circleName, "token": data.data.token, "url": sceneUrl}})
            .catch(console.error);
          } else {
            this.scene = data.data;
            if (this.scene.type === 'default')
              this.isPath.next(true);
            this.lightSteps = this.formatLightSteps(this.scene.steps);
            const imgToLoad = this.getImgElementsToLoad(this.scene);
            const imgPopupToLoad = this.getImgPopupToLoad(this.scene);
            this.totalImgs = imgToLoad.length + imgPopupToLoad.length;
            const promiseArray = [];
            promiseArray.push(this.preloadImages(imgToLoad), this.preloadImagesPopup(imgPopupToLoad));
            Promise.all(promiseArray)
            .then((promiseResolved) => {
              const loadedImgs = promiseResolved[0];
              this.displayLoading = false;
              this.scene = this.bindPreloadedImgs(this.scene, loadedImgs);
              if ( data.data.popupIntro !== null || data.data.popupIntro === '')
                this.isIntro = true;
            })
            .catch(console.error);
            this.logEvent('[EH] All_Scene_Started', {idScene: data.data.idScene});
          }
        }
      );
    })
    .catch(console.error);
    this.statusService.pathTag.subscribe(
      (data) => {
        const urlTag = parseInt(this.utils.getUrlParam(window.location.href, "tag"));
        if (data === 0 && urlTag !== null && !isNaN(urlTag))
          this.pathIdTag = urlTag;
        else
          this.pathIdTag = data;
        if (this.router.url.includes('scene') && !(this.router.url.includes('tag')))
          history.replaceState({}, '', `${this.router.url}?tag=${this.pathIdTag}`);
      });
    this.isPath.subscribe((value) => {
      if (value && (!this.pathIdTag || this.pathIdTag === 0))
        this.router.navigateByUrl('home').catch(console.error);
    });
  }

  /**
   * Binds the preloaded image objects to the corresponding elements in the scene object
   * @param scene Scene object
   * @param loadedImgs Array of loaded images
   */
  public bindPreloadedImgs(scene: Scene, loadedImgs: Array<any>): any {
    scene.steps.forEach((step) => {
      step.elements.forEach((element) => {
        loadedImgs.forEach((img) => {
          if (img.id === element.idElement)
            element.preloadedImg = img.loadedImg;
        });
      });
    });
    return scene;
  }

  /**
   * Creates an array of steps with only the essential data
   * @param steps Raw steps array
   */
  public formatLightSteps(steps: Array<Step>): Array<LightStep> {
    const lightSteps = [];
    steps.forEach((step) => {
      lightSteps.push({
        question: step.question,
        type: step.type,
        offset: step.offset,
        summary: step.summary
      });
    });
    return lightSteps;
  }

  /**
   * Checks if it can switch to the next step, or if it should trigger the end-of-scene process
   */
  public goToNextStep(): void {
    if (this.currentStep + 1 === this.scene.steps.length) {
      this.displaySummary = true;
      if (this.isMobile)
        this.summaryMobile = this.parseMobileSummary(this.scene.summary);
      this.calculateScore();
    } else {
      this.sceneSummary = false;
      this.increaseStep();
    }
  }

  /**
   * Increases the current step in order to go to the next one
   */
  public increaseStep(): void {
    this.currentStep++;
    window.scroll(0, 0);
    if (this.currentStep + 1 === this.scene.steps.length)
      this.isLastStep = true;
  }

  /**
   * Saves the fact that the user made a mistake for this step
   */
  public saveMistake(): void {
    this.mistakes++;
  }

  /**
   * Saves user's score for this scene
   * @todo put real tag path after navigation path is done
   */
  public saveScore(): void {
    this.logEvent('[EH] All_Scene_Finished', {idScene: this.scene.idScene, score: this.score});
    if (this.scene.type === 'bonus') {
      const params: object = {
        score: this.score,
        tag: null
      };
      this.sceneService.updateScene(this.email, this.scene.url, params).toPromise()
      .then(() => {
        this.transitionData = {} as SceneTransition;
      })
      .then(() => {
        if (this.score >= 4) {
          this.userService.getUserScene(this.email).subscribe(
            (scene) => this.statusService.updateScore(scene.data[0])
          );
        }
      })
      .catch(console.error);
    } else {
      let params: object = {
        score: this.score,
        tag: this.pathIdTag
      };
      this.sceneService.updateScene(this.email, this.scene.url, params).subscribe(
        (res) => {
          if (this.score >= 4) {
            params = { tag: this.pathIdTag };
            this.sceneService.handleUnlocks(this.email, this.scene.url, params).toPromise()
            .then((data) => {
              this.transitionData = data;
              if (data.data.nextScene !== null)
                this.statusService.updatePathTag(data.data.nextScene.pathIdTag);
              else
                this.pathEnd = true;
            })
            .then(() =>
              this.userService.getUserScene(this.email).subscribe(
                (scene) => this.statusService.updateScore(scene.data[0])
              ))
            .catch(console.error);
          } else
            this.transitionData = {} as SceneTransition;
        }
      );
    }
  }

  /**
   * Calculates the score of the scene at the end of it
   */
  public calculateScore(): void {
    this.score = ((this.scene.steps.length - this.mistakes) / this.scene.steps.length) * 5;
    this.saveScore();
  }

  /**
   * Gets the list of images to load
   * @param scene Scene from which to extract the images
   */
  public getImgElementsToLoad(scene: Scene): Array<any> {
    const imgToLoad = [];
    imgToLoad.push({
      id: 0,
      src: scene.img
    });
    scene.steps.forEach((step) => {
      if (step.backgroundSrc) {
        imgToLoad.push({
          id: step.idStep * (-1),
          src: step.backgroundSrc
        });
      }
      step.elements.forEach((element) => {
        imgToLoad.push({
          id: element.idElement,
          src: element.img
        });
      });
    });
    return imgToLoad;
  }

  /**
   * Gets the list of images from popups to load
   * @param scene Scene from which to extract the images
   */
  public getImgPopupToLoad(scene: Scene): Array<any> {
    const imgToLoad = [];
    let imgSummary;
    imgSummary = this.getImgPopup(scene.summary);
    imgSummary.forEach((img) => {
      imgToLoad.push(img);
    });
    scene.steps.forEach((step) => {
      imgSummary = this.getImgPopup(step.summary);
      imgSummary.forEach((img) => {
        imgToLoad.push(img);
      });
    });
    return imgToLoad;
  }

  /**
   * Gets images links from an html string
   * @param text html string
   */
  public getImgPopup(text: string): Array<any> {
    const imgToLoad = [];
    const imgBracketRegExp = new RegExp('<img src="(.)*">', 'g');
    const imgBrackets = text.match(imgBracketRegExp);
    if (imgBrackets !== null) {
      for (const imgBracket of imgBrackets)
        imgToLoad.push(imgBracket.split('"')[1]);
    }
    return imgToLoad;
  }

  /**
   * Handles preloading of images for a scene
   * @param imgToLoad Array of images to load
   */
  public preloadImages(imgToLoad: Array<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const imgArray = [];
      const promiseArray = [];
      imgToLoad.forEach((img) => {
        imgArray.push({
          id: img.id,
          loadedImg: null
        });
        promiseArray.push(this.loadImage(img.src, true));
      });
      Promise.all(promiseArray)
      .then((loadedArray) => {
        for (let i = 0; i < loadedArray.length; i++)
          imgArray[i].loadedImg = loadedArray[i];
        resolve(imgArray);
      })
      .catch(() => {
        reject('Error when loading the images');
      });
    });
  }

  /**
   * Handles preloading of popup images for a scene
   * @param imgToLoad Array of images to load
   */
  public preloadImagesPopup(imgToLoad: Array<any>): Promise<void> {
    return new Promise((resolve, reject) => {
      const promiseArray = [];
      imgToLoad.forEach((img) => {
        promiseArray.push(this.loadImage(img, false));
      });
      Promise.all(promiseArray)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject('Error when loading the images');
      });
    });
  }

  /**
   * Loads an image on the DOM
   * @param img Image to load
   * @param isElement Whether or not it's a scene element or not
   */
  public loadImage(img: string, isElement: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const imgObj = new Image();
      imgObj.src = img;
      if (isElement) {
        imgObj.style.width = '100%';
        imgObj.style.height = 'auto';
      }
      imgObj.onload = () => {
        this.loadedImgs++;
        this.calculateLoading();
        resolve(imgObj);
      };
      imgObj.onerror = imgObj.onabort = () => {
        reject(img);
      };
    });
  }

  /**
   * Calculates the percentage of loading that is done
   */
  public calculateLoading(): void {
    this.loadingPercentage = Math.round(this.loadedImgs / this.totalImgs * 100);
  }

  /**
   * Goes back to last page
   */
  public backClicked(): void {
    this.statusService.showHeaderFooter(true);
    this.statusService.showNavMobile(true);
    if (this.previousUrl === null || this.previousUrl === 'undefined' || this.previousUrl.includes('scene/')) {
      this.router.navigateByUrl('/home')
      .catch(console.error);
    } else {
      this.router.navigateByUrl(this.previousUrl)
      .catch(console.error);
    }
  }

  /**
   * Parses scene summary to create a carousel for mobile devices
   * @param text scene summary html string
   */
  public parseMobileSummary(text: string): any {
    let beforeSteps = '';
    const steps = [];
    let afterSteps = '';
    if (text.indexOf('steps') === -1)
      beforeSteps = text;
    else {
      const splittedSteps = text.split('steps');
      beforeSteps = splittedSteps[0].substring(0, splittedSteps[0].lastIndexOf('<'));
      const stepsAndAfterSteps =
        splittedSteps[0].substring(splittedSteps[0].lastIndexOf('<'), splittedSteps[0].length)
        + 'steps' + splittedSteps[1];
      const div = document.createElement('div');
      div.innerHTML = stepsAndAfterSteps;
      const stepsEl = div.getElementsByClassName('steps')[0];
      const stepsList = stepsEl.children;
      // eslint-disable-next-line
      for (let i = 0; i < stepsList.length; i++) {
        steps.push(stepsList[i].outerHTML);
      }
      div.removeChild(stepsEl);
      afterSteps = div.outerHTML;
    }
    return {
      beforeSteps,
      steps,
      afterSteps
    };
  }

  /**
   * Handles transition screens
   */
  public transition(): void {
    this.scoreTitle = true;
    this.displaySummary = false;
    this.displayScore = true;
  }

  /**
   * Change banner when badge is unlocked
   * @param $event Next scene data, current path data to customize header or show Scene/theme page
   */
  public changeBanner($event: [LightPath, Scene?, boolean?]): void {
    if ($event[2]) {
      this.unlockedTheme = true;
      this.unlockedScene = [$event[1], true];
    }
    else if ($event[1]) {
      this.unlockedTheme = true;
      this.unlockedScene = [$event[1], false];
    } else {
      this.badgeShowed = true;
      this.currentPath = $event[0];
    }
  }

  /**
   * Change unlocked theme page status
   * @param $event Theme unlocking status
   */
  public showTheme($event: boolean): void {
    this.unlockedTheme = $event;
  }

  /**
   * Is Scene summary show
   * @param $event Scene summary status
   */
  public isSummary($event: boolean): void {
    this.sceneSummary = $event;
  }

  /**
   * Reloads the scene
   * @param $event Whether or not the scene should be reloaded
   */
  public reloadScene($event: boolean): void {
    if ($event) {
      this.score = 0;
      this.currentStep = 0;
      this.displayLoading = false;
      this.displayScore = false;
      this.displaySummary = false;
      this.isLastStep = false;
      this.mistakes = 0;
      this.sceneSummary = false;
      this.badgeShowed = false;
      this.unlockedTheme = false;
      this.scoreTitle = false;
      this.transitionData = {} as SceneTransition;
      this.replay = true;
    }
  }


  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
