import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { PathsService } from 'src/app/modules/http/paths/paths.service';

/**
 * Components that handle mobile navigation bar relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-nav-mobile',
  templateUrl: './nav-mobile.component.html',
  styleUrls: ['./nav-mobile.component.scss']
})
export class NavMobileComponent implements OnInit {

  /** Mobile navbar view status */
  @Input() isShow: string;
  /** Mobile navbar actual tab emitter */
  @Output() isTab = new EventEmitter<string>();
  /** Navigation dropdown emitter  */
  @Output() changePage = new EventEmitter<string>();
  /** Dropdwon view status */
  public isPlusShow = false;
  /** Control variable to avoid instantly closing the section */
  public isPlusRecentlyShown = false;
  /** Subcomponents view status */
  public noSub = true;
  constructor(
    public location: Location,
    public router: Router,
    public statusService: StatusService,
    public pathService: PathsService
  ) {
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.substring(0, 8) === '/profil/')
        this.noSub = false;
      else this.noSub = true;
    }
    );
  }

  /**
   * Get actual view to activate tab
   */
  ngOnInit(): void {
    this.isShow = (this.location.path().substr(1));
  }

  /**
   *  Change dropdown active page
   * @param $event Page to navigate
   */
  navigateTo($event: string): void {
    this.changePage.emit($event);
    if ($event === 'profil/intervention' || $event === 'profil/circle' || $event === 'profil/edition')
      this.noSub = false;
    else
      this.noSub = true;
  }

  /**
   * Change navbar active page
   * @param tab Page to navigate
   */
  tabNav(tab: string): void {
    this.isShow = tab;
    if (tab === 'show')
      this.isPlusShow = !this.isPlusShow;
    else
      this.isPlusShow = false;
    this.isTab.emit(tab);
    if (tab === 'path') {
      this.statusService.changeHeaderColor('#ffffff');
      this.pathService.showPaths(false);
    }
  }

  /**
   * Show plus menu on click and keep last active tab
   * @param param Page name
   */
  public showPlus(param?: string): void {
    if (param === 'show') {
      this.isPlusShow = !this.isPlusShow;
      if (this.isPlusShow) {
        this.isPlusRecentlyShown = true;
        setTimeout(() => {
          this.isPlusRecentlyShown = false;
        }, 250);
      }
    } else {
      if (this.isPlusShow)
        this.isPlusShow = false;
    }
  }

  /**
   * Change dropdown view status
   * @param $event Dropdown view status
   */
  clickOutside($event: boolean): void {
    this.isPlusShow = $event;
  }

}
