import { Component, EventEmitter, OnInit, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { HomeList } from 'src/app/providers/models/homeList.model';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Components that handle home mosaic relative methods
 */
@Component({
  selector: 'app-home-mosaic',
  templateUrl: './home-mosaic.component.html',
  styleUrls: ['./home-mosaic.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('300ms', style({ opacity: 0 }))
        ])
      ]
    )
  ]
})
export class HomeMosaicComponent implements OnInit {

  constructor(
    public router: Router,
    private statusService: StatusService,
    private stats: StatsService
  ) { }
  /** Carousel configuration */
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: ('.next'),
    prevArrow: ('.prev'),
    infinite: false,
    swipeToSlide: true
  };
  /** Viewport width */
  public innerWidth: number;
  /** Formatted rows to display the scene mosaic */
  @Input() rows: Array<HomeList>;
  /** Formatted content for mobile display */
  @Input() contentMobile: Array<HomeList>;
  /** User circle status */
  @Input() isCircle: boolean;
  /** User view choice */
  @Input() selectedParamDesktop: string;
  /** User's circle content status */
  @Input() circleNoContent: boolean;
  /** Circle view status emitter */
  @Output() emitShowCircle: EventEmitter<boolean> = new EventEmitter();
  /** Scene view status emitter */
  @Output() emitShowAll: EventEmitter<void> = new EventEmitter();
  /** Defaut toggle btn value */
  public toggleBtn: string;
  /** Circle tip status */
  public isCircleTip = false;
  /** Mobile circle display status */
  public isShowCircle = false;
  /** No content text value */
  public noContentText: string;
  /**
   * Set viewport width
   */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.isCircle && this.innerWidth < 765) {
      this.setShowCircle(true);
      this.toggleBtn = 'circle';
    } else
      this.toggleBtn = 'all';
    if (!this.circleNoContent)
      this.noContentText = 'Visualise le contenu lié à ta ville ou entreprise en ajoutant le cercle correspondant.';
    else
      this.noContentText = 'Ton cercle n\'a pas de scène à la une.';
  }
  /**
   * Check viewport width
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * Triggers setShowCircle() method in parent component
   * @param showCircle param to pass to parent component
   */
  public setShowCircle(showCircle: boolean): void {
    this.emitShowCircle.emit(showCircle);
    this.isShowCircle = showCircle;
  }

  /**
   * Triggers setShowAll() method in parent component
   */
  public setShowAll(): void {
    this.emitShowAll.emit();
  }

  /**
   * Scrolls to the mosaic section
   */
  public scrollToMosaic(): void {
    const element = document.getElementsByClassName('mosaic-content')[0];
    const offset = 228;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    if (!this.msieversion()) {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    } else
      window.scrollTo(0, offsetPosition);

  }

  /**
   * Navigates to a scene
   * @param scene Scene data
   */
  public goToScene(scene: HomeList): void {
    if (scene.type === 'path') {
      this.statusService.updatePathTag(scene.idTag);
      this.router.navigateByUrl(`scene/${scene.sceneUrl}`)
      .catch(console.error);
    } else {
      this.router.navigateByUrl(`scene/${scene.url}`)
      .catch(console.error);
    }
  }

  /**
   * Checks if user is using IE
   */
  msieversion(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
      return true;
    return false;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   * Show/hide circle tip on hover
   * @param isShow Hover event
   */
  public showCircleTip(isShow: boolean): void {
    this.isCircleTip = isShow;
  }
}
