export const dateList = [
  {
    name: 'Janvier',
    id: 1
  },
  {
    name: 'Février',
    id: 2
  },
  {
    name: 'Mars',
    id: 3
  },
  {
    name: 'Avril',
    id: 4
  },
  {
    name: 'Mai',
    id: 5
  },
  {
    name: 'Juin',
    id: 6
  },
  {
    name: 'Juillet',
    id: 7
  },
  {
    name: 'Août',
    id: 8
  },
  {
    name: 'Septembre',
    id: 9
  },
  {
    name: 'Octobre',
    id: 10
  },
  {
    name: 'Novembre',
    id: 11
  },
  {
    name: 'Décembre',
    id: 12
  }
];


export const dayList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];
