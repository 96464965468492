import { trigger, animate, transition, style, state } from '@angular/animations';

export const smoothCollapse = [
  trigger('smoothCollapse', [
    state('initial', style({
      height: '0',
      overflow: 'hidden',
      opacity: '0'
    })),
    state('final', style({
      overflow: 'hidden',
      opacity: '1'
    })),
    transition('initial=>final', animate('400ms')),
    transition('final=>initial', animate('400ms'))
  ])
];
