import { Component, OnInit, Output, EventEmitter, Input, ElementRef, AfterContentInit, SimpleChanges, ɵɵNgOnChangesFeature, OnChanges } from '@angular/core';
import { regex } from '../../models/emailValidator.model';
import { FriendService } from 'src/app/modules/http/friend/friend.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { slideInOut } from 'src/app/providers/animations/slideInOut.animation';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { slideDown } from '../../animations/slideDown.animation';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Components that handle friend popup element
 * @author jeremy65
 */
@Component({
  selector: 'app-friends-popup',
  templateUrl: './friends-popup.component.html',
  styleUrls: ['./friends-popup.component.scss'],
  animations: [slideInOut, enterAnimation, slideDown]
})
export class FriendsPopupComponent implements OnChanges, AfterContentInit {
  /** Popup status emitter */
  @Output()
  closePopup: EventEmitter<boolean> = new EventEmitter();
  /** Get popup status */
  @Input() isPopup: boolean;
  /** Mail fields status */
  public isMail = false;
  /** Mail number  */
  public mail = 1;
  /** Mails array */
  public sharedMails = [{ index: 0, mail: '' }];
  /** Mail error array */
  public errMails = [];
  /** Email regex */
  public regEmail = regex;
  /** Form status */
  public submitted = false;
  /** Status of sent emails */
  public mailSend = false;
  /** Link status */
  public copied = false;
  /** Social share container height */
  public topHeight = 462;
  /** Total popup height */
  public popupHeight: number;
  /** Bottom height container */
  public bottomHeight: number;
  /** Mail input status */
  public noMail = true;
  /** Share link value */
  public linkValue: string;
  /** JWT service */
  public jwt = new JwtHelperService();
  /** Text to share */
  public shareText = 'Viens te former aux gestes qui sauvent grâce à Everyday Heroes 🚀 : www.everydayheroes.fr. C’est simple, ludique et complètement gratuit pour que tout le monde ait le pouvoir de sauver des vies !';
  /** Mail error status */
  public showErrors = false;
  constructor(
    public friendService: FriendService,
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public statusService: StatusService,
    public loginService: LoginService,
    private stats: StatsService
  ) { }


  /**
   * Get user mail and generate share link
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isPopup.currentValue === true) {
      this.authenticationService.getToken()
      .then((token) => {
        this.userService.userMail = this.jwt.decodeToken(token).email;
        this.userService.getUserData(this.userService.userMail).subscribe(
          (data) => this.linkValue = `http://www.everydayheroes.fr/?friend=${data.data.friendLink}`
        );
      })
      .catch(console.error);
    }
  }

  /**
   * Set popup container height
   */
  ngAfterContentInit(): void {
    this.popupHeight = (window.innerHeight - 67);
    this.bottomHeight = this.popupHeight - this.topHeight;
  }

  /**
   * Close popup if click outside
   * @param $event Click outside values
   */
  closeFriendsPopup($event: any): void {
    if (this.isPopup && $event.pageX < 1025 && $event.pageX > 1060) {
      this.mailSend = false;
      this.sharedMails = [{ index: 0, mail: '' }];
      this.closePopup.emit(false);
      this.statusService.showFooter(true);
    }
  }

  /**
   *  Close popup on cross click
   */
  closeOnCross(): void {
    this.mailSend = false;
    this.sharedMails = [{ index: 0, mail: '' }];
    this.closePopup.emit(false);
    this.statusService.showFooter(true);
  }

  /**
   *  Add a new mail input, update mail array and container height
   */
  addMail(): void {
    if (this.mail < 10) {
      this.mail += 1;
      this.sharedMails.push({ index: this.mail, mail: '' });
      this.topHeight += (71 + Math.ceil(this.topHeight * 2 / 100));
    }
  }

  /**
   *  Check mail to activate button
   */
  checkInput(): boolean {
    for (const mail of this.sharedMails) {
      this.noMail = true;
      if (mail.mail.length > 0)
        return this.noMail = false;
    }
  }
  /**
   *  Check and send mails if valid
   */
  sendMail(): void {
    this.showErrors = false;
    if (!this.noMail) {
      this.submitted = true;
      this.errMails = [];
      for (let i = 0; i < this.sharedMails.length; i++) {
        if (this.sharedMails[i].mail === '')
          this.sharedMails.splice(i, 1);
        else
          this.errMails.push(this.regEmail.test(this.sharedMails[i].mail));
      }
      if (this.errMails.every(this.isFalse)) {
        const mailArr = this.sharedMails.map((x) => x.mail);
        this.logEvent('[EH] Invite_CTA-Mail-Send_Clicked', {numberOfFriendsInvited: mailArr.length});
        this.friendService.shareToFriends(this.userService.userMail, mailArr, '').subscribe(
          (data) => this.mailSend = true,
          (err) => console.error
        );
      } else
        this.showErrors = true;
    }
  }

  /**
   *  Check custom form validator
   * @param arr Custom form errors array
   */
  isFalse(arr: any): boolean {
    return arr === true;
  }

  /**
   *  Copy link to clipboard
   */
  copyToClipboard(): void {
    this.copied = true;
  }

  /**
   *  Use mobile share feature
   */
  shareOnMobile(): void {
    const newVariable = (window.navigator as any);
    if (newVariable.share) {
      newVariable.share({
        title: 'Everyday Heroes',
        text: this.shareText,
        url: 'everydayheroes.fr'
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else
      alert('share not supported');

  }

  /**
   *  Show/hide mail form
   * @param $event Show mail form boolean
   */
  showMailForm($event: boolean): void {
    this.isMail = $event;
    if ($event)
      this.topHeight = this.topHeight + 139 + this.mail * 71;
    else this.topHeight = 462;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
