import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { StatusService } from '../../../modules/singleton/status.service';
import { HistoryService } from '../../../modules/history/history.service';

/**
 * Component for the Terms of Use Page
 */
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  /** Store window size */
  public innerWidth;

  /** Store previous url for redirection */
  public previousUrl: string = null;

  /** Initial dropdown value */
  public selected = 'article-01';

  /** Array of dropdown options */
  public articleList = [
    { id: 'article-01', text: 'Objet' },
    { id: 'article-02', text: 'Exploitant de la Plateforme' },
    { id: 'article-03', text: 'Acceptation des Conditions Générales' },
    { id: 'article-04', text: 'Accès aux Services' },
    { id: 'article-05', text: 'Inscription sur la Plateforme' },
    { id: 'article-06', text: 'Usage strictement personnel' },
    { id: 'article-07', text: 'Usage non commercial' },
    { id: 'article-08', text: 'Durée' },
    { id: 'article-09', text: 'Informations préalables de l’Utilisateur' },
    { id: 'article-10', text: 'Description des Services' },
    { id: 'article-10-1', text: 'Mise à disposition d’une page de Profil' },
    { id: 'article-10-2', text: 'Accès à des formations en ligne pour apprendre à réagir en cas d’urgence' },
    { id: 'article-10-3', text: 'Accès à des modules interactifs pour apprendre les gestes qui sauvent' },
    { id: 'article-10-4', text: 'Newsletter' },
    { id: 'article-10-5', text: 'Assistance technique' },
    { id: 'article-10-6', text: 'Autres Services' },
    { id: 'article-11', text: 'Conditions financières' },
    { id: 'article-12', text: 'Hébergement' },
    { id: 'article-13', text: 'Garantie de niveau de services' },
    { id: 'article-14', text: 'Obligations de l’Utilisateur' },
    { id: 'article-15', text: 'Fermeture du Compte / arrêt des Services' },
    { id: 'article-16', text: 'Comportements prohibés' },
    { id: 'article-17', text: 'Sanction des manquements' },
    { id: 'article-18', text: 'Exclusion de responsabilité et de garantie de la Société' },
    { id: 'article-19', text: 'Propriété intellectuelle' },
    { id: 'article-20', text: 'Données à caractère personnel' },
    { id: 'article-21', text: 'Publicité' },
    { id: 'article-22', text: 'Références commerciales' },
    { id: 'article-23', text: 'Liens et sites tiers' },
    { id: 'article-24', text: 'Renonciation' },
    { id: 'article-25', text: 'Force majeure' },
    { id: 'article-26', text: 'Nullité' },
    { id: 'article-27', text: 'Modification des Conditions Générales' },
    { id: 'article-28', text: 'Entrée en vigueur' }
  ];

  /**
   * Component dependencies
   * @param router Angular Routing
   * @param statusService Custom Status Service for header handling
   * @param historyService handles navigation history stocked on the application
   */
  constructor(
    public router: Router,
    public statusService: StatusService,
    public historyService: HistoryService
  ) { }

  /**
   * On init handle initial window size to know if we are on mobile or not also handle live window resize
   */
  ngOnInit(): void {
    this.historyService.getUrl()
    .then((url) => {
      this.previousUrl = url;
      this.historyService.setUrl();
    })
    .catch(console.error);

    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.statusService.showHeaderNotConnected(false);
      this.statusService.showHeader(false);
      this.statusService.showNavMobile(false);
    } else {
      this.statusService.showHeaderNotConnected(true);
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
  }

  /**
   * handle window resize
   * @param event resize of window
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.statusService.showHeaderNotConnected(false);
      this.statusService.showHeader(false);
      this.statusService.showNavMobile(false);
    } else {
      this.statusService.showHeaderNotConnected(true);
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
  }

  /**
   * scroll
   * @description scrolls into defined anchor on the page.
   * @event scroll Into View.
   * @param id of element to scroll into
   */
  public scroll(elementId: string): void {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Navigate to previous page
   */
  public async backClicked(): Promise<void> {
    this.statusService.showHeaderFooter(true);

    if (this.innerWidth < 768) this.statusService.showNavMobile(true);

    if (this.previousUrl === null) await this.router.navigateByUrl('/home');

    await this.router.navigateByUrl(this.previousUrl);
  }
}
