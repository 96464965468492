import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { UserService } from 'src/app/modules/http/user/user.service';
import { Circle } from 'src/app/providers/models/circle.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';

/**
 * Component that handles circles related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-my-circle',
  templateUrl: './my-circle.component.html',
  styleUrls: ['./my-circle.component.scss'],
  animations: [enterAnimation]
})
export class MyCircleComponent implements OnInit {

  /*** User's circles */
  public userCircles: Array<Circle>;
  /*** Tip view status */
  public isTooltipShow = false;
  /*** User's mail */
  public userMail: string;
  /*** New added circle status emitter */
  @Output()
  newCircle: EventEmitter<boolean> = new EventEmitter();
  /*** User's device */
  @Input() isMobile: boolean;

  constructor(
    public circleService: CirclesService,
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    private stats: StatsService,
    private historyService: HistoryService
  ) { }

  /**
   * Get circle data from resolver and add popup element
   */
  ngOnInit(): void {
    this.route.data.subscribe(((param) => {
      this.userCircles = param.circles[0];
      for (const opt of this.userCircles)
        opt.popup = false;
    }
    ));
    this.userService.getUserMail()
    .then((email) => {
      this.userMail = email;
    })
    .catch(console.error);
  }

  /**
   * Go to add circle page
   */
  public addCircle(): void {
    this.newCircle.emit(true);
    this.historyService.getUrl()
    .then((url) => {
      this.logEvent('[EH] Circle_Page_Landed', {referrer: url});
    })
    .catch(console.error);
    this.router.navigateByUrl(`/profil/circle`)
    .catch(console.error);
  }

  /**
   * Open/close popup
   * @param i Index in loop
   */
  public openPopup(i: number): void {
    this.userCircles[i].popup = !this.userCircles[i].popup;
  }

  /**
   * Delete user's circle on mobile
   * @param circle Circle's data
   * @param i Index in loop
   */
  public deleteCircle(circle: any, i: number): void {
    this.circleService.deleteUserCircle(this.userMail, circle.name).subscribe(
      (data) => {
        this.userCircles.splice(i, 1);
      },
      console.error
    );
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   * Change tip view status
   */
  public openTip(): void {
    this.isTooltipShow = !this.isTooltipShow;
  }

  /**
   * Open tooltip on mouse enter if screen is a computer
   */
  public mouseEnterTooltip(): void {
    if (window.screen.width > 720)
      this.isTooltipShow = true;
  }
}
