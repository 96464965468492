import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { slideDown } from 'src/app/providers/animations/slideDown.animation';
import { PathCategorie } from 'src/app/providers/models/pathCategorie.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { SimpleChanges } from '@angular/core';
import { PathScene } from 'src/app/providers/models/pathScene.model';
import { Path } from 'src/app/providers/models/paths.model';

/**
 * Component that handles paths mosaic related methods on mobile
 * @author jeremy65
 */
@Component({
  selector: 'app-path-mosaic-mobile',
  templateUrl: './path-mosaic-mobile.component.html',
  styleUrls: ['./path-mosaic-mobile.component.scss'],
  animations: [enterAnimation, slideDown]
})
export class PathMosaicMobileComponent implements OnChanges {
  /** Current slide in view from parent */
  @Input() currentSlide: Path;
  /** Path id tag */
  @Input() pathIdTag: number;
  /** Clicked categorie */
  @Input() clickedCategorie: string;
  /** Clicked categorie */
  @Input() nextScene: PathScene;
  /** Categorie click status */
  @Input() showCategorie: boolean;
  /** Scene detail status emitter*/
  @Output()
  isShowBottom: EventEmitter<boolean> = new EventEmitter();
  /** Scene detail status */
  public isBottom = true;
  /** Scene locked modal status */
  public lockedModal = false;
  constructor(
    public router: Router,
    public statusService: StatusService,
    private stats: StatsService
  ) { }

  /** Get path change and clicked categorie */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showCategorie && this.clickedCategorie) {
      this.isBottom = false;
      this.scrollTo(this.clickedCategorie);
    } else if (changes.nextScene && (this.currentSlide.score / this.currentSlide.total !== 1)) {
      if (!this.currentSlide.categories[0].scenes[0].locked)
        this.scrollTo(this.nextScene.idScene.toString());
    }
  }

  /** Scroll to next scene when path change*/
  public scrollTo(id: string): void {
    setTimeout(() => {
      this.isBottom = false;
      this.isShowBottom.emit(false);
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }); }
    , 800);
  }

  /**
   * On pan up & down, show/hide all container
   */
  public showBottom(type: string): void {
    if (type === 'up') {
      this.isBottom = true;
      this.isShowBottom.emit(this.isBottom);
    } else {
      this.isBottom = !this.isBottom;
      this.isShowBottom.emit(this.isBottom);
    }
  }

  /**
   * Save tag name as behaviour subject
   * @param tag Tag name
   * @param url Scene url
   */
  public saveTag(url: string): void {
    this.statusService.updatePathTag(this.pathIdTag);
    setTimeout(() => this.router.navigateByUrl(`scene/${url}`), 200);
  }

  /**
   * Open/close modal
   */
  public openModal(): void {
    this.lockedModal = !this.lockedModal;
  }

  /**Open summary page
   * @param url PDF url
   */
  public goToSummary(url: string): void {
    window.open(url, "_blank");
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
