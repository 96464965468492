import { Component, Output, Input, EventEmitter } from '@angular/core';

/**
 * Component that handles friends page related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-my-friends',
  templateUrl: './my-friends.component.html',
  styleUrls: ['./my-friends.component.scss']
})
export class MyFriendsComponent {

  /*** Friend popup status */
  @Output()
  friendStatus: EventEmitter<boolean> = new EventEmitter();
  /*** Tip view status */
  public isTooltipShow = false;
  /*** Number of shares converted */
  @Input() sharesConverted: number;

  /**
   * Open friend popup
   */
  public openPopup(): void {
    this.friendStatus.emit(true);
  }

  /**
   * Change tip view status
   */
  public openTip(): void {
    this.isTooltipShow = !this.isTooltipShow;
  }

  /**
   * Open tooltip on mouse enter if screen is a computer
   */
  public mouseEnterTooltip(): void {
    if (window.screen.width > 720)
      this.isTooltipShow = true;
  }
}

