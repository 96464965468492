import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';

/**
 * Component that handles interventions related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-my-interventions',
  templateUrl: './my-interventions.component.html',
  styleUrls: ['./my-interventions.component.scss']
})
export class MyInterventionsComponent {

  /*** Component view emitter */
  @Output()
  newDeclaration: EventEmitter<boolean> = new EventEmitter();
  /*** User device */
  @Input() isMobile: boolean;
  /*** Tip view status */
  public isTooltipShow = false;
  /*** Number of interventions declared */
  @Input() nbInterventions: number;

  constructor(
    public router: Router,
    private stats: StatsService,
    private historyService: HistoryService
  ) { }

  /**
   * Open declaration page
   */
  public declareIntervention(): void {
    this.newDeclaration.emit(true);
    this.historyService.getUrl()
    .then((url) => {
      this.logEvent('[EH] Intervention_Page_Landed', {referrer: url});
    })
    .catch(console.error);
    this.router.navigateByUrl(`/profil/intervention`)
    .catch(console.error);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   * Change tip view status
   */
  public openTip(): void {
    this.isTooltipShow = !this.isTooltipShow;
  }

  /**
   * Open tooltip on mouse enter if screen is a computer
   */
  public mouseEnterTooltip(): void {
    if (window.screen.width > 720)
      this.isTooltipShow = true;
  }
}
