import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { UserService } from '../http/user/user.service';

/**
 * User api resolver
 */
@Injectable()
export class GetUserResolver implements Resolve<any> {

  constructor(
    private readonly userService: UserService
  ) {}

  /**
     * Get user info
     */
  public resolve(): Promise<any> {
    return this.userService.getUserMail()
    .then((mail) =>
      this.userService.getUserData(mail).toPromise()
    );
  }
}
