import { Component, OnInit } from '@angular/core';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { HistoryService } from 'src/app/modules/history/history.service';

/**
 * Component linked to the "About" page
 */
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  /*** Viewport width */
  public innerWidth: number;
  /*** User device */
  public isMobile = false;

  constructor(
    private stats: StatsService,
    public statusService: StatusService,
    public historyService: HistoryService
  ) { }

  /**
   * Handles history and header display
   */
  ngOnInit(): void {
    this.historyService.setUrl();
    this.innerWidth = window.innerWidth;
    this.stats.logEvent('[EH] About_Page_Landed', {})
    .catch(console.error);
    if (this.innerWidth > 765)
      this.statusService.showHeaderFooter(true);
    else {
      this.isMobile = true;
      this.statusService.showNavMobile(true);
      this.statusService.showHeaderFooter(true);
    }
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
