import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomForm } from 'src/app/providers/models/customForm.model';

/**
 * CustomFormService
 * @description Form to get Circle's form
 */
@Injectable({
  providedIn: 'root'
})
export class CustomFormService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   *  Get cutom circle's form
   * @param idCircle Get circle ID
   */
  public getCustomForm(idCircle: number): any {
    return this.http.get<Array<CustomForm>>('../assets/data/customForm.json');
  }

}
