import { Component, Input } from '@angular/core';
import { IAedStatus } from 'src/app/providers/models/aedStatus.model';
/** Unconnected DAE component */
@Component({
  selector: 'app-unconnected',
  templateUrl: './unconnected.component.html',
  styleUrls: ['./unconnected.component.scss']
})
export class UnconnectedComponent {
  /** CSM Dialog status */
  public isCSM = false;
  /** Aed status data */
  @Input() public aedData: IAedStatus;
  constructor() { }

  /**
 * Open contact form
 */
  handleDialog($event: boolean): void {
    this.isCSM = $event;
  }

}
