import { Component, OnInit, Input } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { tipsScene } from 'src/assets/data/tips';
import { trigger, animate, transition, style } from '@angular/animations';

/**
 * Component handling scene loading
 */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger(
      'animateTips', [
        transition(':enter', [
          style({ opacity: 0, transform: 'translateX(100%)' }),
          animate('200ms', style({ opacity: 1, transform: 'translateX(0)' }))
        ]),
        transition(':leave', [
          style({ opacity: 1, transform: 'translateX(0)' }),
          animate('200ms', style({ opacity: 0, transform: 'translateX(-100%)' }))
        ])
      ]
    )
  ]
})
export class LoadingComponent implements OnInit {
  /** Current loading progression */
  @Input() loadingPercentage;
  /** Tip displayed to the user on loading page */
  public tip = '';

  constructor(
    public statusService: StatusService
  ) { }

  /**
   * Prepare display variables for the page
   */
  ngOnInit(): void {
    this.statusService.showHeaderFooter(false);
    this.statusService.showNavMobile(false);
    this.rotateTips(-1);
  }

  /**
   * Changes the displayed tip every few seconds
   * @param previousIndex previous tip index, to avoid displaying the same tip twice in a row
   */
  public rotateTips(previousIndex: number): void {
    if (this.loadingPercentage < 100) {
      let index = -1;
      index = Math.round(Math.random() * (tipsScene.length - 1));
      while (index === previousIndex)
        index = Math.round(Math.random() * (tipsScene.length - 1));
      this.tip = tipsScene[index];
      setTimeout(() => {
        this.tip = '';
        setTimeout(() => {
          this.rotateTips(index);
        }, 500);
      }, 5000);
    }
  }
}
