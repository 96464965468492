import { Injectable, HostListener } from '@angular/core';
import * as Amplitude from 'amplitude-js';
import { UserService } from '../http/user/user.service';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/providers/utils/utils.service';

/**
 * Service handling statistics (Amplitude SDK and DB saving)
 */
@Injectable({
  providedIn: 'root'
})
export class StatsService {

  /** Identify object, used to apply certain properties to the user */
  public identify = new Amplitude.Identify();
  /** Whether the user gave their consent to Amplitude Analytics */
  private consent = false;
  /** Viewport size */
  public innerWidth: number;
  constructor(
    private http: HttpService,
    private userService: UserService,
    private utils: UtilsService
  ) {
    this.updateConsent();
    this.identify.set('[EH-User]', 1);
    Amplitude.getInstance().init('1cee98540b6399dfe88ba5f678a2e862', null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true
    });
    Amplitude.getInstance().identify(this.identify);
  }

  /**
   *  Check screen size
   * @param event Screen size
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * Updates the consent variable for a correct use in the other methods
   */
  public updateConsent(): void {
    const consentCookie = this.utils.readCookie('customConsent');
    if (consentCookie)
      this.consent = JSON.parse(consentCookie).amplitude;
  }

  /**
   * logEvent()
   * @description Logs an event in Amplitude
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): Promise<void> {
    this.updateConsent();
    if (!this.consent)
      return Promise.resolve();
    let device = '';
    if (window.innerWidth > 1165)
      device = 'Desktop';
    else if (window.innerWidth < 765)
      device = 'Mobile';
    else
      device = 'Tablet';
    return new Promise<void>((resolve, reject) => {
      this.userService.getUserMail()
      .then((email) => {
        if (email)
          this.identifyUser(email);
        Amplitude.getInstance().logEvent(title, properties);
        if (email)
          this.registerEvent(title, properties, email).subscribe((data) => {}, (err) => {});
        else
          this.registerEvent(title, properties).subscribe((data) => {}, (err) => {});
        Amplitude.getInstance().setUserProperties({'[EH] UP_Device_Used': device});
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * Sets Amplitude userId to the user's email
   * @param email User email
   */
  public identifyUser(email: string): void {
    Amplitude.getInstance().setUserId(email);
  }

  /**
   * Sets user properties for Amplitude
   * @param properties Object containing user properties
   */
  public setUserProperty(properties: object): void {
    Amplitude.getInstance().setUserProperties(properties);
  }

  /**
   * Registers an event in the database
   * @param title Event title
   * @param properties Event properties
   * @param email User email (if exists)
   */
  public registerEvent(title: string, properties?: object, email?: string): Observable<ServerResponse<void>> {
    const body = {
      name: title,
      ...(email && {email}),
      ...(properties && {properties})
    };
    return this.http.post<ServerResponse<void>>(`/stats/event`, body);
  }
}
