import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SceneFinished } from 'src/app/providers/models/sceneFinished.model';

/**
 * Service handling platform status variables, for display and logic purposes
 */
@Injectable({
  providedIn: 'root'
})
export class StatusService {

  /** Whether or not the header is displayed */
  public isHeader = new BehaviorSubject<boolean>(true);
  /** Whether or not the header is displayed based on connection */
  public isHeaderNotConnected = new BehaviorSubject<boolean>(true);
  /** Whether or not the footer is displayed */
  public isFooter = new BehaviorSubject<boolean>(true);
  /** Whether or not the mobile nav is displayed */
  public isNavMobile = new BehaviorSubject<boolean>(true);
  /** Whether or not user device is mobile */
  public isMobile = new BehaviorSubject<boolean>(false);
  /** Whether or not a popup is displayed */
  public isPopup = new BehaviorSubject<boolean>(false);
  /** Header background color */
  public headerColor = new BehaviorSubject<string>('#ffffff');
  /** Current selected path tag */
  public pathTag = new BehaviorSubject<number>(0);
  /** User finished scene total */
  public userTotal = new BehaviorSubject<SceneFinished>({sceneTotal: 0, userScene: 0});
  /** Landing form status */
  public formStatus = new BehaviorSubject<string>('register');
  /**Lifeaz.co link status */
  public isLifeazURL = new BehaviorSubject<boolean>(true);
  constructor() { }

  /**
   * Update not connected header status
   * @param bool Show header not connected
   */
  showHeaderNotConnected(bool: boolean): void {
    this.isHeaderNotConnected.next(bool);
  }

  /**
   * Update header status
   * @param bool Show header
   */
  public showHeader(bool: boolean): void {
    this.isHeader.next(bool);
  }

  /**
   * Update footer status
   * @param bool Show footer
   */
  public showFooter(bool: boolean): void {
    this.isFooter.next(bool);
  }

  /**
   * Update both header and footer
   * @param bool footer and header status
   */
  public showHeaderFooter(bool: boolean): void {
    this.isFooter.next(bool);
    this.isHeader.next(bool);
  }

  /**
   * Updates mobile nav menu
   * @param bool mobile nav status
   */
  public showNavMobile(bool: boolean): void {
    this.isNavMobile.next(bool);
  }

  /**
   * Check device status
   * @param bool Device status
   */
  public mobileDevice(bool: boolean): void {
    this.isMobile.next(bool);
  }

  /**
   * Change friend popup status
   * @param bool Friend popup status
   */
  public showPopup(bool: boolean): void {
    this.isPopup.next(bool);
  }

  /**
   * Change header color on mobile path page
   * @param col Secondary path color
   */
  public changeHeaderColor(col: string): void {
    this.headerColor.next(col);
  }

  /**
   * Get current scene path tag
   * @param tag Tag name
   */
  public updatePathTag(tag: number): void {
    this.pathTag.next(tag);
  }

  /**
   * Update user scene finished total
   * @param total Scene finished and total scene number
   */
  public updateScore(total: SceneFinished): void {
    this.userTotal.next(total);
  }

  /**
   * Update landing form view
   * @param type Login type
   */
  public updateForm(type: string): void {
    this.formStatus.next(type);
  }

  /**
   * Update Lifeaz.co URL visibility
   * @param param URL visibility status
   */
  public updateLifeazURL(param: boolean): void {
    this.isLifeazURL.next(param);
  }

}
