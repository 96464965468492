import { Injectable } from '@angular/core';
import { Circle, CircleUser } from 'src/app/providers/models/circle.model';
import { Subject, Observable } from 'rxjs';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';

/**
 * CirclesService
 * @description Service to handle Circles
 */
@Injectable({
  providedIn: 'root'
})
export class CirclesService {

  /** Circle Subject */
  public showCircle = new Subject<Circle>();

  constructor(
    private http: HttpService
  ) { }

  /**
   * Share circleInfo to components
   * @param circleInfo Circle data
   */
  public updateCircle(circleInfo: Circle): void {
    this.showCircle.next(circleInfo);
  }

  /**
   *  Get circle info
   * @param token Circle token from URL
   */
  public getCircleInfo(token: string): Observable<ServerResponse<Circle>> {
    return this.http.get<ServerResponse<Circle>>(`/platform/circle/token/${token}`);
  }

  /**
   * Get user circle info
   * @param email User's mail
   * @param name Circle's name
   */
  public getCircleUserInfo(email: string, name: string): Observable<ServerResponse<Array<CircleUser>>> {
    return this.http.get<ServerResponse<Array<CircleUser>>>(`/platform/user/${email}/circle/${name}`);
  }

  /**
   * Add circle to user's info
   * @param email User's mail
   * @param name Circle's name
   * @param customEntry Custom form data
   */
  public addCircle(email: string, name: string, formAnswers?: object): Observable<ServerResponse<void>> {
    const circleName = encodeURI(name);
    return this.http.post<ServerResponse<void>>(`/platform/user/${email}/circle/${circleName}`, { formAnswers });
  }

  /**
   * Get user's circles
   * @param email User's mail
   */
  public getCircleUser(email: string): Observable<ServerResponse<Array<Circle>>> {
    return this.http.get<ServerResponse<Array<Circle>>>(`/platform/user/${email}/circle`);
  }

  /**
   * Get user's unlink circles
   * @param email User's mail
   */
  public getAllCircles(email: string): Observable<ServerResponse<Array<Circle>>> {
    return this.http.get<ServerResponse<Array<Circle>>>(`/platform/circle/${email}`);
  }

  /**
   * Delete user's circle
   * @param email User's mail
   * @param name Circle's name
   */
  public deleteUserCircle(email: string, name: string): Observable<ServerResponse<void>> {
    const circleName = encodeURI(name);
    return this.http.delete<ServerResponse<void>>(`/platform/user/${email}/circle/${circleName}`);
  }
}
