import { Component, OnInit, HostListener, Input, Output, EventEmitter, Inject } from '@angular/core';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { DOCUMENT } from '@angular/common';
import { Tags } from 'src/app/providers/models/tags.models';
import { ActivatedRoute } from '@angular/router';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Component that handles bonus-searchbar related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-bonus-searchbar',
  templateUrl: './bonus-searchbar.component.html',
  styleUrls: ['./bonus-searchbar.component.scss'],
  animations: [enterAnimation]
})
export class BonusSearchbarComponent implements OnInit {
  /*** Search button status */
  public isSearchBtn = false;
  /*** Searched string */
  public search: string;
  /*** Searched tag status */
  public isTag = false;
  /*** Sticky searchbar status */
  public isSticky = false;
  /*** User device */
  public isMobile = false;
  /*** Searched tags array */
  public tagList = [];
  /*** Tags array */
  public allTags: Array<Tags>;
  /*** Searched string */
  public searchedStrings = '';
  /*** Searched strings and tags */
  public stringTags = [];
  /*** Viewport width */
  @Input() innerWidth: number;
  /*** Searched tag emitter */
  @Output()
  searchedBonus: EventEmitter<Array<any>> = new EventEmitter();
  /*** Tag list status */
  @Output()
  isShowTag: EventEmitter<boolean> = new EventEmitter();
  /*** Scroll listener to change sticky searchbar status */
  @HostListener('window:scroll')
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (this.innerWidth > 1191) {
      if (windowScroll > 400)
        this.isSticky = true;
      else
        this.isSticky = false;
    } else {
      if (windowScroll > 300)
        this.isSticky = true;
      else
        this.isSticky = false;
    }
  }

  constructor(
    public statusService: StatusService,
    @Inject(DOCUMENT) private document: Document,
    public route: ActivatedRoute,
    private stats: StatsService
  ) { }

  /**
   * Get user device and tags list
   */
  ngOnInit(): void {
    if (this.innerWidth < 765)
      this.isMobile = true;
    this.route.data.subscribe(
      (res) => {
        this.allTags = res.bonusTag.data
        .sort((a, b) => a.title.localeCompare(b.title));
      }
    );
  }

  /**
   * Show search button
   */
  public searchBonus(): void {
    if (this.search === '') {
      this.searchedStrings = '';
      this.isSearchBtn = false;
      this.stringTags = [];
      this.searchedBonus.emit([this.tagList, []]);
    } else
      this.isSearchBtn = true;
  }

  /**
   * Show "cloud tag"
   */
  public showTag(): void {
    this.isTag = !this.isTag;
    if (this.isMobile && this.isTag) {
      setTimeout(() => this.document.body.classList.add('is-popup'), 200);
      this.statusService.showHeaderFooter(false);
      this.statusService.showNavMobile(false);
      this.isShowTag.emit(true);
    } else if (this.isMobile && !this.isTag) {
      this.document.body.classList.remove('is-popup');
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
      this.isShowTag.emit(false);
    }
  }

  /**
   * Decompose string and emit tags and string value
   * @param param Searched bonus
   */
  public bonusSearch(param: string): void {
    this.tagList = [];
    this.searchedStrings = param;
    const words = param.toLowerCase().split(' ');
    this.stringTags = [];
    for (const word of words) {
      this.allTags.forEach((el) => {
        if ((el.keywords.findIndex((element) => element.includes(word)) !== -1 && this.stringTags.indexOf(word) === -1)
        || el.title.includes(word))
          this.stringTags.push(el.keywords[0]);
      });
    }
    this.searchedBonus.emit([this.tagList, this.searchedStrings, this.stringTags]);
  }

  /**
   * Search by tags
   * @param $event Clicked tags
   */
  public tagSearch($event: string): void {
    this.search = '';
    this.searchedStrings = '';
    this.stringTags = [];
    const index = this.tagList.some((value) => $event.includes(value));
    if (!index)
      this.tagList.push($event);
    else
      this.tagList.splice(this.tagList.findIndex((item) => item === $event), 1);
    this.searchedBonus.emit([this.tagList, this.searchedStrings, this.stringTags]);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
