import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { SceneElement } from 'src/app/providers/models/element.model';
import { LightStep } from 'src/app/providers/models/lightStep.model';
import { UtilsService } from 'src/app/providers/utils/utils.service';

/**
 * Component handling scene view (background, characters, etc.)
 */
@Component({
  selector: 'app-scene-view',
  templateUrl: './scene-view.component.html',
  styleUrls: ['./scene-view.component.scss']
})
export class SceneViewComponent implements OnChanges {

  /** List of elements for this step */
  @Input() elementList: Array<SceneElement>;
  /** Current step object */
  @Input() step: LightStep;
  /** Scene replay status */
  @Input() replay: boolean;

  constructor(
    private utils: UtilsService
  ) { }

  /**
   * Updates the view with the current step and calculates elements position
   * @param changes Step changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.elementList && changes.step && !this.replay)
      this.calculateElementCoordinates();
    if (changes.elementList) {
      setTimeout(() => {
        this.bindElementImages();
      }, 100);
    }
  }

  /**
   * Calculates the coordinates of all elements inside the step
   */
  public calculateElementCoordinates(): void {
    const backgroundURL = document.getElementById('sceneView').style.backgroundImage.slice(5, -2);
    this.utils.getMeta(backgroundURL)
    .then((backgroundDimensions) => {
      const sceneViewElement = document.getElementById('sceneView');
      const finalRatio = sceneViewElement.offsetHeight / backgroundDimensions.height;
      const finalImageWidth = backgroundDimensions.width * finalRatio;
      const finalPosition = (sceneViewElement.offsetWidth - finalImageWidth) * (this.step.offset / 100);
      this.elementList.forEach((element) => {
        element.width = finalImageWidth * element.width / 100;
        element.left = (finalImageWidth * element.left / 100) + finalPosition;
      });
    })
    .catch((err) => console.error());
  }

  /**
   * Binds the images html objects to each element of the step
   */
  public bindElementImages(): void {
    this.elementList.forEach((element) => {
      document.getElementById(`${element.idElement}`).appendChild(element.preloadedImg);
    });
  }

}
