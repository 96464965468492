import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ProvidersModule } from '../providers/providers.modules';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaModule
} from 'ng-recaptcha';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { BonusTagsComponent } from './bonus/bonus-searchbar/bonus-tags/bonus-tags.component';
import { BonusTagsMobileComponent } from './bonus/bonus-searchbar/bonus-tags-mobile/bonus-tags-mobile.component';
import { PathComponent } from './path/path.component';
import { HeaderComponent } from './header/header.component';
import { ProfilComponent } from './profil/profil.component';
import { HomeComponent } from './home/home.component';
import { BonusComponent } from './bonus/bonus.component';
import { SignupSectionComponent } from './landing-page/signup-section/signup-section.component';
import { FormSignupComponent } from './landing-page/signup-section/form/form-signup/form-signup.component';
import { FormCircleComponent } from './landing-page/signup-section/form/form-circle/form-circle.component';
import { FormComponent } from './landing-page/signup-section/form/form-login/form.component';
import { HomeMosaicComponent } from './home/home-mosaic/home-mosaic.component';
import { HomeSearchThemesComponent } from './home/home-search-themes/home-search-themes.component';
import { SceneComponent } from './scene/scene.component';
import { SceneViewComponent } from './scene/scene-view/scene-view.component';
import { CreditsComponent } from './terms-of-service/credits/credits.component';
import { PrivacyPolicyComponent } from './terms-of-service/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './terms-of-service/cookie-policy/cookie-policy.component';
import { TermsOfUseComponent } from './terms-of-service/terms-of-use/terms-of-use.component';
import { LegalNoticeComponent } from './terms-of-service/legal-notice/legal-notice.component';
import { SceneUiComponent } from './scene/scene-ui/scene-ui.component';
import { LoadingComponent } from './scene/loading/loading.component';
import { BonusSearchbarComponent } from './bonus/bonus-searchbar/bonus-searchbar.component';
import { PasswordPopupComponent } from './landing-page/signup-section/password-popup/password-popup.component';
import { NewPasswordComponent } from './landing-page/new-password/new-password.component';
import { PathMosaicComponent } from './path/path-mosaic/path-mosaic.component';
import { ProgressCircleComponent } from './path/path-mosaic/progress-circle/progress-circle.component';
import { PathMobileComponent } from './path/path-mobile/path-mobile.component';
import { PathMosaicMobileComponent } from './path/path-mobile/path-mosaic-mobile/path-mosaic-mobile.component';
import { HeaderHideDirective } from '../providers/directive/header-hide.directive';
import { UnlockedViewComponent } from './scene/unlocked-view/unlocked-view.component';
import { UnlockedThemeSceneComponent } from './scene/unlocked-view/unlocked-theme-scene/unlocked-theme-scene.component';
import { UnlockedBadgeComponent } from './scene/unlocked-view/unlocked-badge/unlocked-badge.component';
import { FormRegisterComponent } from './landing-page/signup-section/form/form-register/form-register.component';
import { AboutComponent } from './about/about.component';
import { ContestRulesComponent } from './terms-of-service/contest-rules/contest-rules.component';
import { BadgeBannerComponent } from './scene/unlocked-view/badge-banner/badge-banner.component';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { ClarkComponent } from './clark/clark.component';
import { ClarkStatusComponent } from './clark/clark-status/clark-status.component';
import { UnconnectedComponent } from './clark/clark-status/unconnected/unconnected.component';
import { OutdatedComponent } from './clark/clark-status/outdated/outdated.component';
import { ContactCSMComponent } from './clark/contact-csm/contact-csm.component';

const globalSettings: RecaptchaSettings = { siteKey: '6LdfIbgZAAAAAH6-aN6ujexDIUdR9S1ZgpoornTm' };
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("642150100946-otkg1c3rqfn11j33h1c7ts9ov3iuqs7s.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1016407385485855")
  }
]);

// eslint-disable-next-line @typescript-eslint/tslint/config
export function provideConfig() {
  return config;
}
/**
 * Modules required to operate the pages components
 */
@NgModule({
  declarations: [
    LandingPageComponent,
    PathComponent,
    HeaderComponent,
    ProfilComponent,
    HomeComponent,
    HomeMosaicComponent,
    HomeSearchThemesComponent,
    BonusComponent,
    SceneComponent,
    SignupSectionComponent,
    FormSignupComponent,
    FormCircleComponent,
    FormComponent,
    FormRegisterComponent,
    SceneViewComponent,
    SceneUiComponent,
    LoadingComponent,
    BonusSearchbarComponent,
    BonusTagsComponent,
    BonusTagsMobileComponent,
    PasswordPopupComponent,
    PasswordPopupComponent,
    NewPasswordComponent,
    PathMosaicComponent,
    ProgressCircleComponent,
    PathMobileComponent,
    PathMosaicMobileComponent,
    CreditsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsOfUseComponent,
    LegalNoticeComponent,
    HeaderHideDirective,
    UnlockedViewComponent,
    UnlockedThemeSceneComponent,
    UnlockedBadgeComponent,
    AboutComponent,
    ContestRulesComponent,
    BadgeBannerComponent,
    ClarkComponent,
    ClarkStatusComponent,
    UnconnectedComponent,
    OutdatedComponent,
    ContactCSMComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ProvidersModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    SlickCarouselModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    RecaptchaModule.forRoot(),
    SocialLoginModule
  ],
  exports: [
    HeaderComponent
  ],
  entryComponents: [
    PasswordPopupComponent,
    NewPasswordComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ]
})
export class PagesModule { }
