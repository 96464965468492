import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { Circle } from 'src/app/providers/models/circle.model';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from 'src/app/modules/http/user/user.service';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { DatabaseService } from 'src/app/providers/database/database.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { AuthService } from "angularx-social-login";

/**
 * Components that handle landing page relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  /** Circle information */
  public circleInfo: Circle;
  /** User device */
  public isMobile: boolean;
  /** Circle status */
  public isCircleConnected: boolean;
  /*** Viewport width */
  public innerWidth: number;
  /** User circle list */
  public userCircles: Array<Circle>;
  /** JWT service */
  public jwt = new JwtHelperService();
  /** User connection status */
  private isConnected = false;
  /**
   * Check screen size
   * @param event Screen size
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    public myElement: ElementRef,
    public activatedRoute: ActivatedRoute,
    public circleService: CirclesService,
    public router: Router,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    public statusService: StatusService,
    public loginService: LoginService,
    private storage: DatabaseService,
    private stats: StatsService,
    private historyService: HistoryService,
    private oauth: AuthService
  ) {
  }

  /**
   *  Check screen size, URL, connection status and get circle data
   */
  ngOnInit(): void {

    this.innerWidth = window.innerWidth;
    this.statusService.showHeaderFooter(true);
    this.statusService.showHeaderNotConnected(true);

    this.handleAuth()
    .then((isConnected) => {
      this.activatedRoute.queryParams.subscribe(
        (data) => {
          if (data.circle) {
            this.statusService.updateLifeazURL(false);
            if (data.url) {
              this.historyService.setUrl(data.url);
              this.statusService.updatePathTag(data.tag);
            }
            this.circleService.getCircleInfo(data.circle).subscribe(
              (infos) => {
                infos.data.error = false;
                this.circleService.updateCircle(infos.data);
                this.circleInfo = infos.data;
                if (isConnected) {
                  this.loginService.circleConnected(true);
                  this.circleService.getCircleUserInfo(this.userService.userMail, infos.data.name).subscribe(
                    (param) => {
                      if (param.code === "00")
                        this.isConnected = true;
                      else
                        this.loginService.isConnected(false);
                      this.logEvent('[EH] Landing_Page_Landed', {idCircle: this.circleInfo.idCircle});
                    }
                  );
                } else
                  this.logEvent('[EH] Landing_Page_Landed', {idCircle: this.circleInfo.idCircle});
              });
          } else {
            if (isConnected) {
              this.isConnected = true;
              this.loginService.isConnected(true);
              this.router.navigateByUrl('home')
              .catch(console.error);
            } else if (data.friend) {
              this.userService.userInvite = [true, data.friend];
              this.logEvent('[EH] Landing_Page_Landed', {idCircle: 0});
            } else if (data.password) {
              this.storage.set('token', data.password)
              .then(() => this.router.navigateByUrl('password'))
              .catch(console.error);
            } else
              this.logEvent('[EH] Landing_Page_Landed', {idCircle: 0});
          }
        }
      );
    })
    .catch(console.error);
    if (this.innerWidth <= 765) {
      this.statusService.mobileDevice(true);
      this.isMobile = true;
    } else {
      this.statusService.mobileDevice(false);
      this.isMobile = false;
    }
  }

  /**
   * Handles user authentication
   */
  public handleAuth(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authenticationService.checkAuth()
      .then((status) => {
        if (status) {
          this.authenticationService.getToken()
          .then((token) => {
            this.userService.userMail = this.jwt.decodeToken(token).email;
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        } else
          resolve(false);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
