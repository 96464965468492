import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { BonusService } from 'src/app/modules/http/bonus/bonus.service';
import { ActivatedRoute, Router } from '@angular/router';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { slideDown } from 'src/app/providers/animations/slideDown.animation';
import { slideInOut } from 'src/app/providers/animations/slideInOut.animation';
import { Path } from 'src/app/providers/models/paths.model';
import { PathCategorie } from 'src/app/providers/models/pathCategorie.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { PathScene } from 'src/app/providers/models/pathScene.model';

/**
 * Component that handles mosaic path related methods
 */
@Component({
  selector: 'app-path-mosaic',
  templateUrl: './path-mosaic.component.html',
  styleUrls: ['./path-mosaic.component.scss'],
  animations: [enterAnimation, slideDown, slideInOut]
})
export class PathMosaicComponent implements OnInit, OnChanges {

  /*** Viewport width*/
  public innerWidth: number;
  /*** User device*/
  public isMobile = false;
  /** Scene availability status */
  public blockedScene = false;
  /** Get categories list from parent */
  @Input() showedCategories: Array<PathCategorie>;
  /** Path id tag */
  @Input() pathIdTag: number;
  /** Next scene to play */
  @Input() nextScene: PathScene;
  /** Actual categories */
  public categories: Array<PathCategorie>;
  constructor(
    public statusService: StatusService,
    public bonusService: BonusService,
    public route: ActivatedRoute,
    public router: Router,
    private stats: StatsService
  ) { }

  /**
   * Get viewport width to show header / footer
   */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 765)
      this.statusService.showHeaderFooter(true);
    else {
      this.isMobile = true;
      this.statusService.showNavMobile(true);
      this.statusService.showHeaderFooter(true);
    }
  }


  /**
   * Update scene view and scene data when current path is changed
   * @param changes Path change
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showedCategories.currentValue) {
      this.categories = changes.showedCategories.currentValue;
      this.categories.forEach((categorie) => {
        for (const opt of categorie.scenes) {
          opt.hearts = [];
          if (opt.score === null)
            return;
          let totalScore = opt.score;
          while (opt.hearts.length < 5) {
            if (totalScore >= 1) {
              opt.hearts.push('assets/icons/full_heart.svg');
              totalScore = totalScore - 1;
            } else if (totalScore >= 0.5) {
              opt.hearts.push('assets/icons/half_heart.svg');
              totalScore = totalScore - 0.5;
            } else if (totalScore < 0.5)
              opt.hearts.push('assets/icons/empty_heart.svg');
          }
        }
        categorie.isPromValid = 0;
        categorie.scenes.map((x) => {
          if (x.score >= 4)
            categorie.isPromValid += 1;
          x.blockedScene = false;
        });
      });
    }
    if (changes.pathIdTag)
      this.statusService.updatePathTag(this.pathIdTag);
  }

  /**
   * Save tag name as behaviour subject
   * @param url Scene URL
   */
  public saveTag(url: string): void {
    this.statusService.updatePathTag(this.pathIdTag);
    setTimeout(() => this.router.navigateByUrl(`scene/${url}`), 200);
  }

  /**
   * Change blocked popup status
   * @param id Scene id
   */
  public showPopup(id: number): void {
    this.showedCategories.forEach((categorie) => {
      for (const opt of categorie.scenes) {
        if (opt.idScene === id)
          opt.blockedScene = !opt.blockedScene;
      }
    });
  }

  /**Open summary page
   * @param url PDF url
   */
  public goToSummary(url: string): void {
    window.open(url, "_blank");
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
