import { Component, OnInit, HostListener } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { HomeScene } from 'src/app/providers/models/homeScene.model';
import { UserService } from 'src/app/modules/http/user/user.service';
import { User } from 'src/app/providers/models/user.model';
import { HomeList } from 'src/app/providers/models/homeList.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Tags } from 'src/app/providers/models/tags.models';
import { Scene } from 'src/app/providers/models/scene.model';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Components that handle home page relative methods
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public statusService: StatusService,
    public historyService: HistoryService,
    public userService: UserService,
    public route: ActivatedRoute,
    public router: Router,
    private stats: StatsService
  ) { }
  /** User information */
  public userInfo: User;
  /** Viewport width */
  public innerWidth: number;
  /** Home scene data */
  public contentList: [HomeList];
  /** Scene to highlight */
  public firstScene: HomeList;
  /** Formatted rows to display the scene mosaic */
  public rows = [];
  /** Formatted content for mobile display */
  public contentMobile = [];
  /** Formatted circle's content for mobile display */
  public contentMobileCircles = [];
  /** Formatted trining content for mobile display */
  public contentMobileTraining = [];
  /** Circle content display status */
  public showCircle = true;
  /** User view choice */
  public selectedParamDesktop = 'All';
  /** Tag list */
  public tagsList: [Tags];
  /** User circle status */
  public isCircle: boolean;
  /** Circle content status */
  public circleNoContent: boolean;
  /**
   * Set history URL, set score hearts by scene and sort content
   */
  ngOnInit(): void {
    this.historyService.getUrl()
    .then((url) => {
      this.logEvent('[EH] Home_Page_Landed', {referrer: url});
    })
    .catch(console.error);
    this.historyService.setUrl();
    this.innerWidth = window.innerWidth;
    this.route.data.subscribe(
      (data) => {
        this.circleNoContent = data.home.newData.hasCircleNoContent;
        this.contentList = data.home.newData.rawData.filter((value: {}) => Object.keys(value).length !== 0);
        this.userInfo = data.userInfo.data;
        this.tagsList = data.tags.data;
      }
    );
    this.contentList.forEach((scene) => {
      scene = this.setSceneHearts(scene);
    });
    let contentClone = JSON.parse(JSON.stringify(this.contentList));
    contentClone = this.filterContent(contentClone, true, true);
    this.firstScene = contentClone[0];
    contentClone.shift();
    this.rows = this.formatRows(contentClone);
    this.sortContentMobileCircles();
    this.sortContentMobileTraining();
    this.contentMobile = this.contentMobileTraining;
    this.statusService.showHeaderFooter(true);
    this.statusService.showNavMobile(true);
  }

  /**
   * Check viewport width
   * @param event Viewport size
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * Filters the content depending on what type of content should be displayed
   * @param contentList raw content list recovered from the back end
   * @param isCircle true if circle content should be displayed, false otherwise
   * @param isTraining true if training content should be displayed, false otherwise
   */
  public filterContent(contentList: any, isCircle: boolean, isTraining: boolean): Array<HomeScene> {
    if (isCircle && isTraining)
      return contentList;

    const updatedContent = [];
    contentList.forEach((content) => {
      if ((isCircle && content.circle.name)
        || (isTraining && !content.circle.name))
        updatedContent.push(content);

    });
    return updatedContent;
  }

  /**
   * Prepares hearts images for a scene depending on its score
   * @param scene Scene to modify
   */
  public setSceneHearts(scene: any): HomeList {
    if (scene.score === undefined || scene.score === null)
      return;
    let totalScore = scene.score;
    while (scene.hearts.length < 5) {
      if (totalScore >= 1) {
        scene.hearts.push('assets/icons/full_heart.svg');
        totalScore = totalScore - 1;
      } else if (totalScore >= 0.5) {
        scene.hearts.push('assets/icons/half_heart.svg');
        totalScore = totalScore - 0.5;
      } else if (totalScore < 0.5)
        scene.hearts.push('assets/icons/empty_heart.svg');
    }
    return scene;
  }

  /**
   * Formats the rows to display in the mosaic
   * @param contentList Scenes and paths which will be displayed
   */
  public formatRows(contentList: any): any {
    let rowIndex = 0;
    let tileIndex = 0;
    const rows = [[]];
    for (const content of contentList) {
      if (tileIndex >= 3) {
        tileIndex = 0;
        rowIndex++;
        rows[rowIndex] = [];
      }
      rows[rowIndex].push(content);
      if (content.type === 'path')
        tileIndex += 2;
      else if (content.type === 'scene')
        tileIndex += 1;

    }
    return rows;
  }

  /**
   * Sorts the content to separate it by circles and type for mobile display
   */
  public sortContentMobileCircles(): void {
    this.contentMobileCircles[0] = [];
    let index = 0;
    let currentCircle = '';
    let currentType = '';
    this.contentList.forEach((content) => {
      if (content.circle.name) {
        if (currentCircle === '') {
          currentCircle = content.circle.name;
          currentType = content.type;
          this.contentMobileCircles[index] = [];
          this.contentMobileCircles[index].push(content);
        } else if (currentCircle !== content.circle.name) {
          currentCircle = content.circle.name;
          currentType = content.type;
          index++;
          this.contentMobileCircles[index] = [];
          this.contentMobileCircles[index].push(content);
        } else {
          if (currentType !== content.type) {
            index++;
            this.contentMobileCircles[index] = [];
            this.contentMobileCircles[index].push(content);
          } else
            this.contentMobileCircles[index].push(content);
        }
      }
    });
    if (this.contentMobileCircles[0].length > 0)
      this.isCircle = true;
    else
      this.isCircle = false;
  }

  /**
   * Sorts the content by keeping only the training content for mobile display
   */
  public sortContentMobileTraining(): void {
    this.contentMobileTraining[0] = [];
    let index = 0;
    let currentType = '';
    this.contentList.forEach((content) => {
      if (!content.circle.name) {
        if (currentType === '') {
          currentType = content.type;
          this.contentMobileTraining[index] = [];
          this.contentMobileTraining[index].push(content);
        } else if (currentType !== content.type) {
          index++;
          currentType = content.type;
          this.contentMobileTraining[index] = [];
          this.contentMobileTraining[index].push(content);
        } else
          this.contentMobileTraining[index].push(content);
      }
    });
  }

  /**
   * Sets whether or not the circle content should be shown
   * @param showCircle true if circle content should be shown, false otherwise
   */
  public setShowCircle(showCircle: boolean): void {
    this.showCircle = showCircle;
    if (showCircle) {
      this.selectedParamDesktop = 'Circle';
      this.contentMobile = this.contentMobileCircles;
      let contentClone = JSON.parse(JSON.stringify(this.contentList));
      contentClone = this.filterContent(contentClone, true, false);
      this.firstScene = contentClone[0];
      contentClone.shift();
      this.rows = this.formatRows(contentClone);
    } else {
      this.selectedParamDesktop = 'Training';
      this.contentMobile = this.contentMobileTraining;
      let contentClone = JSON.parse(JSON.stringify(this.contentList));
      contentClone = this.filterContent(contentClone, false, true);
      this.firstScene = contentClone[0];
      contentClone.shift();
      this.rows = this.formatRows(contentClone);
    }
  }

  /**
   * Shows all the content on the home page
   */
  public setShowAll(): void {
    this.selectedParamDesktop = 'All';
    let contentClone = JSON.parse(JSON.stringify(this.contentList));
    contentClone = this.filterContent(contentClone, true, true);
    this.firstScene = contentClone[0];
    contentClone.shift();
    this.rows = this.formatRows(contentClone);
  }

  /**
   * Play selected scene
   * @param scene Scene data
   */
  public goToScene(scene: HomeList): void {
    if (scene.type === 'path') {
      this.statusService.updatePathTag(scene.idTag);
      this.router.navigateByUrl(`scene/${scene.sceneUrl}`)
      .catch(console.error);
    } else {
      this.router.navigateByUrl(`scene/${scene.url}`)
      .catch(console.error);
    }
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
