import { Component } from '@angular/core';
/** Oudated DAE component */
@Component({
  selector: 'app-outdated',
  templateUrl: './outdated.component.html',
  styleUrls: ['./outdated.component.scss']
})
export class OutdatedComponent {

  constructor(

  ) { }

  /**
 * Open a navigator page on tab
 * @param url url where the user will be redirected (connectivite.lifeaz.co)
 */
  public updateDae(url: string): void {
    window.open(url, "_blank");
  }
}
