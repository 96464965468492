/**Custom data for Clark status */
export const clarkStatus = {
  unlinkedClark: {
    title: 'unlinkedClark',
    intro: 'Comment va Clark aujourd\'hui ?',
    icon: '',
    btnPosition: 'column'
  },
  functionnalClark: {
    title: 'functionnalClark',
    intro: 'Clark se porte à merveille !',
    icon: '../assets/icons/clark/check.svg',
    btnPosition: 'column-reverse'
  },
  warningClark: {
    title: 'warningClark',
    intro: 'Clark nécessite votre attention',
    icon: '../assets/icons/clark/warning.svg',
    btnPosition: 'column'
  },
  outdatedClark: {
    title: 'outdatedClark',
    intro: 'Une mise à jour de clark est disponible',
    icon: '',
    btnPosition: 'column-reverse'
  },
  unconnectedClark: {
    title: 'unconnectedClark',
    intro: 'L’application n’a pas encore pu se connecter à Clark',
    icon: '',
    btnPosition: 'column-reverse'
  }
};
