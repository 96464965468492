import { Component, OnInit, HostListener, Input } from '@angular/core';
import { BonusService } from 'src/app/modules/http/bonus/bonus.service';
import { Router } from '@angular/router';
import { Tags } from 'src/app/providers/models/tags.models';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Components that handle home search themes relative methods
 */
@Component({
  selector: 'app-home-search-themes',
  templateUrl: './home-search-themes.component.html',
  styleUrls: ['./home-search-themes.component.scss']
})
export class HomeSearchThemesComponent implements OnInit {

  constructor(
    public bonusService: BonusService,
    public router: Router,
    private stats: StatsService
  ) { }
  /** Tags list from parent */
  @Input() tagsList: [Tags];
  /** Mocked tag list */
  public rawTagList = [
    {
      title: 'Incendie',
      img: 'assets/fire.png',
      overlayColor: 'rgba(0, 0, 155, 0.8)',
      overlayImg: '../../../assets/overview_scene.jpg',
      keywords: ['incendie', 'incendies', 'feu', 'flamme', 'chaleur']
    },
    {
      title: 'Été',
      img: 'assets/fire.png',
      overlayColor: 'rgba(146, 224, 163, 0.8)',
      overlayImg: '../../../assets/overview_scene.jpg',
      keywords: ['été', 'chaleur', 'juin', 'juillet', 'aout']
    },
    {
      title: 'Transports',
      img: 'assets/fire.png',
      overlayColor: 'rgba(112, 29, 47, 0.8)',
      overlayImg: '../../../assets/overview_scene.jpg',
      keywords: ['transport', 'transports', 'metro', 'métro', 'rer', 'voiture', 'moto', 'trottinette', 'scooter']
    },
    {
      title: 'Insectes',
      img: 'assets/fire.png',
      overlayColor: 'rgba(0, 181, 184, 0.8)',
      overlayImg: '../../../assets/overview_scene.jpg',
      keywords: ['insecte', 'insecte', 'abeille', 'abeilles', 'araignee', 'araignée']
    },
    {
      title: 'Chutes',
      img: 'assets/fire.png',
      overlayColor: 'rgba(255, 87, 126, 0.8)',
      overlayImg: '../../../assets/overview_scene.jpg',
      keywords: ['chutes', 'chute', 'escalier', 'escaliers', 'badaboum', 'patatras']
    }
  ];
  /** Viewport width */
  public innerWidth: number;
  /** Search input status */
  public showSearchInput = true;

  /**
   * Check user's device and get tag list
   */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 765)
      this.showSearchInput = false;
  }
  /** Check viewport width */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 765)
      this.showSearchInput = false;
    else
      this.showSearchInput = true;
  }

  /**
   * Toggles the display of search input for mobile devices
   */
  public toggleSearchInput(): void {
    if (this.innerWidth < 765) {
      this.showSearchInput = !this.showSearchInput;
      if (this.showSearchInput) {
        setTimeout(() => {
          document.getElementById('search').focus();
        }, 500);
      }
    }
  }

  /**
   * Go to bonus page pass tag data to route
   * @param param Tag keyword
   */
  public bonusTag(param: string): void {
    this.router.navigate(['/bonus'], { state: { tag: param } })
    .catch(console.error);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
