import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * SafeHtmlPipe
 * @description Pipe to Bypass Html Safty
 */
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  /**
   * transform
   * @description Method to bypass HTML Security
   * @param html to transform
   */
  public transform(html: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
