import { Component, OnInit } from '@angular/core';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { StatusService } from 'src/app/modules/singleton/status.service';

/** Components that handle footer related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**Lifeaz.co URL status */
  public showURL: boolean;
  constructor(
    private stats: StatsService,
    private historyService: HistoryService,
    public statusService: StatusService
  ) { }

  /** Show/hide Lifeaz.co URL */
  ngOnInit(): void {
    this.statusService.isLifeazURL.subscribe(
      (data) => this.showURL = data
    );
  }
  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   */
  public logEvent(title: string): void {
    this.historyService.getUrl()
    .then((url) => {
      this.stats.logEvent(title, {referrer: url})
      .catch(console.error);
    })
    .catch(console.error);
  }
}
