import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Component that handles the "locked scene" view
 */
@Component({
  selector: 'app-locked-view',
  templateUrl: './locked-view.component.html',
  styleUrls: ['./locked-view.component.scss']
})
export class LockedViewComponent implements OnInit {

  /** URL to be redirected to */
  public url: string = '/home';
  /** Title of the displayed section */
  public title: string = "Pas si vite !";
  /** Content of the displayed section */
  public text: string = "Vous n'avez pas encore accès à cette scène ! Terminez les scènes précédentes pour y avoir accès 😉";
  /** Displayed button text */
  public button: string = "Démarrer mon parcours";

  constructor(
    public statusService: StatusService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  /**
   * Handles header/footer and sets the displayed text
   */
  ngOnInit(): void {
    this.statusService.showNavMobile(true);
    this.statusService.showHeader(true);
    this.statusService.showFooter(false);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.code === "11") {
        this.title = "Oups, vous n'avez pas accès à cette scène";
        this.text = `Cette scène est exclusive au cercle ${params.circleName}. Pour y avoir accès, il vous suffit d'ajouter ce cercle 😉`;
        this.button = "Ajouter le cercle";
        this.url = `/?circle=${params.token}&url=scene/${params.url}`;
      }
    });
  }

  /**
   * Defines the url to which the button should redirect
   */
  public redirectUrl(): void {
    this.router.navigateByUrl(this.url)
    .catch(console.error);
  }

}
