import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { StatusService } from '../../../modules/singleton/status.service';
import { HistoryService } from '../../../modules/history/history.service';

/**
 * Component for the privacy policy page
 */
@Component({
  selector: 'app-contest-rules',
  templateUrl: './contest-rules.component.html',
  styleUrls: ['./contest-rules.component.scss']
})
export class ContestRulesComponent implements OnInit {
  /** Store window size */
  public innerWidth: number;

  /** Store previous url for redirection */
  public previousUrl: string = null;

  /** Initial dropdown value */
  public selected = 'article-01';

  /** Array of dropdown options */
  public articleList = [
    { id: 'article-01', text: 'Société organisatrice' },
    { id: 'article-02', text: 'Conditions de participation' },
    { id: 'article-03', text: 'Modalités de participation' },
    { id: 'article-03', text: 'Dotations' },
    { id: 'article-04', text: 'Information des gagnants et retrait des lots' },
    { id: 'article-06', text: 'Litiges et responsabilités' },
    { id: 'article-07', text: 'Utilisation des données - informatique et libertés' },
    { id: 'article-08', text: 'Dépot du règlement de jeu et communication' }
  ];

  /**
   * Component dependencies
   * @param router Angular Routing
   * @param statusService Custom Status Service for header handling
   * @param historyService handles navigation history stocked on the application
   */
  constructor(
    public router: Router,
    public statusService: StatusService,
    public historyService: HistoryService
  ) { }

  /**
   * On init handle initial window size to know if we are on mobile or not also handle live window resize
   */
  async ngOnInit(): Promise<void> {

    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.statusService.showHeaderNotConnected(false);
      this.statusService.showHeader(false);
      this.statusService.showNavMobile(false);
    } else {
      this.statusService.showHeaderNotConnected(true);
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
  }

  /**
   * handle window resize
   * @param event resize of window
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.statusService.showHeaderNotConnected(false);
      this.statusService.showHeader(false);
      this.statusService.showNavMobile(false);
    } else {
      this.statusService.showHeaderNotConnected(true);
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
  }

  /**
   * scroll
   * @description scrolls into defined anchor on the page.
   * @event scroll Into View.
   * @param id of element to scroll into
   */
  public scroll(elementId: string): void {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Navigate to previous page
   */
  public async backClicked(): Promise<void> {
    this.statusService.showHeaderFooter(true);
    if (this.innerWidth < 768) this.statusService.showNavMobile(true);
    await this.router.navigateByUrl('/home');
  }
}
