import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { IGetOptions, IHttpBaseOptions } from './http.interface';
import { Observable, race } from 'rxjs';

/**
 * HttpService
 * @description Wrapper for Angular HTTP Service
 * @author ImOverlord
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  /** Regex to check if string is a valid url */
  private urlRegEx = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  /** API Server Base Url */
  private baseUrl = environment.baseUrl;

  constructor(
    private webHTTP: HttpClient
  ) { }

  /**
   * get
   * @description HTTP GET
   * @param url to send GET Request
   * @param options to add to GET Request
   * @param timeout for GET Request being treating as error
   */
  public get<T>(url: string, options: IGetOptions = {}, timeout: number = 2000): Observable<T> {
    let fullUrl = url;
    if (!this.urlRegEx.test(url) && environment.production)
      fullUrl = `${this.baseUrl}${url}`;
    // eslint-disable-next-line no-underscore-dangle
    return race(this._get<T>(fullUrl, options), this.timeout(timeout));
  }

  /**
   * _get
   * @description HTTP GET without timeout
   * @param url to send GET Request
   * @param options to add to GET Request
   */
  private _get<T>(url: string, options: IGetOptions = {}): Observable<T> {
    return this.webHTTP.get(url, options) as Observable<T>;
  }

  /**
   * post
   * @description HTTP POST
   * @param url to send POST Request
   * @param options to add to POST Request
   * @param timeout for POST Request being treating as error
   */
  public post<T>(url: string, body: object = {}, options: IHttpBaseOptions = {}, timeout: number = 2000): Observable<T> {
    let fullUrl = url;
    if (!this.urlRegEx.test(url) && environment.production)
      fullUrl = `${this.baseUrl}${url}`;
    // eslint-disable-next-line no-underscore-dangle
    return race(this._post<T>(fullUrl, body, options), this.timeout(timeout));
  }

  /**
   * post
   * @description HTTP POST without timeout
   * @param url to send POST Request
   * @param options to add to POST Request
   */
  private _post<T>(url: string, body: object = {}, options: IHttpBaseOptions = {}): Observable<T> {
    return this.webHTTP.post(url, body, options) as Observable<T>;
  }

  /**
   * put
   * @description HTTP PUT
   * @param url to send PUT Request
   * @param options to add to PUT Request
   * @param timeout for PUT Request being treating as error
   */
  public put<T>(url: string, body: object = {}, options: IHttpBaseOptions = {}, timeout: number = 2000): Observable<T> {
    let fullUrl = url;
    if (!this.urlRegEx.test(url) && environment.production)
      fullUrl = `${this.baseUrl}${url}`;
    // eslint-disable-next-line no-underscore-dangle
    return race(this._put<T>(fullUrl, body, options), this.timeout(timeout));
  }

  /**
   * post
   * @description HTTP PUT without timeout
   * @param url to send PUT Request
   * @param options to add to PUT Request
   */
  private _put<T>(url: string, body: object = {}, options: IHttpBaseOptions = {}): Observable<T> {
    return this.webHTTP.put(url, body, options) as Observable<T>;
  }

  /**
   * delete
   * @description HTTP DELETE
   * @param url to send DELETE Request
   * @param options to add to DELETE Request
   * @param timeout for DELETE Request being treating as error
   */
  public delete<T>(url: string, options: object = {}, timeout: number = 2000): Observable<T> {
    let fullUrl = url;
    if (!this.urlRegEx.test(url) && environment.production)
      fullUrl = `${this.baseUrl}${url}`;
    // eslint-disable-next-line no-underscore-dangle
    return race(this._delete<T>(fullUrl, options), this.timeout(timeout));
  }

  /**
   * _delete
   * @description HTTP DELETE without timeout
   * @param url to send DELETE Request
   * @param options to add to DELETE Request
   */
  private _delete<T>(url: string, options: IHttpBaseOptions = {}): Observable<T> {
    return this.webHTTP.delete(url, options) as Observable<T>;
  }

  /**
   * timeout
   * @param time before treating request as failed
   */
  private timeout(time: number): Observable<never> {
    return new Observable((observer) => {
      setTimeout(() => { observer.error("Network Timeout"); }, time);
    });
  }

}
