import { Injectable } from '@angular/core';
import { HomeList } from 'src/app/providers/models/homeList.model';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { Tags } from 'src/app/providers/models/tags.models';

/**
 * Home service
 * @description Service to handle home
 */
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpService
  ) { }

  /**
   *  Get home content
   * @param email User's mail
   */
  public getHome(email: string): Observable<ServerResponse<Array<HomeList>>> {
    return this.http.get<ServerResponse<Array<HomeList>>>(`/content/user/${email}/home`);
  }

  /** Get featured tag on Homepage
   * @param email: User's mail
  */
  public getHomeTag(email: string): Observable<ServerResponse<Array<Tags>>> {
    return this.http.get<ServerResponse<Array<Tags>>>(`/content/user/${email}/home/tags`);
  }

}

