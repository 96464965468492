import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { ClarkService } from 'src/app/modules/http/clark/clark.service';
import { IAedStatus } from 'src/app/providers/models/aedStatus.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { clarkStatus } from 'src/assets/data/clark.data';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';

/** Clark device components */
@Component({
  selector: 'app-clark',
  templateUrl: './clark.component.html',
  styleUrls: ['./clark.component.scss']
})
export class ClarkComponent implements OnInit {

  /*** Viewport width */
  public innerWidth: number;
  /*** User device */
  public isMobile = false;
  /** Input serail number */
  public serial = new FormControl('', [Validators.required, Validators.pattern(/CLA[0-9]{9}$/)]);
  /** If serial number is already linked to a user */
  public textError = '';
  /** Aed - User link status */
  public isLinked = false;
  /** Aed status data */
  public aedData: IAedStatus;
  /** User mail */
  private userMail: string;
  /** Custom component view */
  public aedView = clarkStatus.unlinkedClark;

  constructor(
    public statusService: StatusService,
    private stats: StatsService,
    private userService: UserService,
    public clarkService: ClarkService,
    public circleService: CirclesService
  ) { }

  /** Component initialisation */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 765) {
      this.statusService.showHeaderFooter(true);
      this.statusService.showNavMobile(true);
    } else {
      this.isMobile = true;
      this.statusService.showNavMobile(true);
      this.statusService.showHeaderFooter(false);
    }
    this.userService.getUserMail().then((mail) => {
      this.userMail = mail;
      this.clarkService.getAedData(mail).subscribe(
        (aedStatus) => {
          this.aedData = aedStatus.data;
          this.getClarkStatus(aedStatus.data);
          this.isLinked = true;
        },
        (err) => {
          this.logEvent('[EH] ClarkRegister_Page_Landed');
          console.log(err);
        });
    })
    .catch(() => console.error);
    this.serial.valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.serial.patchValue(this.serial.value.toUpperCase());
    });
  }

  /**
   * Submit Clark serial number to get status
   */
  public submitSerial(): void {
    this.textError = '';
    this.logEvent('[EH] ClarkStatus-Landing_CTA-RegisterClark_Clicked');
    this.clarkService.postAedData(this.userMail, this.serial.value).subscribe(
      (data) => {
        if (data.code === '00') {
          this.clarkService.getAedData(this.userMail).subscribe(
            (aedStatus) => {
              this.addClarkCircle();
              this.getClarkStatus(aedStatus.data);
              this.aedData = aedStatus.data;
            },
            (err) =>
              console.error(err)
          );
        }
      },
      (err) => {
        if (err.error?.code) {
          if (err.error.code === '05')
            this.textError = 'Ce défibrillateur est déjà relié à un compte utilisateur.';
          if (err.error.code === '04')
            this.textError = 'Ce numéro de série n\'est pas reconnu.';
        }
        else
          this.textError = 'Une erreur est survenue, merci de réessayer ultérieurement';
      }
    );
  }

  /**
   * Log event when user clicks on discover lifeaz button
   * @param url url where the user will be redirected (home.lifeaz.co)
   */
  public discoverLifeaz(url: string): void {
    this.logEvent('[EH] ClarkStatus-Landing_CTA-Lifeaz.co_Clicked', {});
    window.open(url, "_blank");
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }


  /**
   * Customize view based on Aed data
   * @param aedData Aed status data
   */
  public getClarkStatus(aedData: IAedStatus): void {
    if (aedData.esw && !this.checkEsw(aedData.esw, '1.2.6'))
      this.aedView = clarkStatus.outdatedClark;
    else if (aedData.aedStatus) {
      if (aedData.lastReport?.length > 0)
        this.aedView = clarkStatus.functionnalClark;
      if (aedData.lastReport.length === 0)
        this.aedView = clarkStatus.unconnectedClark;
    } else
      this.aedView = clarkStatus.warningClark;
    this.isLinked = true;
    this.logEvent('[EH] ClarkStatus_Page_Landed');
  }

  /**
   * Compares two version esw  and returns true if the first version is greater than the second.
   * @param {string} aedEswVersion - The version that needs to be compared.
   * @param {string} minEswVersion - The version to compare against.
   * @return {boolean} - true if aedEswVersion is greater than minEswVersion, false otherwise.
   */
  public checkEsw(aedEswVersion: string, minEswVersion: string): boolean {
    aedEswVersion = aedEswVersion.replace(/^v/i, "");
    minEswVersion = minEswVersion.replace(/^v/i, "");

    const v1 = aedEswVersion.split(".").map(Number);
    const v2 = minEswVersion.split(".").map(Number);

    for (let i = 0; i < 3; i++) {
      if (v1[i] > v2[i])
        return true;
      else if (v1[i] < v2[i])
        return false;
    }
    return false;
  }

  /**
   * Add 'Mon Clark' circle to user account when a serial is added
   */
  public addClarkCircle(): void {
    this.circleService.addCircle(this.userMail, "Mon Clark").subscribe();
  }

}
