import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { Circle } from 'src/app/providers/models/circle.model';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/http/user/user.service';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { DatabaseService } from 'src/app/providers/database/database.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { blurAnimation } from 'src/app/providers/animations/blurAnimation.animation';
import { regex } from 'src/app/providers/models/emailValidator.model';
import { AuthService } from "angularx-social-login";

/**
 * Components that handle signup form relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['../form.component.scss'],
  animations: [ blurAnimation ]
})
export class FormComponent {
  /** Circle information */
  @Input() circle: Circle;
  /** User device */
  @Input() displayMobile: boolean;
  /** Form display status */
  @Input() displayLogin: boolean;
  /** Form view emitter */
  @Output()
  isRegister: EventEmitter<number> = new EventEmitter();
  /** Login form view emitter */
  @Output()
  isLoginHide: EventEmitter<boolean> = new EventEmitter();
  /** Circle form view emitter */
  @Output()
  isCircleForm: EventEmitter<number> = new EventEmitter();
  /** Password popup view emitter  */
  @Output()
  isPopup: EventEmitter<boolean> = new EventEmitter();
  /** Form submission status */
  public submitted = false;
  /** Login errors status */
  public logError: boolean;
  /** Password validity status */
  public invalidPassword = false;
  /** Email regex */
  public regEmail = regex;
  /** User account status */
  public userError = false;
  /** Invalid mail error */
  public mailError = false;
  /**
   *  Reactive form builder & validators
   */
  // eslint-disable-next-line no-invalid-this
  loginForm = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private serviceLogin: LoginService,
    private router: Router,
    public userService: UserService,
    public circleService: CirclesService,
    private storage: DatabaseService,
    private historyService: HistoryService,
    private stats: StatsService,
    private oauth: AuthService
  ) { }

  /**
   *  Get an alias on form
   */
  get f(): any {
    return this.loginForm.controls;
  }

  /**
   *  Login method, check password, if no user in DB load signup page
   */
  public onSubmit(): void {
    this.submitted = true;
    this.invalidPassword = false;
    this.mailError = false;
    if (this.f.password && this.f.email) {
      const mail = this.f.email.value.toLowerCase().trim();
      const password = this.f.password.value;
      if (!this.regEmail.test(mail))
        this.mailError = true;
      else {
        this.serviceLogin.login(mail, password)
        .subscribe((data) => {
          if (data.code === '00') {
            window.localStorage.setItem('token', data.data.token);
            this.userService.userMail = mail;
            if (this.circle) {
              this.circleService.getCircleUserInfo(this.userService.userMail, this.circle.name).subscribe(
                (param) => {
                  if (param.data.length !== 0)
                    this.redirectURL();
                  else
                    this.isCircleForm.emit(2);
                });
            }
            else
              this.redirectURL();
          }
        }, (err) => {
          if (err.status === 404)
            this.userError = true;
          else if (err.status === 401)
            this.invalidPassword = true;
          else {
            this.logError = true;
            this.invalidPassword = true;
          }
        });
      }
    }
  }
  /**
   *  Boolean to show error after submit
   */
  public formSubmit(): void {
    if (this.submitted)
      this.submitted = false;
  }

  /**
   *  Hide mobile form on back arrow
   */
  public hideMobile(): void {
    this.displayMobile = false;
    this.isLoginHide.emit(false);
  }

  /**
   * Show password popup
   */
  public showPopup(): void {
    this.isPopup.emit(true);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /** Redirect user on last URL */
  public redirectURL(): void {
    this.serviceLogin.isConnected(true);
    this.historyService.getUrl()
    .then((redirectUrl) => {
      if (redirectUrl) {
        this.storage.remove("previousUrl")
        .catch(console.error);
        this.router.navigateByUrl(redirectUrl)
        .catch(console.error);
      } else {
        this.router.navigateByUrl('home')
        .catch(console.error);
      }
    })
    .catch(console.error);
  }

  /** Show register form */
  public showRegister(): void {
    this.isRegister.emit(3);
  }

  /** Login with Google or Facebook */
  public loginSocial(param: string): void {
    this.oauth.signIn(param)
    .then((user) => {
      let email = null;
      if (param === "FACEBOOK" || "GOOGLE")
        email = user.email;
      this.userService.loginSocial(param, user.authToken, email, user.firstName)
      .subscribe((data) => {
        if (data.code === '00') {
          this.logEvent(`[EH] RS_${param}_login`);
          window.localStorage.setItem('token', data.data.token);
          this.userService.userMail = user.email;
          if (this.circle) {
            this.circleService.getCircleUserInfo(this.userService.userMail, this.circle.name).subscribe(
              (param) => {
                if (param.data.length !== 0)
                  this.redirectURL();
                else
                  this.isCircleForm.emit(2);
              });
          }
          else
            this.redirectURL();
        }
      });
    }).catch((err) => console.log(err));
  }
}
