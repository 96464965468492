import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/http/user/user.service';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { LoginService } from 'src/app/modules/http/login/login.service';

/**
 * Component that handles delete popup related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {

  /*** User's mail */
  public email: string;

  constructor(
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public loginService: LoginService
  ) {
  }

  /**
   * Get user's mail
   */
  ngOnInit(): void {
    this.userService.getUserMail()
    .then((mail) => {
      this.email = mail;
    })
    .catch(console.error);
  }

  /**
   * Delete account on validation
   */
  public deleteAccount(): void {
    this.userService.deleteUser(this.email).subscribe(
      (data) => {
        this.loginService.isConnected(false);
        this.authenticationService.logout();
      }
    );
  }

}
