import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/providers/http/http.service';
import { ServerResponse } from 'src/app/providers/models/ServerResponse.model';
import { Observable } from 'rxjs';

/**
 * FriendService
 * @description Service to Share code
 */
@Injectable({
  providedIn: 'root'
})
export class FriendService {

  constructor(
    private http: HttpService
  ) { }

  /**
   * Share user's link to friends
   * @param email User's mail
   * @param emailtoshare User's friend mail
   * @param message Custom message
   */
  public shareToFriends(email: string, emailtoshare: Array<string>, message: string): Observable<ServerResponse<void>> {
    return this.http.post<ServerResponse<void>>(`/platform/user/${email}/share`, { emailtoshare, message });
  }

}
