import { Component } from '@angular/core';

/**
 * Components that handle app component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  title = 'everydayheroes-front';
}
