import { Component, Input, OnInit } from '@angular/core';
import { SceneFinished } from 'src/app/providers/models/sceneFinished.model';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { UserService } from 'src/app/modules/http/user/user.service';

/**
 * Component that handles profil stats related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-my-stats',
  templateUrl: './my-stats.component.html',
  styleUrls: ['./my-stats.component.scss']
})
export class MyStatsComponent implements OnInit {

  /** Number of scenes finished by the user */
  public totalScene: SceneFinished;
  /** User's average score */
  @Input() score: number;

  constructor(
    public statusService: StatusService,
    public userService: UserService
  ) { }

  /** init */
  ngOnInit(): void {
    this.statusService.userTotal.subscribe(
      (total) => this.totalScene = total
    );
  }
}
