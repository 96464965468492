import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { Router } from '@angular/router';
import { slideDown } from 'src/app/providers/animations/slideDown.animation';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { Circle } from 'src/app/providers/models/circle.model';

/**
 * Components that handle password popup relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-password-popup',
  templateUrl: './password-popup.component.html',
  styleUrls: ['./password-popup.component.scss'],
  animations: [
    enterAnimation, slideDown
  ]
})
export class PasswordPopupComponent implements OnInit {
  /** Mail field status */
  public wrongMail = false;
  /** User mail */
  public email: string;
  /** Email validation status */
  public emailCheck = false;
  /** Form submission status */
  public submit = false;
  /** User device */
  public isMobile: boolean;
  /** Form view status emitter */
  @Output()
  isPopup: EventEmitter<boolean> = new EventEmitter();
  /** Form view input */
  @Input() show: boolean;
  /** Current circle (for stats purposes) */
  @Input() circle: Circle;
  constructor(
    public loginService: LoginService,
    public statusService: StatusService,
    public router: Router,
    private stats: StatsService
  ) { }
  /** Get user device */
  ngOnInit(): void {
    if (this.statusService.isMobile.value) {
      this.isMobile = true;
      this.statusService.showHeaderFooter(false);
    }
  }

  /**
   * Show/hide password popup
   */
  showPopup(): void {
    this.isPopup.emit(false);
    if (this.isMobile)
      this.statusService.showHeaderFooter(true);
  }

  /**
   * Submit email adress to send forgot password mail
   */
  sendPassword(): void {
    this.submit = true;
    this.loginService.forgotPassword(this.email.toLowerCase()).subscribe(
      (data) => {
        this.wrongMail = false;
        this.emailCheck = true;
        this.logEvent('[EH] Landing_Forgotten-password_Form-Submission_Successful', {email: this.email, idCircle: this.circle ? this.circle.idCircle : 0});
        setTimeout(() => this.isPopup.emit(false), 2000);
      },
      (err) => this.wrongMail = true
    );
  }

  /**
   * Close popup on click outside
   */
  clickOutside(): void {
    this.isPopup.emit(false);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}

