import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Circle } from 'src/app/providers/models/circle.model';
import { FormBuilder, Validators, Form } from '@angular/forms';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/http/user/user.service';
import { DatabaseService } from 'src/app/providers/database/database.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { blurAnimation } from 'src/app/providers/animations/blurAnimation.animation';

/**
 * Components that handle signup form relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-form-signup',
  templateUrl: './form-signup.component.html',
  styleUrls: ['../form.component.scss'],
  animations: [blurAnimation]
})
export class FormSignupComponent {
  /** User circle information */
  @Input() circle: Circle;
  /** Form content */
  @Input() formContent: string;
  /** User device status */
  @Input() displayMobile: boolean;
  /** Form status */
  @Input() displaySignup: boolean;
  /** Recaptcha token */
  public recaptchaStr = '';
  /** Pasword regex */
  public regex = RegExp(/^(?=.*\d).{6,}$/);
  /** Form submission status */
  public submitted = false;
  /** Form errors status */
  public logError: boolean;
  /** Password validity status */
  public isSamePassword: boolean;
  /** User mail */
  public email: string;
  /** Custom form status */
  public goCustom = false;
  /** Password validity status */
  public passFormat: boolean;
  /** Page view emitter */
  @Output()
  isLogin: EventEmitter<number> = new EventEmitter();
  /** Login form view emitter */
  @Output()
  isLoginHide: EventEmitter<boolean> = new EventEmitter();
  /**
   *  Reactive form builder & validators
   */
  // eslint-disable-next-line no-invalid-this
  signupForm = this.fb.group({
    password: ['', Validators.required],
    passwordConfirm: ['', Validators.required],
    firstname: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private serviceLogin: LoginService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private storage: DatabaseService,
    public historyService: HistoryService,
    private stats: StatsService,
    private circleService: CirclesService
  ) { }

  /**
   *  Get an alias on form
   */
  get f(): any {
    return this.signupForm.controls;
  }

  /**
   *  Check password and recaptcha to validate form
   */
  public onSubmit(): any {
    this.email = this.formContent.toLowerCase();
    this.submitted = true;
    this.passFormat = true;
    this.isSamePassword = true;
    if (!this.f.firstname.valid)
      return;
    if (this.f.passwordConfirm.value === this.f.password.value) {
      this.isSamePassword = true;
      if (!this.regex.test(this.f.password.value)) {
        this.passFormat = false;
        return;
      }
      if (!this.userService.userInvite[0]) {
        let circleName = '';
        if (this.circle && this.circle.name)
          circleName = this.circle.name;
        this.serviceLogin.register(this.email, this.f.password.value, this.f.firstname.value, circleName).subscribe(
          (data) => {
            if (data.code === '00') {
              this.storage.set('token', data.data.token)
              .then(() => {
                if (this.circle) {
                  if (this.circle.form.length > 0)
                    this.isLogin.emit(2);
                  else {
                    this.circleService.addCircle(this.email, this.circle.name)
                    .subscribe(() => {
                      this.serviceLogin.isConnected(true);
                      this.logError = false;
                      return this.navigateAfterLogin();
                    });
                  }
                } else {
                  this.logError = false;
                  this.serviceLogin.isConnected(true);
                  return this.navigateAfterLogin();
                }
              })
              .catch(console.error);
            }
          },
          (err) => {
            this.logError = true;
          }
        );
      } else {
        this.userService.registerInvite(
          this.email, this.f.password.value, this.f.firstname.value, this.userService.userInvite[1]).subscribe(
          (data) => {
            if (data.code === '00') {
              this.logError = false;
              this.storage.set('token', data.data.token)
              .then(() => {
                this.serviceLogin.isConnected(true);
                return this.navigateAfterLogin();
              })
              .catch(console.error);
            }
          },
          (err) => {
            this.logError = true;
          }
        );
      }
    } else this.isSamePassword = false;
  }

  /**
   * Navigates to a page after login
   */
  public navigateAfterLogin(): void {
    this.historyService.getUrl()
    .then((redirectUrl) => {
      if (redirectUrl) {
        this.storage.remove("previousUrl")
        .catch(console.error);
        return this.router.navigate([redirectUrl])
        .catch(console.error);
      } else {
        return this.router.navigateByUrl('home')
        .catch(console.error);
      }
    })
    .catch(console.error);
  }

  /**
   *  Boolean to show error after submit
   */
  public formSubmit(): void {
    if (this.submitted)
      this.submitted = false;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   * Change login view
   */
  public showLogin(): void {
    this.isLogin.emit(1);
  }

  /**
   *  Hide mobile form on back arrow
   */
  public hideMobile(): void {
    this.displayMobile = false;
    this.isLoginHide.emit(true);
  }

  /**
   * Submit captcha
   * @param captchaRef Captcha token
   */
  public onLoginClick(captchaRef: any): void {
    this.onSubmit();
  }

  /**
   * Submit form is captcha is valid
   * @param captchaResponse Captcha response
   */
  public resolved(captchaResponse: string): void {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr)
      this.onSubmit();
    else
      this.onSubmit();
  }
}
