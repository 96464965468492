import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/modules/http/user/user.service';

/** Components that handle unsubscribe relative methods */
@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  /** Unsubscribe status */
  public unsubscribeStatus: boolean;
  constructor(
    public statusService: StatusService,
    public activatedRoute: ActivatedRoute,
    public userService: UserService
  ) { }

  /** Don't show footer and unsubscribe user */
  ngOnInit(): void {
    this.statusService.showFooter(false);
    this.activatedRoute.queryParams.subscribe(
      (data) =>
        this.userService.unsubscribeUser(data.token).subscribe(
          (answer) =>
            answer.code === '00' ? this.unsubscribeStatus = true : this.unsubscribeStatus = false
        )
    );
  }

}
