import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { zoomIn } from 'src/app/providers/animations/zoomIn.animation';
import { LightBadge } from 'src/app/providers/models/lightBadge.model';
import { LightPath } from 'src/app/providers/models/lightPath.model';
/** Unlocked badge handling components */
@Component({
  selector: 'app-unlocked-badge',
  templateUrl: './unlocked-badge.component.html',
  styleUrls: ['./unlocked-badge.component.scss'],
  animations: [zoomIn]
})
export class UnlockedBadgeComponent {
  /** Badge data */
  @Input() badge: LightBadge;
  /** actual path data */
  @Input() path: LightPath;
  /** Show theme page */
  @Output()
  public showTheme: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public router: Router
  ) { }

  /** Show unlocked theme page */
  public goTheme(): void {
    this.showTheme.emit(true);
  }
}
