import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { PathsService } from 'src/app/modules/http/paths/paths.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { trigger, transition, style, animate } from '@angular/animations';
import { slideDown } from 'src/app/providers/animations/slideDown.animation';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Path } from 'src/app/providers/models/paths.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { PathScene } from 'src/app/providers/models/pathScene.model';

/**
 * Component that handles paths related methods on mobile
 * @author jeremy65
 */
@Component({
  selector: 'app-path-mobile',
  templateUrl: './path-mobile.component.html',
  styleUrls: ['./path-mobile.component.scss'],
  animations: [
    enterAnimation,
    slideDown,
    trigger('slideDown', [
      transition(':enter', [
        style({ transform: 'translateY(150%)', opacity: 0 }),
        animate('500ms ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class PathMobileComponent implements OnInit, OnDestroy {

  /** Current path in view */
  public currentSlide: Path;
  /** All paths list */
  public pathsList: [Path];
  /** Path detail page status */
  public pathShowed = false;
  /*** Viewport width */
  public innerWidth: number;
  /*** User device */
  public isMobile = false;
  /** Path index */
  public actualPath = 0;
  /** Scene detail status */
  public isBottom = true;
  /** Previous showed path */
  public prevPath: Path;
  /** Path showed index */
  public pathIndex: number;
  /** Chosen categorie */
  public clickedCategorie: string;
  /** Categorie click status */
  public showCategorie = false;
  /** Next scene to play */
  public nextScene: PathScene;
  /** Carousel component */
  @ViewChild('slickModal', null)
  slickModal: SlickCarouselComponent;
  /** Carousel config */
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: ('.next'),
    prevArrow: ('.prev'),
    swipeToSlide: true,
    variableWidth: true
  };

  constructor(
    public router: Router,
    public serviceLogin: LoginService,
    public statusService: StatusService,
    public pathsService: PathsService,
    public route: ActivatedRoute,
    private stats: StatsService
  ) { }
  /** Get viewport width, path datas from resolver and set user score */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 765)
      this.isMobile = true;
    else
      this.isMobile = false;
    this.statusService.showHeaderFooter(true);
    this.statusService.showNavMobile(true);
    this.route.data.subscribe(
      (data) => {
        this.pathsList = data.path.data;
      }
    );
    this.pathsList.sort((a, b) => a.idPath - b.idPath);
    for (let i = 0; i < this.pathsList.length; i++) {
      this.pathsList[i].index = i;
      this.pathsList[i].score = 0;
      this.pathsList[i].total = 0;
      this.pathsList[i].categories.forEach((categorie) => {
        for (const opt of categorie.scenes) {
          opt.hearts = [];
          if (opt.score === null)
            return;
          let totalScore = opt.score;
          while (opt.hearts.length < 5) {
            if (totalScore >= 1) {
              opt.hearts.push('assets/icons/full_heart.svg');
              totalScore = totalScore - 1;
            } else if (totalScore >= 0.5) {
              opt.hearts.push('assets/icons/half_heart.svg');
              totalScore = totalScore - 0.5;
            } else if (totalScore < 0.5)
              opt.hearts.push('assets/icons/empty_heart.svg');
          }
        }
        categorie.isPromValid = 0;
        categorie.score = 0;
        categorie.scenes.map((x) => {
          this.pathsList[i].total += 1;
          if (x.score >= 4) {
            this.pathsList[i].score += 1;
            categorie.isPromValid += 1;
            categorie.score += 1;
          }
        });
      });
      if (this.pathsList[i].score / this.pathsList[i].total === 1)
        this.pathIndex = this.pathsList.findIndex((x) => x.idPath === (this.pathsList[i].idPath + 1));
    }
    if (this.pathIndex && this.pathIndex !== -1)
      this.currentSlide = this.pathsList[this.pathIndex];
    else
      this.currentSlide = this.pathsList[0];
    this.pathsService.pathsShowed.subscribe(
      (data) => this.pathShowed = data
    );
  }
  /**
   * Reset header color when leaving path page
   */
  ngOnDestroy(): void {
    this.statusService.changeHeaderColor('#ffffff');
  }

  /**
   * Change the highlighted path
   * @param col Header color
   */
  public showPath(col?: any, $event?: MouseEvent): void {
    if (typeof col === 'string') {
      this.pathShowed = !this.pathShowed;
      this.prevPath = this.pathsList.find((x) => x.index === this.currentSlide.index - 1);
      if (!this.pathShowed)
        this.statusService.changeHeaderColor('#ffffff');
      else
        this.statusService.changeHeaderColor(col);
    } else if (typeof col === 'number') {
      const path = this.pathsList.find((x) => x.index === col);
      this.prevPath = this.pathsList.find((x) => x.index === col - 1);
      if (path.secondaryColor === null)
        this.statusService.changeHeaderColor('#85a3d1');
      else
        this.statusService.changeHeaderColor(path.secondaryColor);
    } else if (!col) {
      if ($event && $event.clientX > 340)
        this.currentSlide = this.pathsList.find((x) => x.index === this.currentSlide.index + 1);
      if ($event && $event.clientX < 25)
        this.currentSlide = this.pathsList.find((x) => x.index === this.currentSlide.index - 1);
      this.pathShowed = !this.pathShowed;
      if (this.pathShowed)
        this.statusService.changeHeaderColor('#85a3d1');
      else
        this.statusService.changeHeaderColor('#ffffff');
    }
    this.nextScene = this.getNextScene();
  }

  /**
   * Get current slide to show in top page
   * @param $event Carousel data
   */
  public afterChange($event: any): void {
    this.actualPath = $event.currentSlide;
    this.currentSlide = this.pathsList.find((x) => x.index === this.actualPath);
    if (this.pathShowed)
      this.showPath($event.currentSlide);
    this.nextScene = this.getNextScene();
  }

  /**
   * Show selected path
   * @param event Path secondary color
   */
  public swipeUp(event: any): void {
    if (!this.pathShowed)
      this.showPath(event);
  }

  /**
   * Show next path
   * @param index Path index number
   */
  public changeNextPath(index: number): void {
    this.prevPath = this.pathsList.find((x) => x.index === this.currentSlide.index);
    if (index < this.pathsList.length - 1) {
      this.currentSlide = this.pathsList.find((x) => x.index === (index + 1));
      if (!this.currentSlide.secondaryColor)
        this.statusService.changeHeaderColor('#85a3d1');
      else
        this.statusService.changeHeaderColor(this.currentSlide.secondaryColor);
      this.logEvent('[EH] Path_OtherPath_Clicked', {idPath: this.currentSlide.idPath});
    }
    this.nextScene = this.getNextScene();
  }

  /**
   * Show previous path
   * @param index Path index number
   */
  public changePrevPath(index: number): void {
    this.prevPath = this.pathsList.find((x) => x.index === this.currentSlide.index - 1);
    if (index > 0) {
      this.currentSlide = this.pathsList.find((x) => x.index === (index - 1));
      if (!this.currentSlide.secondaryColor)
        this.statusService.changeHeaderColor('#85a3d1');
      else
        this.statusService.changeHeaderColor(this.currentSlide.secondaryColor);
      this.logEvent('[EH] Path_OtherPath_Clicked', {idPath: this.currentSlide.idPath});
    }
    this.nextScene = this.getNextScene();
  }

  /**
   * Slick to selected path
   */
  public changePath(): void {
    setTimeout(() => this.slickModal.slickGoTo(this.currentSlide.index), 0);
  }

  /**
   * Is bottom mosaic up/down
   * @param $event Mosaic status
   */
  public bottomShow($event: boolean): void {
    this.isBottom = $event;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   *  Scroll to $element
   * @param $element Id to scroll to
   */
  public chosenCategorie($element: string): void {
    this.bottomShow(false);
    this.showCategorie = !this.showCategorie;
    this.clickedCategorie = $element;
  }

  /**
   * Get next scene to play
   */
  public getNextScene(): PathScene {
    let lastScene: PathScene;
    for (const cat of this.currentSlide.categories) {
      for (const scene of cat.scenes) {
        if (!scene.locked)
          lastScene = scene;
        else
          break;
      };
    }
    return lastScene;
  }
}
