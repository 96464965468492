export const tipsScene = [
  'Invitez vos amis et soyez tous prêts à sauver des vies. ⚡️',
  'Tous nos contenus sont validés par les Pompiers de Paris ! 👩‍🚒',
  'En cas d\'urgence, appellez le 18 👩‍🚒 (Pompiers) ou le 15 👩🏽‍⚕️ (SAMU).',
  'Un commentaire sur l\'app ? Cliquez sur "Contact" ! 💌',
  'Du nouveau contenu 🎁 est ajouté toutes les semaines !',
  'L\'accueil vous propose toujours les prochaines scènes les plus pertinentes. 😉',
  'Développé avec ❤ par Lifeaz',
  'Face à un arrêt cardiaque : Alertez, Massez, Défibrillez ! ⚡️',
  'Les parcours vous forment à l\'AMD, aux Gestes qui Sauvent et au PSC1 ! 💪',
  'Les scènes bonus permettent d\'apprendre et réviser dans n\'importe quel ordre. 😉'
];
