import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BadgesService } from 'src/app/modules/http/badges/badges.service';
import { UserService } from 'src/app/modules/http/user/user.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { Badge } from 'src/app/providers/models/badge.model';

/**
 * Component that handles badges related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-my-badges',
  templateUrl: './my-badges.component.html',
  styleUrls: ['./my-badges.component.scss'],
  animations: [enterAnimation]
})
export class MyBadgesComponent implements OnInit {

  /*** Badges method */
  public badgesList: Array<Badge>;
  /*** User's mail */
  public userMail: string;
  /*** User device */
  @Input() isMobile: boolean;
  /** Tip view status */
  public isTooltipShow: number;
  constructor(
    public badgesService: BadgesService,
    public userService: UserService
  ) { }

  /**
   * Get user's mail and resolver's data
   */
  ngOnInit(): void {
    this.userService.getUserMail()
    .then((mail) => {
      this.userMail = mail;
      this.badgesService.getUserBadges(this.userMail).subscribe(
        (userBadges) => this.badgesList = userBadges.data
      );
    })
    .catch(console.error);
  }

  /**
   * Show badge tip
   * @param index Badge loop index
   */
  public showTip(index: number): number {
    return index === this.isTooltipShow ? this.isTooltipShow = null : this.isTooltipShow = index;
  }
}
