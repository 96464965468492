import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { UserService } from '../http/user/user.service';
import { BonusService } from '../http/bonus/bonus.service';

/**
 * BonusResolver
 */
@Injectable()
export class BonusResolver implements Resolve<any> {

  constructor(
    public bonusService: BonusService,
    public userService: UserService
  ) { }

  /**
   * Get bonus scenes
   */
  public resolve(): Promise<any> {
    return this.userService.getUserMail()
    .then((mail) =>
      this.bonusService.getBonus(mail).toPromise()
    );
  }

}
