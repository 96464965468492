import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SceneTransition } from 'src/app/providers/models/sceneTransition.model';
import { Scene } from 'src/app/providers/models/scene.model';

/** Unlocked theme page handling components */
@Component({
  selector: 'app-unlocked-theme-scene',
  templateUrl: './unlocked-theme-scene.component.html',
  styleUrls: ['./unlocked-theme-scene.component.scss']
})
export class UnlockedThemeSceneComponent implements OnInit {

  /** Theme informations */
  @Input() theme: SceneTransition;
  /** Get next scene */
  @Input() scene: [Scene, boolean?];
  /** Path end status */
  @Input() pathEnd: boolean;
  /** Theme & scene view status */
  public showThemeScene = false;
  /** Scene view status */
  public showScene = false;
  constructor(
    public router: Router
  ) { }

  /** Check next scene to change view */
  ngOnInit(): void {
    if (this.pathEnd)
      return;
    if (this.scene[0] && this.scene[1])
      this.showScene = true;
    else if (this.scene[0] && !this.scene[1])
      this.showThemeScene = true;
  }

  /** Navigate to next scene */
  public goScene(): void {
    this.router.navigateByUrl(`/scene/${this.theme.nextScene.url}`)
    .catch(console.error);
  }

  /**
   * Goes back to home page
   */
  public backClicked(): void {
    this.router.navigateByUrl('home')
    .catch(console.error);
  }
}
