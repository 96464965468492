import { Component, OnInit } from '@angular/core';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { BonusService } from 'src/app/modules/http/bonus/bonus.service';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
// import { NgsRevealService, NgsRevealConfig } from 'ngx-scrollreveal';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from 'src/app/modules/history/history.service';
import { BonusScene } from 'src/app/providers/models/bonusScene.model';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Component that handles bonus related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss'],
  animations: [enterAnimation]
  // providers: [NgsRevealConfig]
})
export class BonusComponent implements OnInit {

  /*** Viewport width */
  public innerWidth: number;
  /*** Sitcky searchbar status */
  public isSticky = false;
  /*** Filtered bonus array */
  public filteredBonusList: Array<BonusScene>;
  /*** All bonus scenes */
  public bonusList: Array<BonusScene>;
  /*** Promoted scene count */
  public isPromoted = 0;
  /*** Unpromoted scene count */
  public notPromoted = 0;
  /*** Valid promoted scene count */
  public isPromValid = 0;
  /*** Valid unpormoted scene count */
  public isNotPromValid = 0;
  /*** User device */
  public isMobile = false;
  /*** Tag page on mobile status */
  public isTagMobile = false;
  /*** Whether or not the research returned an empty result */
  public emptySearchResult = false;

  constructor(
    // private revealService: NgsRevealService,
    public statusService: StatusService,
    public bonusService: BonusService,
    public route: ActivatedRoute,
    public historyService: HistoryService,
    public router: Router,
    private stats: StatsService
  ) { }

  /**
   * Set URL, get user device. Get data from resolver, add user's score and count valid scene
   */
  ngOnInit(): void {
    this.historyService.getUrl()
    .then((url) => {
      this.logEvent('[EH] Bonus_Page_Landed', {referrer: url});
    })
    .catch(console.error);
    this.historyService.setUrl();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 765)
      this.statusService.showHeaderFooter(true);
    else {
      this.isMobile = true;
      this.statusService.showNavMobile(true);
      this.statusService.showHeaderFooter(true);
    }
    this.route.data.subscribe(
      (res) => {
        this.bonusList = res.bonus.data;
      }
    );
    for (const opt of this.bonusList) {
      opt.hearts = [];
      if (opt.score === null)
        return;

      let totalScore = opt.score;
      while (opt.hearts.length < 5) {
        if (totalScore >= 1) {
          opt.hearts.push('assets/icons/full_heart.svg');
          totalScore = totalScore - 1;
        } else if (totalScore >= 0.5) {
          opt.hearts.push('assets/icons/half_heart.svg');
          totalScore = totalScore - 0.5;
        } else if (totalScore < 0.5)
          opt.hearts.push('assets/icons/empty_heart.svg');

      }
    }
    this.filteredBonusList = JSON.parse(JSON.stringify(this.bonusList));
    this.setCounters();
  }

  // Incoming cards animation
  // ngOnDestroy(): void {
  //   this.revealService.destroy();
  // }

  /**
   * Sets counters for promoted/unpromoted scenes
   */
  public setCounters(): void {
    this.isPromoted = 0;
    this.notPromoted = 0;
    this.isPromValid = 0;
    this.isNotPromValid = 0;
    this.filteredBonusList.map((x) => {
      if (x.featured) {
        this.isPromoted += 1;
        if (x.score >= 4)
          this.isPromValid += 1;
      } else {
        this.notPromoted += 1;
        if (x.score >= 4)
          this.isNotPromValid += 1;
      }
    });
  }

  /**
   * Show/hide cloud tag
   * @param $event Cloud tag status
   */
  public showTag($event: boolean): void {
    this.isSticky = $event;
  }

  /**
   * Bonus search method by tag and string
   * @param param Search value
   */
  public searchBonus(param: any): void {
    let tagArray = [];
    let titleArray = [];
    this.filteredBonusList = this.bonusList;
    if (param[0].length > 0)
      this.filteredBonusList = this.searchTag(param[0]);
    if (param[1].length > 0 && param[2].length === 0) {
      tagArray = this.searchTag([param[1]]);
      titleArray = this.searchString(param[1]);
      this.filteredBonusList = this.mergeArray(tagArray, titleArray);
    }
    if (param[2] !== "undefined" && param[2]?.length > 0) {
      const allTags = param[0].concat(param[2]);
      tagArray = this.searchTag(allTags);
      titleArray = this.searchString(param[1]);
      this.filteredBonusList = this.mergeArray(tagArray, titleArray);
    }
    if (this.filteredBonusList.length === 0) {
      this.emptySearchResult = true;
      this.filteredBonusList = this.bonusList;
    } else
      this.emptySearchResult = false;
    this.setCounters();
  }

  /**
   * Search bonus scenes by string
   * @param param Searched string
   */
  private searchString(param: string): Array<BonusScene> {
    return Object.assign([], this.bonusList).filter(
      (item) => item.title.toLowerCase().indexOf(param.toLowerCase()) > -1);
  }

  /**
   * Search bonus scenes corresponding to some tags
   * @param param Searched tag array
   */
  private searchTag(param: Array<string>): Array<BonusScene> {
    return this.bonusList.filter((scene) => {
      if (scene.keywords)
        return param.some((value) => scene.keywords.includes(value));
    });
  }

  /**
   * Merge arrays and filter duplicate results
   * @param arr1 Searched string array
   * @param arr2 Searched tag array
   */
  private mergeArray(arr1: Array<BonusScene>, arr2: Array<BonusScene>): Array<BonusScene> {
    const arr3 = arr1.concat(arr2);
    return arr3.filter((val, ind, a) => arr3.findIndex((par) => (JSON.stringify(par) === JSON.stringify(val))) === ind);
  }

  /**
   * Change page container on tag mobile
   * @param $event is Tag mobile
   */
  public mobileTag($event: boolean): void {
    this.isTagMobile = $event;
  }

  /**
   * Navigates to a scene
   * @param url Scene url
   */
  public goToScene(url: string): void {
    this.router.navigateByUrl(`scene/${url}`)
    .catch(console.error);
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
