import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

/**
 * Component that handles progress circle related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnChanges {
  /** Circle fill value */
  @Input() value: number;
  /** Circle radius */
  public radius = 44;
  /** Circle circumference */
  // eslint-disable-next-line no-invalid-this
  public circumference = 2 * Math.PI * this.radius;
  /** Circle offset */
  public dashoffset: number;

  constructor() {
    this.progress(0);
  }

  /** Update circle size on changes */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value.currentValue !== changes.value.previousValue)
      this.progress(changes.value.currentValue);

  }

  /**
   * Change circle offset
   * @param value Progression value
   */
  private progress(value: number): void {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }
}
