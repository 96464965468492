import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Theme } from 'src/app/providers/models/themes.model';
import { Observable } from 'rxjs';

/**
 * ThemeService
 * @description Service to handle Themes
 */
@Injectable({
  providedIn: 'root'
})
export class ThemesService {
  constructor(
    private http: HttpClient
  ) { }

  /**
   *  Get Themes
   */
  public getThemes(): Observable<Array<Theme>> {
    return this.http.get<Array<Theme>>('../assets/data/themesMock.json');
  }

}
