import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserService } from '../http/user/user.service';
import { HomeService } from '../http/home/home.service';

/**
 * HomeResolver
 */
@Injectable()
export class HomeResolver implements Resolve<any> {

  constructor(
    public homeService: HomeService,
    public userService: UserService
  ) { }

  /**
   * Get user's path scenes
   */
  public resolve(): Promise<any> {
    return this.userService.getUserMail()
    .then((mail) =>
      this.homeService.getHome(mail).toPromise()
    );
  }

}
