import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

/** Directive allowing to listen outside click from an element */
@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  /** Emitter that will be triggered upon clicking outside */
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  /**
   * Click handler
   * @param target div that was clicked
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside)
      this.clickOutside.emit();
  }
}
