import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { trigger, animate, transition, style } from '@angular/animations';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { Answer } from 'src/app/providers/models/answer.model';
import { LightStep } from 'src/app/providers/models/lightStep.model';

/**
 * Component handling UI-related scene elements (questions, answers, summaries)
 */
@Component({
  selector: 'app-scene-ui',
  templateUrl: './scene-ui.component.html',
  styleUrls: [
    './scene-ui.component.scss',
    './scene-popup.scss'
  ],
  animations: [
    enterAnimation,
    trigger(
      'enterWrong', [
        transition(':enter', [
          style({ opacity: 0, bottom: '-67px' }),
          animate('200ms', style({ opacity: 1, bottom: '0' }))
        ]),
        transition(':leave', [
          style({ opacity: 1, bottom: '0' }),
          animate('200ms', style({ opacity: 0, bottom: '-67px' }))
        ])
      ]
    ),
    trigger(
      'enterBravo', [
        transition(':enter', [
          style({ 'font-size': '1px' }),
          animate('180ms', style({ 'font-size': '20px' }))
        ])
      ]
    ),
    trigger(
      'enterGoodAnswer', [
        transition(':enter', [
          style({ 'font-size': '0px' }),
          animate('100ms', style({ 'font-size': '0px' })),
          animate('350ms', style({ 'font-size': '42px' })),
          animate('200ms', style({ 'font-size': '26px' })),
          animate('120ms', style({ 'font-size': '33px' })),
          animate('120ms', style({ 'font-size': '30px' })),
          animate('120ms', style({ 'font-size': '31px' }))
        ])
      ]
    ),
    trigger(
      'incrementGoodAnswers', [
        transition(':increment', [
          style({ opacity: 0 }),
          animate('200ms', style({ opacity: 1 }))
        ])
      ]
    ),
    trigger(
      'enterStar', [
        transition(':enter', [
          style({ width: 0, opacity: 1 }),
          animate('200ms', style({ width: '16px', opacity: 1 })),
          animate('500ms', style({ width: '16px', opacity: 1 })),
          animate('300ms', style({ opacity: 0 }))
        ])
      ]
    )
  ],
  encapsulation: ViewEncapsulation.None
})
export class SceneUiComponent implements OnInit, OnChanges {
  /** Current step object */
  @Input() step: LightStep;
  /** List of existing answers */
  @Input() answerList: Array<Answer>;
  /** Whether or not this step is the last one */
  @Input() isLastStep: boolean;
  /** Emitter that tells the parent component that it should switch to the next step */
  @Output() emitNextStep: EventEmitter<void> = new EventEmitter();
  /** Emitter that tells the parent a mistake has been made by the user */
  @Output() emitMistake: EventEmitter<void> = new EventEmitter();
  /** Emit view status */
  @Output() emitPopup: EventEmitter<boolean> = new EventEmitter();
  /** Total valid answers for this step */
  public totalAnswers = 0;
  /** Current amount of good answers from the user for this step */
  public goodAnswers = 0;
  /** Whether or not to display the "wrong answer" banner */
  public displayWrongAnswer = false;
  /** Whether or not to display the answers list */
  public displayAnswers = true;
  /** Whether or not to display the step summary */
  public displayPopup = false;
  /** Whether or not the user made a mistake in this step */
  public madeMistake = false;

  constructor(
  ) { }

  /**
   * Initializes UI elements for current step
   */
  ngOnInit(): void {
    this.initStepUI();
  }

  /**
   * Handles navigating from step to step
   * @param changes Step changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.answerList && changes.step && changes.step.previousValue)
      this.initStepUI();

  }

  /**
   * Initializes the UI variables and elements for a step
   */
  public initStepUI(): void {
    this.displayAnswers = true;
    this.displayPopup = false;
    this.goodAnswers = 0;
    this.formatQuestionType();
    this.getTotalAnswers();
    this.initAnswers(this.answerList);
  }

  /**
   * Defines the overview question text depending on step type
   */
  private formatQuestionType(): void {
    this.step.questionType = '';
    switch (this.step.type) {
    case 'multiple':
      this.step.questionType = 'QUESTION À RÉPONSES MULTIPLES';
      break;
    default:
      this.step.questionType = 'QUESTION SIMPLE';
      break;
    }
  }

  /**
   * Calculates the amount of right answers
   */
  private getTotalAnswers(): void {
    let total = 0;
    this.answerList.forEach((answer) => {
      if (answer.isCorrect)
        total++;

    });
    this.totalAnswers = total;
  }

  /**
   * Initializes the answers with specific display parameters
   * @param answerList List of answers in this step
   */
  private initAnswers(answerList: Array<Answer>): Array<Answer> {
    answerList.forEach((answer) => {
      answer.checked = false;
    });
    return answerList;
  }

  /**
   * Submits an answer
   * @param answer Submitted answer
   */
  public submitAnswer(answer: Answer): void {
    if (!answer.checked) {
      answer.checked = true;
      if (answer.isCorrect) {
        this.displayWrongAnswer = false;
        this.goodAnswers++;
        if (this.goodAnswers === this.totalAnswers)
          this.showPopup();

        if (this.madeMistake) {
          this.emitMistake.emit();
          this.madeMistake = false;
        }
      } else {
        this.madeMistake = true;
        this.displayWrongAnswer = true;
        setTimeout(() => {
          this.displayWrongAnswer = false;
        }, 2500);
      }
    }
  }

  /**
   * Shows the summary popup for this step
   */
  private showPopup(): void {
    setTimeout(() => {
      this.displayAnswers = false;
      this.displayPopup = true;
      this.emitPopup.emit(true);
    }, 800);
  }

  /**
   * Hides the summary popup and restarts the step
   */
  public restartStep(): void {
    this.initStepUI();
    this.emitPopup.emit(false);
  }

  /**
   * Tells the parent component to switch to the next step
   */
  public nextStep(): void {
    this.emitNextStep.emit();
  }
}
