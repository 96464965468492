import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/providers/database/database.service';

/**
 * HistoryService
 * @description Handles Navigator BackButton
 */
@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private router: Router,
    private storage: DatabaseService
  ) { }

  /** Gets the url of the previous page */
  public getUrl(): Promise<string> {
    return this.storage.get('previousUrl');
  }

  /** Sets the url of the current page to be used later */
  public setUrl(url?: string): void {
    if (!url)
      this.storage.set('previousUrl', this.router.url).catch(console.error);
    else
      this.storage.set('previousUrl', url).catch(console.error);
  }

}
