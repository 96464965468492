import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ShareModule } from '@ngx-share/core';
import { HttpClientModule } from '@angular/common/http';

import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';
import { NavMobileComponent } from './components/nav-mobile/nav-mobile.component';
import { FriendsPopupComponent } from './components/friends-popup/friends-popup.component';
import { FooterComponent } from './components/footer/footer.component';
import { MyPathComponent } from './components/my-profil/my-path/my-path.component';
import { MyStatsComponent } from './components/my-profil/my-stats/my-stats.component';
import { MyFriendsComponent } from './components/my-profil/my-friends/my-friends.component';
import { MyInterventionsComponent } from './components/my-profil/my-interventions/my-interventions.component';
import { MyCircleComponent } from './components/my-profil/my-circle/my-circle.component';
import { MyBadgesComponent } from './components/my-profil/my-badges/my-badges.component';
import { PlusSectionComponent } from './components/plus-section/plus-section.component';
import { DeclareInterventionComponent } from './components/my-profil/my-interventions/declare-intervention/declare-intervention.component';
import { AddCircleComponent } from './components/my-profil/my-circle/add-circle/add-circle.component';
import { EditProfilComponent } from './components/my-profil/edit-profil/edit-profil.component';
import { PasswordPopupComponent } from './components/my-profil/edit-profil/password-popup/password-popup.component';
import { DeletePopupComponent } from './components/my-profil/edit-profil/delete-popup/delete-popup.component';
import { CertificatePopupComponent } from './components/my-profil/my-path/certificate-popup/certificate-popup.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule  } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { ClickOutsideDirective } from './directive/clickOutside.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';


/**
 * Modules required to operate the providers components
 */
@NgModule({
  declarations: [
    FooterComponent,
    NavMobileComponent,
    FriendsPopupComponent,
    ShareButtonsComponent,
    MyPathComponent,
    MyStatsComponent,
    MyFriendsComponent,
    MyInterventionsComponent,
    MyCircleComponent,
    MyBadgesComponent,
    PlusSectionComponent,
    DeclareInterventionComponent,
    AddCircleComponent,
    EditProfilComponent,
    PasswordPopupComponent,
    DeletePopupComponent,
    ClickOutsideDirective,
    CertificatePopupComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ShareModule,
    SlickCarouselModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    ClipboardModule,
    NgxSmartModalModule.forRoot()
  ],
  exports: [
    FooterComponent,
    NavMobileComponent,
    FriendsPopupComponent,
    MyPathComponent,
    MyStatsComponent,
    MyFriendsComponent,
    MyInterventionsComponent,
    MyCircleComponent,
    MyBadgesComponent,
    PlusSectionComponent,
    DeclareInterventionComponent,
    AddCircleComponent,
    EditProfilComponent,
    DeletePopupComponent,
    SafeHtmlPipe
  ],
  providers: [ NgxSmartModalService ],
  entryComponents: [
    DeletePopupComponent,
    PasswordPopupComponent,
    CertificatePopupComponent
  ]
})
export class ProvidersModule { }
