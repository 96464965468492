import { Component, OnInit, HostListener, ChangeDetectorRef, Inject } from '@angular/core';
import { enterAnimation } from '../../providers/animations/enterAnimation.animation';
import { Location, DOCUMENT } from '@angular/common';
import { LoginService } from 'src/app/modules/http/login/login.service';
import { Router, NavigationEnd, OutletContext } from '@angular/router';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { filter } from 'rxjs/operators';
import { slideDown } from 'src/app/providers/animations/slideDown.animation';
import { fadeAnimation } from 'src/app/providers/animations/fadeAnimation.animation';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { SceneFinished } from 'src/app/providers/models/sceneFinished.model';
import { UserService } from 'src/app/modules/http/user/user.service';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { HistoryService } from 'src/app/modules/history/history.service';
import { DatabaseService } from 'src/app/providers/database/database.service';

/**
 * Components that handle header relative methods
 * @author jeremy65
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [enterAnimation, slideDown, fadeAnimation]
})
export class HeaderComponent implements OnInit {
  /** User connection status */
  public isConnected: boolean;
  /** Selected navigation tab */
  public isShow: string;
  /** Control variable to avoid instantly closing the section */
  public isPlusRecentlyShown = false;
  /** Dropdown status */
  public isPlusShow = false;
  /** Dropdown hover status */
  public isPlusHover = false;
  /** User device */
  public isMobile = false;
  /** Social share popup status */
  public isPopup: boolean;
  /** Footer view status */
  public isFooter: boolean;
  /** Header view status */
  public isHeader: boolean;
  /** Header view status when not connected */
  public isHeaderNotConnected: boolean;
  /** Mobile navigation view status */
  public isNavMobile: boolean;
  /** Header color */
  public headerColor: string;
  /** * Viewport width */
  public innerWidth: number;
  /** Header status on mobile bonus page */
  public isBonusHeader = true;
  /** User finished scene number */
  public totalScene: SceneFinished;
  /** Connection and register buttons status */
  public showButton = false;
  /**
   *  Check screen size
   * @param event Screen size
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  /**
   * Check scroll to change header status on bonus mobile page
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any): void {
    if (window.innerWidth < 765 && window.pageYOffset > 400 && this.isShow === 'bonus')
      this.isBonusHeader = false;
    else
      this.isBonusHeader = true;
    if (window.pageYOffset > window.innerHeight)
      this.showButton = true;
    else
      this.showButton = false;
  }


  constructor(
    @Inject(DOCUMENT) private document: Document,
    public serviceLogin: LoginService,
    public location: Location,
    public router: Router,
    public statusService: StatusService,
    private cdRef: ChangeDetectorRef,
    public authService: AuthenticationService,
    public userService: UserService,
    private stats: StatsService,
    private historyService: HistoryService,
    private storage: DatabaseService
  ) { }
  /**
   * Get viewport width, header and footer view status and actual tab
   */
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 765) {
      this.isMobile = true;
      this.isPlusHover = false;
    } else {
      this.isMobile = false;
      if (this.innerWidth > 767 && this.innerWidth < 1100)
        this.isPlusHover = false;
    }
    this.serviceLogin.currentStatus.subscribe(
      (userStatus) => {
        {this.isConnected = userStatus;
          if (userStatus) {
            this.statusService.updateLifeazURL(true);
            this.userService.getUserMail()
            .then((mail) =>
              this.userService.getUserScene(mail).subscribe(
                (scene) => this.statusService.updateScore(scene.data[0])
              )
            )
            .catch(console.error); } else {
            this.statusService.updateScore({userScene: 0, sceneTotal: 0});
            this.statusService.showPopup(false);
            this.isPlusShow = false;
          } }
      });
    this.headerStatus().catch(console.error);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.substr(1).includes('profil'))
        this.isShow = 'profil';
      else
        this.isShow = event.url.substr(1);

    });
    this.statusService.isHeaderNotConnected.subscribe(
      (headerNotConnected) =>
        setTimeout(() => this.isHeaderNotConnected = headerNotConnected, 0)
    );
    this.statusService.isHeader.subscribe(
      (header) =>
        setTimeout(() => this.isHeader = header, 0)
    );
    this.statusService.isFooter.subscribe(
      (footer) =>
        setTimeout(() => this.isFooter = footer, 0)
    );
    this.statusService.isNavMobile.subscribe(
      (navMobile) =>
        setTimeout(() => this.isNavMobile = navMobile, 0)
    );
    this.statusService.isPopup.subscribe(
      (popup) => {
        setTimeout(() => this.isPopup = popup, 0);
        if (popup) {
          this.historyService.getUrl()
          .then((url) => {
            this.logEvent('[EH] Invite_Page_Landed', {referrer: url});
          })
          .catch(console.error);
        }
      }
    );
    this.statusService.headerColor.subscribe(
      (color) =>
        setTimeout(() => this.headerColor = color)
    );
    this.statusService.userTotal.subscribe(
      (total) =>
        setTimeout(() => this.totalScene = total)
    );
  }

  /**
   *  Go to screen top
   * @param param Button type
   */
  public scrollToTop(param: string): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.statusService.updateForm(param);
  }

  /**
   *  Navigate to page and show/hide Plus menu
   * @param tab Page name
   */
  public tabNav(tab: string): void {
    this.popupStatus(false);
    this.isShow = tab;
    if (tab === 'show')
      this.isPlusShow = !this.isPlusShow;
    else
      this.isPlusShow = false;
  }

  /** Check user connection status */
  async headerStatus(): Promise<void> {
    this.serviceLogin.isConnected(await this.authService.checkAuth().then((res) => res));
  }
  /**
   *  Output from nav mobile
   * @param $event Page name
   */
  public onTab($event: string): void {
    this.isShow = $event;
    if ($event === 'show')
      this.isPlusShow = !this.isPlusShow;
    else
      this.isPlusShow = false;
  }

  /**
   *  Show/hide Plus menu on hover on desktop
   * @param param Hover event
   */
  public hoverPlus(param: string): void {
    if (this.innerWidth > 1099) {
      if (!this.isPlusShow) {
        if (param === 'in')
          this.isPlusHover = true;
        if (param === 'out')
          this.isPlusHover = false;
      }
    }
  }

  /**
   * Change tab
   * @param $event Page to navigate
   */
  public navigateTo($event: string): void {
    this.router.navigateByUrl(`/${$event}`)
    .catch(console.error);
    this.isShow = $event;
  }

  /**
   * Show plus menu on click and keep last active tab
   * @param param Page name
   */
  public showPlus(param?: string): void {
    if (param === 'show') {
      this.isPlusShow = !this.isPlusShow;
      if (this.isPlusShow) {
        this.isPlusRecentlyShown = true;
        setTimeout(() => {
          this.isPlusRecentlyShown = false;
        }, 250);
      }
    }
    else {
      if (this.isPlusShow)
        this.isPlusShow = false;
    }
  }

  /**
   * Handles a click anywhere on the header and decides whether or not to close the 'plus' section
   * @param $event Click target
   */
  public clickHandler($event: Event): void {
    if (!($event.target as Element).closest(`.plus`))
      this.clickOutsidePlus(false);
  }

  /**
   * Change dropdown view status
   * @param $event Dropdown view status
   */
  public clickOutsidePlus($event: boolean): void {
    this.isPlusShow = $event;
    this.isPlusHover = $event;
  }

  /***
  *  Show friends page
  */
  public showFriends(): void {
    this.statusService.showPopup(!this.isPopup);
    if (!this.isPopup)
      setTimeout(() => this.document.body.classList.add('is-popup'), 200);
    else if (this.isPopup)
      this.document.body.classList.remove('is-popup');
    if (this.isMobile)
      this.statusService.showHeaderFooter(!this.isPopup);
    else
      this.statusService.showFooter(!this.isPopup);
  }

  /**
   *  Output from friends-popup
   * @param $event Boolean to open/close popup
   */
  public popupStatus($event: boolean): void {
    this.statusService.showPopup($event);
    if ($event)
      this.document.body.classList.add('is-popup');
    else if (!$event)
      this.document.body.classList.remove('is-popup');
    if (this.isMobile)
      this.statusService.showHeaderFooter(!$event);
    else
      this.statusService.showFooter(!$event);
  }

  /**
   * Get on top page on rooter navigation
   * @param event Rooter navigation
   */
  public onActivate(event: any): void {
    window.scroll(0, 0);
  }

  /**
   * Get router state to animate navigation
   * @param outlet Router state
   */
  public getRouterOutletState(outlet: any): OutletContext {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }

  /**
   * Open a new tab with an URL
   * @param url Url to navigate to
   */
  public goToLink(url: string): void {
    window.open(url, "_blank");
  }
}
