import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { sharedText } from '../../models/shared.model';
import { UserService } from 'src/app/modules/http/user/user.service';
import { StatsService } from 'src/app/modules/stats/stats.service';

/**
 * Components that handle social share buttons relative methods
 */
@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss']
})
export class ShareButtonsComponent implements OnInit {

  /** Mail's view status emitter*/
  @Output()
  showMail: EventEmitter<boolean> = new EventEmitter();
  /** Mail's view status input */
  @Input() mail: boolean;
  /** Share link value */
  @Input() linkValue: string;
  constructor(
    public userService: UserService,
    private stats: StatsService
  ) { }
  /** Shared text description */
  public sharedText = sharedText;
  /** Shared website URL */
  public sharedWebsite: string;
  /**
   * Get user's mail to generate share link
   * @author jeremy65
   */
  ngOnInit(): void {
    this.userService.getUserMail()
    .then((mail) => {
      this.userService.getUserData(mail).subscribe(
        (data) => this.sharedWebsite = `https://everydayheroes.fr/?friend=${data.data.friendLink}`
      );
    })
    .catch(console.error);
  }

  /**
   *  Change mail view status
   */
  addEmail(): void {
    this.showMail.emit(!this.mail);
    this.mail = !this.mail;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
