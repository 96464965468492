import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Circle } from 'src/app/providers/models/circle.model';

import { PathsService } from '../http/paths/paths.service';
import { UserService } from '../http/user/user.service';

/**
 * PathSceneResolver
 */
@Injectable()
export class PathSceneResolver implements Resolve<any> {

  /** Circle Info */
  public circleInfo: Circle;

  constructor(
    public pathService: PathsService,
    public userService: UserService
  ) { }

  /**
   * Get user's path scenes
   */
  public resolve(): Promise<any> {
    return this.userService.getUserMail()
    .then((mail) =>
      this.pathService.getPathsScene(mail).toPromise()
    );
  }

}
