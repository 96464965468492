export const Regex = [
  {
    type: 'codePostal',
    regex: '/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/'
  },
  {
    type: 'mail',
    regex: '^[^\\W][a-zA-Z0-9_]+(\\.[a-zA-Z0-9_]+)*\\@[a-zA-Z0-9_]+(\\.[a-zA-Z0-9_]+)*\\.[a-zA-Z]{2,4}'
  }
];


