import { Component, Input } from '@angular/core';
import { IAedStatus } from 'src/app/providers/models/aedStatus.model';


/** Aed status display component */
@Component({
  selector: 'app-clark-status',
  templateUrl: './clark-status.component.html',
  styleUrls: ['./clark-status.component.scss']
})
export class ClarkStatusComponent {

  /** Aed status data */
  @Input() public aedData: IAedStatus;
  /** Aed status view */
  @Input() public aedView;
  /*** Info bubble status */
  public isInfoBubble = false;
  /** User's message */
  public message: string;
  /** CSM Dialog status */
  public isCSM = false;

  constructor() {}

  /** Format expirationDate */
  ngOnInit(): void {
    const formattedDate = new Date(this.aedData.expirationDate);
    this.aedData.expirationDate = `${formattedDate.getMonth() + 1}/${formattedDate.getFullYear()}`;
  }

  /**
   * Open contact form
   */
  handleDialog($event: boolean): void {
    this.isCSM = $event;
  }
}
