import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
/** Components that handle circle redirection */
@Component({
  selector: 'app-circle-redirection',
  templateUrl: './circle-redirection.component.html',
  styleUrls: ['./circle-redirection.component.scss']
})
export class CircleRedirectionComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router
  ) { }
  /**
   * Redirect user to circle landing page
   */
  ngOnInit(): void {
    this.router.navigateByUrl(this.route.snapshot.data.circle)
    .catch(console.error);
  }

}
