import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { filter } from 'rxjs/operators';
import { enterAnimation } from 'src/app/providers/animations/enterAnimation.animation';
import { UserService } from 'src/app/modules/http/user/user.service';
import { User } from 'src/app/providers/models/user.model';
import { StatusService } from 'src/app/modules/singleton/status.service';
import { leaveAnimation } from 'src/app/providers/animations/leaveAnimation.animations';
import { DOCUMENT } from '@angular/common';
import { HistoryService } from 'src/app/modules/history/history.service';
import { UserCircle } from 'src/app/providers/models/userCircle.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { Subscription } from 'rxjs';
import { ProfileStats } from 'src/app/providers/models/profilestats.model';

/**
 * Component that handles profile related methods
 * @author jeremy65
 */
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
  animations: [enterAnimation, leaveAnimation]
})
export class ProfilComponent implements OnInit, OnDestroy {

  /*** User circle informations */
  public circleInfo: UserCircle;
  /*** User circle status */
  public isCircle = false;
  /*** Circle ID */
  public idCircle: string;
  /*** Viewport width */
  public innerWidth: number;
  /*** User device */
  public isMobile = false;
  /*** Actual tab in profil page */
  public isTab = 'progress';

  /*** User's information */
  public userInfo: User;
  /*** User's mail */
  public userMail: string;
  /*** Declaration page status */
  public isDeclare = false;
  /*** Circle page status */
  public isAddCircle = false;
  /*** Profil edition page status */
  public isEditProfil = false;
  /*** Subcomponent view status */
  public isSub = false;
  /*** Profile deletion popup status */
  public isDeletePopup = false;
  /*** Top background img URL */
  public bgImage = '';
  /*** Route URL to show subcomponent */
  public routeURL: string;
  /*** User's stats */
  public myStats: ProfileStats;

  /** Router subscription */
  private routerSubscription: Subscription;

  /**
   * Check viewport width
   * @param event Screen size
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public activatedRoute: ActivatedRoute,
    public circleService: CirclesService,
    public router: Router,
    public userService: UserService,
    public statusService: StatusService,
    public historyService: HistoryService,
    private stats: StatsService
  ) {
    this.routerSubscription = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.routeURL = event.url.replace('/profil/', '');
      if (window.innerWidth < 768) {
        this.isSub = true;
        this.statusService.showHeaderFooter(false);
        this.statusService.showNavMobile(false);
      }
      switch (this.routeURL) {
      case 'intervention':
        this.isDeclare = true;
        this.isEditProfil = false;
        this.isAddCircle = false;
        break;
      case 'circle':
        this.isAddCircle = true;
        this.isDeclare = false;
        this.isEditProfil = false;
        break;
      case 'edition':
        this.isEditProfil = true;
        this.isAddCircle = false;
        this.isDeclare = false;
        break;
      default:
        this.isEditProfil = false;
        this.isAddCircle = false;
        this.isDeclare = false;
        this.isSub = false;
        break;
      }
    });
  }

  /**
   * Set current URL, check if device type and subsribe to resolvers
   */
  ngOnInit(): void {
    this.historyService.getUrl()
    .then((url) => {
      this.logEvent('[EH] Profil_Page_Landed', {referrer: url});
    })
    .catch(console.error);
    this.historyService.setUrl();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isMobile = true;
      this.bgImage = 'https://imgs.everydayheroes.fr/EH2/profil/profil_eh_mobile.jpg';
      this.statusService.showHeaderFooter(false);
      this.statusService.showNavMobile(false);
    } else {
      this.isMobile = false; this.isSub = false;
      this.bgImage = 'https://imgs.everydayheroes.fr/EH2/profil/profil_eh_desktop.jpg';
      this.statusService.showHeaderFooter(true);
      this.statusService.showNavMobile(true);
    }
    this.activatedRoute.data.subscribe((data) => {
      if (data.circles[0].length === 0)
        this.isCircle = false;
      else {
        this.circleService.updateCircle(data.circles[0][0]);
        this.circleInfo = data.circles[0][0];
        this.isCircle = true;
      }
    });
    this.updateProfil();
  }

  /**
   * Update profil information on init and edition profil changes
   */
  public updateProfil(): void {
    this.userService.getUserMail()
    .then((mail) => {
      this.userMail = mail;
      this.userService.getUserData(this.userMail).subscribe(
        (infos) => this.userInfo = infos.data
      );
      this.userService.getStats(this.userMail)
      .subscribe((res) => {
        this.myStats = res.data;
      });
    })
    .catch(console.error);
  }

  /**
   * Unsubscribes to router events
   */
  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  /**
   *  Navigation on mobile
   * @param tab Page to navigate
   */
  public tab(tab: string): void {
    this.isTab = tab;
  }

  /**
   *  Back to last page
   */
  public backClicked(): any {
    if (this.innerWidth < 768) {
      this.statusService.showHeader(true);
      this.statusService.showNavMobile(true);
    }
    this.router.navigateByUrl('home')
    .catch(console.error);
  }

  /**
   *  Output from friends-popup
   * @param $event Boolean to open/close popup
   */
  public popupStatus($event: boolean): void {
    this.statusService.showPopup($event);
    this.statusService.showFooter(!$event);
    if ($event)
      setTimeout(() => this.document.body.classList.add('is-popup'), 200);
    else if (!$event)
      this.document.body.classList.remove('is-popup');

  }

  /**
   * Open/close intervention page
   * @param $event Intervention page status
   */
  public interventionStatus($event: boolean): void {
    this.isDeclare = $event;
    window.scrollTo(0, 0);
  }

  /**
   * Open/close circle page
   * @param $event Circle page status
   */
  public addCircleStatus($event: boolean): void {
    this.isAddCircle = $event;
    window.scrollTo(0, 0);
  }

  /**
   * Open/close edit profil page
   * @param $event Edit profil page status
   */
  public editProfilStatus($event: boolean): void {
    this.updateProfil();
    this.isEditProfil = $event;
    if (!this.isMobile) {
      if ($event) {
        this.router.navigateByUrl(`/profil/edition`)
        .catch(console.error);
      }
      else {
        this.router.navigateByUrl(`/profil`)
        .catch(console.error);
      }

    } else {
      this.router.navigateByUrl(`/profil/edition`)
      .catch(console.error);
      this.isEditProfil = true;
      this.isSub = true;
    }
    window.scrollTo(0, 0);
  }

  /**
   * Back to mobile profil page
   */
  public backTo(): void {
    this.isDeclare = false;
    this.router.navigateByUrl('/profil')
    .catch(console.error);
    window.scrollTo(0, 0);
  }

  /**
   * Open delete popup
   * @param $event Popup status
   */
  public deletePopup($event: boolean): void {
    this.isDeletePopup = $event;
  }

  /**
   * Logs an event in Amplitude and the database
   * @param title Event title
   * @param properties Event properties
   */
  public logEvent(title: string, properties?: object): void {
    this.stats.logEvent(title, properties)
    .catch(console.error);
  }
}
