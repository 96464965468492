import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/modules/http/user/user.service';
import { CirclesService } from 'src/app/modules/http/circles/circles.service';
import { ActivatedRoute } from '@angular/router';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Circle } from 'src/app/providers/models/circle.model';
import { CertificateInfos } from 'src/app/providers/models/certificate.model';
import { StatsService } from 'src/app/modules/stats/stats.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Component that handles certificate popup related methods
 * @author jeremy65
*/
@Component({
  selector: 'app-certificate-popup',
  templateUrl: './certificate-popup.component.html',
  styleUrls: ['./certificate-popup.component.scss']
})
export class CertificatePopupComponent implements OnInit {

  /*** Popup element */
  public dialogRef: MatDialog;
  /** User's paths infos */
  public paths: any;
  /** window width */
  public innerWidth: number;
  /*** User's circles */
  public userCircles: Array<Circle>;
  /** User's email */
  public userMail: string;
  /** Certificate form step */
  public step: number = 1;
  /** User's firstname */
  public firstName: string;
  /** User's lastname */
  public lastName: string;
  /*** User's firstname input status */
  public isFirstName: boolean = false;
  /*** User's lastname input status */
  public isLastName: boolean = false;
  /*** Form status */
  public submit = false;
  /** Path's title */
  public pathTitle: string;
  /*** User's circles id */
  public idsCircle: Array<number> = [];

  constructor(
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    public route: ActivatedRoute,
    public circleService: CirclesService,
    private stats: StatsService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      AvenirHeavy: {
        normal: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf',
        bold: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf',
        italics: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf',
        bolditalics: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf'
      },
      AvenirBold: {
        normal: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf',
        bold: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf',
        italics: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf',
        bolditalics: 'https://fonts.gstatic.com/s/lato/v13/4cKlrioa77J2iqTqBgkRWg.ttf'
      },
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
    };
  }

  /** init */
  ngOnInit(): void {
    this.userService.getUserMail()
    .then((mail) => {
      this.userMail = mail;
      this.circleService.getCircleUser(this.userMail)
      .subscribe((data) => {
        this.userCircles = data.data;
      });
      this.userService.getUserData(this.userMail)
      .subscribe((infos) => {
        this.firstName = infos.data.firstName;
        this.lastName = infos.data.lastName;
      });
    })
    .catch((error) => console.error(error));
    this.paths = this.data.paths;
    this.innerWidth = window.innerWidth;
  }

  /** Click on cross to close dialog */
  public closeDialog(): void {
    this.matDialog.closeAll();
  }

  /**
   * click on button "télécharger l'attesation'"
   * @param pathTitle Path's title
   */
  public downloadCertificate(pathTitle: any): void {
    let idCircle: number = 0;
    for (const element of this.userCircles) {
      this.idsCircle.push(element.idCircle);
      if (element.idCircle === 1295 || element.idCircle === 1296 || (element.idCircle >= 1362 && element.idCircle <= 1390))
        idCircle = element.idCircle;
    }
    this.pathTitle = pathTitle;
    if (this.firstName.length !== 0 && this.lastName && this.lastName.length !== 0) {
      const infos: CertificateInfos = {
        path: pathTitle,
        idCircle,
        name: this.firstName + " " + this.lastName
      };
      this.userService.getCertificate(infos, this.userMail)
      .subscribe((data) => {
        console.log(data);
        pdfMake.createPdf(data.data).open();
        this.stats.logEvent('[EH] Profil_CTA-DLAttestation_Clicked', { idCircle: this.idsCircle, Path: this.pathTitle })
        .catch(console.error);
      });
    }
    else
      this.step = 2;
  }

  /**
   ** Check password and send form data
   */
  public onSubmit(): void {
    this.submit = true;
    this.isFirstName = this.firstName ? true : false;
    this.isLastName = this.lastName ? true : false;
    if (this.isFirstName && this.isLastName) {
      this.userService.setUserData(this.userMail, { firstName: this.firstName, lastName: this.lastName }).subscribe(
        (data) => {
          console.log(data);
          this.step = 1;
          this.downloadCertificate(this.pathTitle);
        }
      );
    }
  }
}
